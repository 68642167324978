import { defineMessages } from "react-intl";

export const scope = "app.containers.OrderInfo";

export default defineMessages({
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: "Order Number",
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: "Location",
  },
  workshopName: {
    id: `${scope}.workshopName`,
    defaultMessage: "Workshop Name",
  },
  workshopCode: {
    id: `${scope}.workshopCode`,
    defaultMessage: "Workshop Code",
  },
  workshopAddress: {
    id: `${scope}.workshopAddress`,
    defaultMessage: "Customer Address",
  },
  branchName: {
    id: `${scope}.branchName`,
    defaultMessage: "Branch Name",
  },
  contactNumber: {
    id: `${scope}.contactNumber`,
    defaultMessage: "Contact Number",
  },
  orderPlacedBy: {
    id: `${scope}.orderPlacedNy`,
    defaultMessage: "Order Placed by",
  },
  orderCreatedAt: {
    id: `${scope}.orderCreatedAt`,
    defaultMessage: "Time",
  },
  newOrder: {
    id: `${scope}.newOrder`,
    defaultMessage: "New Order",
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: "Pending",
  },
  partUnavailable: {
    id: `${scope}.partUnavailable`,
    defaultMessage: "Parts Unavailable",
  },
  customerName: {
    id: `${scope}.customerName`,
    defaultMessage: "Customer Name",
  },
  garaazCode: {
    id: `${scope}.garaazCode`,
    defaultMessage: "Garaaz Code",
  },
  supplierCode: {
    id: `${scope}.customerCode`,
    defaultMessage: "Supplier Code",
  },
  invoiceDetails: {
    id: `${scope}.invoiceDetails`,
    defaultMessage: "Invoice Details",
  },
  challanDetails: {
    id: `${scope}.challanDetails`,
    defaultMessage: "Challan Details",
  },
  parentOrder: {
    id: `${scope}.parentOrder`,
    defaultMessage: "Parent Order",
  },
  association: {
    id: `${scope}.association`,
    defaultMessage: "Customer Association",
  },
});
