import produce from "immer";
import moment from "moment";

import {
  GET_ALL_SUPPLIERS,
  SET_ALL_ORDERS_NEW,
  SET_TOTAL_COUNT_NEW,
  UPDATE_TABLE_TOP_VALUE_NEW,
  SET_LAST_API_CALL_TIME,
} from "./constants";

const initialState = {
  orders: [],
  location: [],
  totalCount: 0,
  tableTopValues: {
    orderNo: "",
    supplierOrderStatus: [],
    branchValue: null,
    selectedCustomer: null,
    dates: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    isPending : true
  },
  allSuppliers: [],
  lastApiCallTime: new Date().getTime(),
};

/* eslint-disable default-case, no-param-reassign */
const orderListReducerNew = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_ALL_ORDERS_NEW:
        draft.orders = payload ?? [];
        break;
      case SET_TOTAL_COUNT_NEW:
        draft.totalCount = payload ?? 0;
        break;
      case UPDATE_TABLE_TOP_VALUE_NEW:
        draft.tableTopValues = { ...draft.tableTopValues, ...payload };
        break;
      case GET_ALL_SUPPLIERS:
        draft.allSuppliers = payload ?? [];
        break;
      case SET_LAST_API_CALL_TIME:
        draft.lastApiCallTime = payload ?? 0;
        break;
    }
  });

export default orderListReducerNew;