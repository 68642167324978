import { Box, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import enums from "../../utils/enums";
import { FaRupeeSign } from "react-icons/fa";
import SupplierOrderStatus from "../../utils/supplierOrderStatus";

const GaraazCountDownTimer = ({ allocationTime, orderStatus, stateTime }) => {
  const countDownDate = new Date(allocationTime).getTime() + 30 * 60 * 1000;

  let actionTime =
    new Date(stateTime).getTime() - new Date(allocationTime).getTime();

  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      var now = new Date().getTime();

      var distance = countDownDate - now;

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setMinutesRemaining(minutes);
      setSecondsRemaining(seconds);

      if (distance < 0 || SupplierOrderStatus.isActionTaken(orderStatus)) {
        clearInterval(intervalId);
      }
    }, 1000);
  }, []);

  if (!allocationTime || !stateTime) {
    return "-";
  }

  if (actionTime <= 30 * 60 * 1000) {
    if (SupplierOrderStatus.isActionTaken(orderStatus)) {
      return (
        <Box
          display={"flex"}
          justifyContent={"center"}
          color={"green"}
          alignItems={"center"}
        >
          <Text>+</Text>
          <Text>
            <FaRupeeSign></FaRupeeSign>
          </Text>
          <Text ml={-0.5}>5</Text>
        </Box>
      );
    } else {
      if (orderStatus === enums.REVOKED) {
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"red"}
          >
            <Text>
              <FaRupeeSign></FaRupeeSign>
            </Text>
            <Text ml={-0.5}>5</Text>
          </Box>
        );
      } else {
        if (minutesRemaining >= 0 && secondsRemaining >= 0) {
          return (
            <Box display={"flex"} justifyContent={"center"}>
              <Text>{minutesRemaining.toString().padStart(2, "0")}</Text> :{" "}
              <Text>{secondsRemaining.toString().padStart(2, "0")}</Text>
            </Box>
          );
        } else {
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"red"}
            >
              <Text>
                <FaRupeeSign></FaRupeeSign>
              </Text>
              <Text ml={-0.5}>5</Text>
            </Box>
          );
        }
      }
    }
  } else {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"red"}
      >
        <Text>
          <FaRupeeSign></FaRupeeSign>
        </Text>
        <Text ml={-0.5}>5</Text>
      </Box>
    );
  }
};

export default GaraazCountDownTimer;
