import React, { Fragment } from "react";
import {
  InputGroup,
  Input,
  InputRightElement,
  Box,
  useColorMode,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Select as MultiSelect } from "chakra-react-select";
import { FiSearch } from "react-icons/fi";
import enums from "../../utils/enums";
import styled from "styled-components";
import device from "../../utils/mediaQueries";
import uuid from "react-uuid";

/* This is ths structure of the input data */
/* const inputData = [
  {
    title: 'Filter by order no',
    type: 'text',
    placeholder: 'Enter order No',
    name: 'orderNo',
  },
  {
    title: 'Filter by Status',
    type: 'select',
    placeholder: 'Enter order No',
    name: 'status',
    options: [
      {
        value: enums.PENDING,
        label: 'Pending',
      },
      {
        value: enums.COMPLETED,
        label: 'Completed',
      },
      {
        value: enums.UPDATED,
        label: 'Updated',
      },
    ],
  },
]; */

/* 

 const [inputValues, setInputValue] = useState({})

const handleTableTopChange = (e, name) => {
  const value = e.hasOwnProperty('target')? e.target.value : e
  setInputValue(prevState => ({...prevState, [name]:value }))
}

<ModifiedTableTop inputData={inputData} onChange={handleTableTopChange}/>
 */

const ModefiedTableTop = (props) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderTopRightRadius="10px"
      borderTopLeftRadius="10px"
      backgroundColor={colorMode === "dark" ? "black" : "white"}
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      {[
        ...[...props.inputData].map((item) =>
          generateInput(item, props.onChange)
        ),
      ]}
      {(Array.isArray(props.children) ? props.children : [props.children]).map(
        (item) => (
          <StyledButtonBox key={uuid()}>{item}</StyledButtonBox>
        )
      )}
    </Box>
  );
};

export const TableTopWrapper = (props) => {
  const { colorMode } = useColorMode();

  return (
    <StyledFlex backgroundColor={colorMode === "dark" ? "black" : "white"}>
      {props.children}
    </StyledFlex>
  );
};

const InputBox = ({ islarge, children, fullWidth, mb, mr }) => {
  return <StyledInputBox>{children}</StyledInputBox>;
};

export const generateInput = (inputObj, onChange, others = {}) => {
  switch (inputObj.type) {
    case "select":
      return (
        <InputBox
          key={inputObj.title}
          fullWidth={inputObj?.fullWidth}
          mb={inputObj?.mb}
          mr={inputObj?.mr}
        >
          <Box textStyle="label" layerStyle="muted" mb={1}>
            {inputObj?.title}
          </Box>
          {inputObj.defaultValue ? (
            <MultiSelect
              isDisabled={inputObj.isDisabled}
              name={inputObj.name}
              placeholder={inputObj?.placeholder}
              defaultValue={inputObj.defaultValue}
              onChange={(e) => onChange(e, inputObj.name)}
              isMulti={inputObj?.isMulti ?? false}
              options={inputObj.options}
            />
          ) : (
            <MultiSelect
              width={{ base: "100%", md: "40%" }}
              isDisabled={inputObj.isDisabled}
              name={inputObj.name}
              placeholder={inputObj?.placeholder}
              onChange={(e) => onChange(e, inputObj.name)}
              isMulti={inputObj?.isMulti ?? false}
              options={inputObj.options}
            />
          )}
        </InputBox>
      );

    case "text":
    case "email":
      return (
        <InputBox key={inputObj.title} mb={inputObj?.mb} mr={inputObj?.mr}>
          <Box textStyle="label" layerStyle="muted" mb={1}>
            {inputObj?.title}
          </Box>
          <InputGroup>
            {inputObj.defaultValue ? (
              <Input
                type={inputObj.type}
                name={inputObj.name}
                defaultValue={inputObj.defaultValue}
                placeholder={inputObj?.placeholder}
                onChange={(e) => onChange(e, inputObj.name)}
              />
            ) : (
              <Input
                type={inputObj.type}
                name={inputObj.name}
                placeholder={inputObj?.placeholder}
                onChange={(e) => onChange(e, inputObj.name)}
              />
            )}
            <InputRightElement
              pointerEvents="none"
              children={<FiSearch color="gray.300" />}
            />
          </InputGroup>
        </InputBox>
      );
    case "date":
      return (
        <Fragment key={inputObj.name.toString()}>
          <InputBox
            key={`${inputObj.name[0]}-startDate`}
            mb={inputObj?.mb}
            mr={inputObj?.mr}
          >
            <Box textStyle="label" layerStyle="muted" mb={1}>
              Start Date
            </Box>

            {inputObj?.defaultValue ? (
              <Input
                name={enums.STARTDATE}
                type="date"
                onChange={(e) => onChange(e, inputObj.name[0])}
                placeholder="Start Date"
                defaultValue={
                  inputObj.defaultValue ? inputObj?.defaultValue[0] : ""
                }
              />
            ) : (
              <Input
                value={inputObj.startDate}
                name={enums.STARTDATE}
                type="date"
                onChange={(e) => onChange(e, inputObj.name[0])}
                placeholder="Start Date"
              />
            )}
          </InputBox>
          <InputBox
            key={`${inputObj.name[1]}-endDate`}
            mb={inputObj?.mb}
            mr={0}
          >
            <Box textStyle="label" layerStyle="muted" mb={1}>
              End Date
            </Box>
            {inputObj?.defaultValue ? (
              <Input
                placeholder="End Date"
                name={enums.ENDDATE}
                type="date"
                onChange={(e) => onChange(e, inputObj.name[1])}
                defaultValue={
                  inputObj.defaultValue ? inputObj?.defaultValue[1] : ""
                }
              />
            ) : (
              <Input
                value={inputObj.endDate}
                placeholder="End Date"
                name={enums.ENDDATE}
                type="date"
                onChange={(e) => onChange(e, inputObj.name[1])}
              />
            )}
          </InputBox>
        </Fragment>
      );
    /* return (
        <InputBox islarge key={inputObj.name}>
          <Box
            display='flex'
            width='100%'
            flexWrap='wrap'
            justifyContent='space-between'
          >
            <Box width={{ base: '100%', md: '48%' }} mb={{ base: 15, md: 0 }}>
              <Box textStyle='label' layerStyle='muted' mb={1}>
                Start Date
              </Box>
              <Input
                name={enums.STARTDATE}
                type='date'
                onChange={(e) => onChange(e, inputObj.name[0])}
                placeholder='Start Date'
                defaultValue={
                  inputObj.defaultValue ? inputObj?.defaultValue[0] : ''
                }
              />
            </Box>

            <Box width={{ base: '100%', md: '48%' }}>
              <Box textStyle='label' layerStyle='muted' mb={1}>
                End Date
              </Box>
              <Input
                placeholder='End Date'
                name={enums.ENDDATE}
                type='date'
                onChange={(e) => onChange(e, inputObj.name[1])}
                defaultValue={
                  inputObj.defaultValue ? inputObj?.defaultValue[1] : ''
                }
              />
            </Box>
          </Box>
        </InputBox>
      ); */

    default:
      return;
  }
};

export const SingleSelect = ({ inputObj, onChange }) => {
  return (
    <Box
      width={{
        base: "100%",
        lg: inputObj?.islarge ? "40%" : inputObj?.fullWidth ? "100%" : "15rem",
      }}
    >
      <Box textStyle="label" layerStyle="muted" mb={1}>
        {inputObj?.title}
      </Box>
      <MultiSelect
        isDisabled={inputObj.isDisabled}
        name={inputObj.name}
        placeholder={inputObj?.placeholder}
        onChange={(e) => onChange(e, inputObj.name)}
        isMulti={false}
        options={inputObj.options}
      />
    </Box>
  );
};

export const SingleInput = ({ inputObj, onChange }) => {
  return (
    <InputBox {...inputObj} key={inputObj.title}>
      <Text fontWeight={"bold"} mb={1}>
        {inputObj?.title}
      </Text>
      <InputGroup>
        <Input
          defaultValue={inputObj?.defaultValue ?? ""}
          type={inputObj.type}
          key={inputObj?.defaultValue}
          name={inputObj.name}
          placeholder={inputObj?.placeholder}
          onChange={(e) => onChange(e, inputObj.name)}
        />
        {!inputObj?.hideIcon && (
          <InputRightElement
            pointerEvents="none"
            children={<FiSearch color="gray.300" />}
          />
        )}
      </InputGroup>
    </InputBox>
  );
};

const StyledInputBox = styled(Box)`
  flex-basis: 100%;
  margin-bottom: 16px;

  @media ${device.laptop} {
    flex-basis: 23%;
    margin-right: 2.6%;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
`;

const StyledButtonBox = styled(StyledInputBox)`
  flex-basis: 100%;
  & > button {
    width: 100%;
  }

  @media ${device.laptop} {
    flex-basis: fit-content;
  }
`;

const StyledFlex = styled(Flex)`
  display: flex;

  justify-content: flex-start;
  align-items: flex-end;

  & > div {
    flex-basis: 100%;
    margin-bottom: 16px;
  }

  & > button {
    width: 100%;
    margin-bottom: 16px;
  }

  @media ${device.laptop} {
    & > div {
      flex-basis: 23%;
      margin-right: 1%;
      &:nth-child(6n) {
        margin-right: 0%;
      }
    }

    & > button {
      flex-basis: fit-content;
      margin-right: 2.6%;
    }
  }
`;
export default ModefiedTableTop;
