import routes from "../../routes";
import enums from "../../utils/enums";

export const GET_ALL_ORDERS = routes.getAllGaraazOrders;
export const SET_ALL_ORDERS_NEW = "SET_ALL_ORDERS_NEW";
export const SET_TOTAL_COUNT_NEW = "SET_TOTAL_COUNT_NEW";
export const UPDATE_TABLE_TOP_VALUE_NEW = "UPDATE_TABLE_TOP_VALUE_NEW";
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const SET_LAST_API_CALL_TIME = "SET_LAST_API_CALL_TIME";
export const PENDING_SUPPLIER_ORDER_STATUS = [enums.ACK, enums.NEW, enums.CBO];
