import { defineMessages } from "react-intl";

export const scope = "app.containers.Dashboard";

export default defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  distributor: {
    id: `${scope}.distributor`,
    defaultMessage: "Distributor",
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: "Profile",
  },
  branch: {
    id: `${scope}.branches`,
    defaultMessage: "Branches",
  },
  employee: {
    id: `${scope}.employees`,
    defaultMessage: "Employees",
  },
  part: {
    id: `${scope}.parts`,
    defaultMessage: "Parts",
  },
  partAddition: {
    id: `${scope}.partAddition`,
    defaultMessage: "Part Addition",
  },
  partInfo: {
    id: `${scope}.partInfo`,
    defaultMessage: "Part Info",
  },
  workshop: {
    id: `${scope}.workshop`,
    defaultMessage: "Workshop",
  },
  orderList: {
    id: `${scope}.orderList`,
    defaultMessage: "Order List",
  },
  customerOrders: {
    id: `${scope}.customerOrders`,
    defaultMessage: "Customer Orders",
  },
});
