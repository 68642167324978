import React, { useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Select,
  useColorMode,
  IconButton,
  Input,
  Tfoot,
  Flex,
  extendTheme,
  Spinner,
} from "@chakra-ui/react";

import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, usePagination } from "react-table";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useIntl } from "react-intl";
import messages from "./messages";
import getMoneyFormat from "../../utils/getMoneyFormat";

const rowsNo = [10, 25, 50, 100, 150, 200];

const DynamicTable = ({
  data,
  columns,
  totalCount,
  fetchData,
  loading,
  hidePagination,
  skipPageReset,
  updateMyData,
  totals,
  controlledPageCount,
  smallWidth,
  summary,
  params,
  customPageIndex,
  getPageIndex,
  getPageSize,
  customPageSize,
  updateIsAcknowledged,
}) => {
  const { colorMode } = useColorMode();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: !skipPageReset,
      updateMyData,
      updateIsAcknowledged,
      initialState: {
        pageIndex: customPageIndex ? customPageIndex : 0,
        pageSize: customPageSize ? customPageSize : 150,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pageSize) {
      fetchData({
        offset: pageSize * pageIndex,
        limit: pageSize,
        ...params,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex]);

  const tableHeadRow = headerGroups.map((headerGroup) => (
    <Tr
      {...headerGroup.getHeaderGroupProps()}
      height={{ base: "10vh", lg: "3rem" }}
    >
      {headerGroup.headers.map((column, index) => {
        let borderRight =
          index < headerGroup.headers.length - 1 ? "1px" : "0px";
        return (
          <Th
            {...column.getHeaderProps(column.getSortByToggleProps())}
            isNumeric={column.isNumeric}
            className="h-full bg-blueGray-800 text-white"
            p={1}
            borderRightWidth={borderRight}
            textAlign="center"
            style={{ wordBreak: "keep-all" }}
          >
            {column.render("Header")}
            <chakra.span pl="0">
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <TriangleDownIcon aria-label="sorted descending" />
                ) : (
                  <TriangleUpIcon aria-label="sorted ascending" />
                )
              ) : null}
            </chakra.span>
          </Th>
        );
      })}
    </Tr>
  ));

  const tableCells = page.map((row, index) => {
    prepareRow(row);

    return (
      <Tr
        {...row.getRowProps()}
        borderStyle="solid"
        borderBottomWidth="1px"
        className="border-gray-400"
      >
        {row.cells.map((cell, index, cells) => {
          let borderRight = index < row.cells.length - 1 ? "1px" : "0px";
          return (
            <Td
              position={"relative"}
              py={cell?.column?.Header === "Date" ? 0 : 2}
              px={cell?.column?.Header === "Date" ? 0 : 2}
              overflowY="auto"
              maxWidth={`${100 / cells.length}vw`}
              {...cell.getCellProps()}
              isNumeric={cell.column.isNumeric}
              borderRightWidth={borderRight}
              textAlign="center"
              wordBreak="break-all"
            >
              {cell.render("Cell")}
            </Td>
          );
        })}
      </Tr>
    );
  });

  return (
    <>
      <Box
        overflowX="auto"
        width="100%"
        borderBottomRightRadius="10px"
        flex={1}
      >
        {data.length > 0 && !loading ? (
          <Table
            overflowX="auto"
            className="min-h-1/2"
            variant="unstyle"
            {...getTableProps()}
            minWidth={{ base: smallWidth ? smallWidth : 800 }}
          >
            <Thead>{tableHeadRow}</Thead>
            <Tbody
              backgroundColor={colorMode === "dark" ? "black" : "white"}
              {...getTableBodyProps()}
            >
              {tableCells}
            </Tbody>

            {totals && (
              <Tfoot>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  <Th fontSize={{ base: 16, lg: "1.3vw" }} textAlign="center">
                    Total
                  </Th>
                  <Th fontSize={{ base: 16, lg: "1.3vw" }} textAlign="center">
                    {totals.totalOrderedQuantity}
                  </Th>
                </Tr>
              </Tfoot>
            )}
            {summary && (
              <Tfoot>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  <Th fontSize={{ base: 16, lg: "1.3vw" }} textAlign="center">
                    Total
                  </Th>
                  <Th fontSize={{ base: 16, lg: "1.3vw" }} textAlign="center">
                    {summary.totalOrderedQuantity}
                  </Th>
                  <Th></Th>
                  {headerGroups[0].headers.length > 6 && <Th></Th>}
                  <Th fontSize={{ base: 16, lg: "1.3vw" }} textAlign="center">
                    {getMoneyFormat(summary.totalAmount)}
                  </Th>
                </Tr>
              </Tfoot>
            )}
          </Table>
        ) : !loading && data.length === 0 ? (
          <Flex
            minH="40vh"
            minWidth={{ base: smallWidth ? smallWidth : 800 }}
            justifyContent="center"
            alignItems="center"
          >
            <Box textStyle="subtitle" layerStyle="muted">
              No Data
            </Box>
          </Flex>
        ) : (
          <Flex
            minH="40vh"
            minWidth={{ base: smallWidth ? smallWidth : 800 }}
            justifyContent="center"
            alignItems="center"
          >
            <Box textStyle="subtitle" layerStyle="muted">
              loading
            </Box>
          </Flex>
        )}
      </Box>
      {!hidePagination && (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          loading={loading}
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          getPageIndex={getPageIndex}
          getPageSize={getPageSize}
        />
      )}
    </>
  );
};

const Pagination = ({
  previousPage,
  nextPage,
  gotoPage,
  canPreviousPage,
  canNextPage,
  loading,
  page,
  totalCount,
  pageSize,
  setPageSize,
  pageIndex,
  pageOptions,
  getPageIndex,
  getPageSize,
}) => {
  const intl = useIntl();

  return (
    <Box
      m={2}
      display="flex"
      justifyContent={{ base: "flex-start", md: "space-between" }}
      flexWrap="wrap"
      alignItems="center"
    >
      <Box mt={5} mb={4} display="flex" justifyContent="left">
        {intl.formatMessage(messages.showing)} {pageIndex * pageSize + 1}{" "}
        {intl.formatMessage(messages.to)} {pageIndex * pageSize + page.length}{" "}
        result {intl.formatMessage(messages.of)} {totalCount} results
      </Box>

      <Box
        flexWrap="wrap"
        display="flex"
        justifyContent={{ base: "left", md: "right" }}
        alignItems="center"
      >
        <Flex>
          <Box pt={2} mr={2}>
            {intl.formatMessage(messages.rowPerPage)}:
          </Box>
          <Select
            value={pageSize}
            mr={10}
            width="fit-content"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              getPageSize(Number(e.target.value));
            }}
          >
            {rowsNo.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Select>
        </Flex>
        <Box
          display="flex"
          flexWrap="nowrap"
          alignItems="center"
          my={{ base: 4, md: 0 }}
        >
          <Box mr={5}>
            {intl.formatMessage(messages.page)} {pageIndex + 1}{" "}
            {intl.formatMessage(messages.of)}{" "}
            {pageOptions.length ? pageOptions.length : 1}
          </Box>
          <IconButton
            onClick={() => {
              if (getPageIndex) {
                getPageIndex(pageIndex - 1);
              }
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            <AiOutlineLeft />
          </IconButton>
          <IconButton
            ml={6}
            onClick={() => {
              if (getPageIndex) {
                getPageIndex(pageIndex + 1);
              }
              nextPage();
            }}
            disabled={!canNextPage}
          >
            <AiOutlineRight />
          </IconButton>
        </Box>
        <Flex>
          <Box pt={2} mr={2} ml={{ base: 0, md: 6 }}>
            {intl.formatMessage(messages.goToPage)}:
          </Box>
          <Box>
            <Input
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 1;

                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
              type="number"
              width={{ base: 20 }}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default DynamicTable;
