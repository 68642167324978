import { createSlice } from '@reduxjs/toolkit';
import { MdDataSaverOff } from 'react-icons/md';
import {
  createBulkAccount,
  fetchAllAccounts,
  fetchWorkshopAccounts,
  getFileQueue,
} from './service';
import moment from 'moment';

const accountSlice = createSlice({
  name: 'accountSlice',
  initialState: {
    allAccounts: [],
    workshopAccount: [],
    workshopAccountList: [],
    queuedFile: [],
    inputValues: {
      end: moment().format('YYYY-MM-DD'),
      start: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      groupBy: '',
    },

    searchAccount: ''
  },
  reducers: {
    addAllAccounts: (state, { payload }) => {
      state.allAccounts = payload;
    },
    addWorkshopAccount: (state, { payload }) => {
      state.workshopAccount = payload;
    },
    addWorkshopAccountList: (state, { payload }) => {
      state.workshopAccountList = payload;
    },
    addQueuedFile: (state, { payload }) => {
      state.queuedFile = payload;
    },
    addSingleQueue: (state, { payload }) => {
      state.queuedFile = [...state.queuedFile, payload];
    },
    addInputValues: (state, { payload }) => {
      state.inputValues = { ...state.inputValues, ...payload };
    },
    addSearchAccount: (state, { payload }) => {
        state.searchAccount = payload
    }
  },
});

export const {
  addAllAccounts,
  addWorkshopAccount,
  addWorkshopAccountList,
  addQueuedFile,
  addSingleQueue,
  addInputValues,
  addSearchAccount
} = accountSlice.actions;

export const uploadBulkAccount = (payload) => (dispatcher) => {
  return createBulkAccount(payload).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error);
      }
    },
  );
};

export const getAllAccounts = (payload) => (dispatcher) => {
  return fetchAllAccounts(payload).then(
    (response) => {
      const data = Object.values(response.data)?.map((el, index) => {
        return { ...el, code: Object.keys(response.data)[index] };
      });
      dispatcher(addAllAccounts(data));
      return Promise.resolve(data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    },
  );
};

export const getWorkshopAccounts = (payload) => (dispatcher) => {
  return fetchWorkshopAccounts(payload).then((response) => {
    dispatcher(addWorkshopAccount([response.data.workshopAccounts]));
    dispatcher(addWorkshopAccountList(response.data.accounts));
    return Promise.resolve(response.data);
  });
};

export const getQueuedFiles = (payload) => (dispatcher) => {
  return getFileQueue(payload).then(
    (response) => {
      dispatcher(
        addQueuedFile(Array.isArray(response.data) ? response.data : []),
      );
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    },
  );
};

export const selectAccounts = (state) => state.accounts;

export default accountSlice.reducer;
