import produce from 'immer';
import moment from 'moment';

import { SET_ALL_ORDERS, SET_TOTAL_COUNT, UPDATE_TABLE_TOP_VALUE, GET_ALL_SUPPLIERS } from './constants';

const initialState = {
    orders: [],
    location: [],
    totalCount: 0,
    tableTopValues: {
        orderIds: '',
        orderStatus: [],
        branchValue: [],
        selectedWorkshop: [],
        dates: {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
        }
    },
    allSuppliers: []
}

/* eslint-disable default-case, no-param-reassign */
const orderListReducer = (state = initialState, { payload, type }) =>
    produce(state, draft => {
        switch (type) {
            case SET_ALL_ORDERS:
                draft.orders = payload ?? [];
                break;
            case SET_TOTAL_COUNT:
                draft.totalCount = payload ?? 0;
                break;
            case UPDATE_TABLE_TOP_VALUE:
                draft.tableTopValues = { ...draft.tableTopValues, ...payload };
                break;
            case GET_ALL_SUPPLIERS:
                draft.allSuppliers = payload ?? []
                break;
        }
    });

export default orderListReducer;

