const Modal = {


    // Styles for the size variations
    sizes: { '2.5xl': { dialog: { maxW: '80vw' } } },
    // Styles for the visual style variations
    variants: {
        
    },
    // The default `size` or `variant` values
    defaultProps: {
        size: 'sm',
    },
  }


  export default Modal;