import { Box, Text } from "@chakra-ui/react";
import { GiPayMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaRupeeSign } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectGlobalState } from "../../globalSlice";
import { getGarrazMonthlyIncentive } from "../../containers/OrderListNew/actions";
import { async } from "@firebase/util";

export default function GarrazMonthlyIncentive() {
  const [earnedIncentive, setEarnedIncentive] = useState(0);
  const [missedIncentive, setMissedIncentive] = useState(0);
  const { isDistributor, employee, distributor, toggleActionTaken } =
    useSelector(selectGlobalState);
  const auth = useSelector((state) => state.auth);

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const startDate = `${currentYear}-${currentMonth}-01`;
  const endDate = `${currentYear}-${currentMonth}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  useEffect(async () => {
    fetchMonthlyIncentive();
  }, [distributor, employee, toggleActionTaken]);

  async function fetchMonthlyIncentive() {
    const supplierID = isDistributor ? distributor._id : employee.distributorId;
    if (supplierID) {
      const response = await getGarrazMonthlyIncentive(
        auth,
        startDate,
        endDate,
        supplierID
      );
      setEarnedIncentive(response.incentiveEarned);
      setMissedIncentive(response.incentiveLost);
    }
  }
  return (
    <Box
      display={"flex"}
      alignItems="center"
      pl={"2em"}
      pr={"2em"}
      pt="0"
      pb={"0"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        mr={4}
        pt="0"
        pb="0"
        p={2}
        backgroundColor={"linear-gradient(90deg, #2AF598 0%,  #08AEEA 100%"}
      >
        <Box>
          <Text
            alignItems="center"
            fontSize="xl"
            fontWeight="bold"
            color={"black"}
            pr={2}
          >
            <GiReceiveMoney></GiReceiveMoney>{" "}
          </Text>
        </Box>
        <Box>
          <Text
            display={"flex"}
            alignItems="center"
            color="green"
            fontWeight="bold"
            fontSize="md"
          >
            <FaRupeeSign></FaRupeeSign> {earnedIncentive}
          </Text>
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        mr={4}
        pt="0"
        pb="0"
        p={2}
        backgroundColor={"linear-gradient(90deg, #2AF598 0%,  #08AEEA 100%"}
      >
        <Box>
          <Text
            alignItems="center"
            color={"black"}
            fontSize="xl"
            fontWeight={"bold"}
            pr={2}
          >
            <GiPayMoney></GiPayMoney>
          </Text>
        </Box>
        <Box>
          <Text
            color="#C40C0C"
            display={"flex"}
            alignItems="center"
            fontSize="md"
            fontWeight="bold"
          >
            {" "}
            <FaRupeeSign></FaRupeeSign> {missedIncentive}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
