import messages from "./messages";
import individualIllustrator from '../../assets/img/individualIllustrator.svg'
import bulkIllustrator from '../../assets/img/bulkAddIllustrator.svg'
export const SET__BRANCHES = 'SET__BRANCHES';
export const optionsProp = {
  indivi: {
    header: messages.indiviOpt,
    title: messages.indiviTitle,
    msg: messages.indiviMsg,
    illustrators: individualIllustrator,
    link: '/branch/add'
  }, bulk: {
    header: messages.bulkOpt,
    title: messages.bulkTitle,
    msg: messages.bulkMsg,
    illustrators: bulkIllustrator,
    link: '/branch/add/bulk'
  }
}
