import React, { useContext, createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { publicAxios as axios } from "../axios";
import { useToast } from "@chakra-ui/toast";

const VisitContext = createContext("");

export const VisitProvider = ({ children }) => {
  const toast = useToast();
  const [visitId, setVisitId] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("visitId") === null) {
      const postVisitCreate = async () => {
        try {
          const res = await axios.post("/visits", {
            source: "WEB",
          });
          sessionStorage.setItem("visitId", get(res, "data.visitId", ""));
          setVisitId(get(res, "data.visitId", ""));
        } catch (err) {
          toast({
            description: "Error occured, please try again",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        }
      };
      postVisitCreate();
    } else {
      setVisitId(sessionStorage.getItem("visitId"));
    }
  }, []);

  return (
    <VisitContext.Provider value={visitId}>
      <>{children}</>
    </VisitContext.Provider>
  );
};

VisitProvider.propTypes = {
  children: PropTypes.node,
};

export const useVisit = () => {
  const visitId = useContext(VisitContext);

  if (visitId === "undefined") {
    throw new Error("useVisit is not defined within VisitProvider");
  }

  return visitId;
};
