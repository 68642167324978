import { defineMessages } from "react-intl";

export const scope = "app.containers.OrderListNew";

export default defineMessages({
  orderList: {
    id: `${scope}.orderList`,
    defaultMessage: "Order List",
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: "Date",
  },
  SLA: {
    id: `${scope}.SLA`,
    defaultMessage: "SLA",
  },
  orderNo: {
    id: `${scope}.orderNo`,
    defaultMessage: "Order No",
  },
  workshopCode: {
    id: `${scope}.workshopCode`,
    defaultMessage: "Workshop Code",
  },
  workshopName: {
    id: `${scope}.workshopName`,
    defaultMessage: "Workshop Name",
  },
  branchCode: {
    id: `${scope}.branchCode`,
    defaultMessage: "Branch Code",
  },
  orderValue: {
    id: `${scope}.orderValue`,
    defaultMessage: "Order Value",
  },
  paymentMode: {
    id: `${scope}.paymentMode`,
    defaultMessage: "Payment Mode",
  },
  totalParts: {
    id: `${scope}.totalParts`,
    defaultMessage: "Total Parts",
  },
  orderStatus: {
    id: `${scope}.orderStatus`,
    defaultMessage: "Order Status",
  },
  updatedStatus: {
    id: `${scope}.updatedStatus`,
    defaultMessage: "Updated",
  },
  completedStatus: {
    id: `${scope}.completedStatus`,
    defaultMessage: "Completed",
  },
  pendingStatus: {
    id: `${scope}.pendingStatus`,
    defaultMessage: "Pending",
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: "Actions",
  },
  invoiceNo: {
    id: `${scope}.invoiceNo`,
    defaultMessage: "Invoice No",
  },
  invoiceNo: {
    id: `${scope}.invoiceNo`,
    defaultMessage: "Invoice No",
  },
  invoiceAmount: {
    id: `${scope}.invoiceAmount`,
    defaultMessage: "Invoice Amount",
  },
  invoiceInfo: {
    id: `${scope}.invoiceInfo`,
    defaultMessage: "Invoice Info",
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage: "Something went wrong, please try again",
  },
  dateError: {
    id: `${scope}.dateError`,
    defaultMessage: "End date must be greater than Start date",
  },
  supplierCode: {
    id: `${scope}.supplierCode`,
    defaultMessage: "Supplier Code",
  },
  branchName: {
    id: `${scope}.branchName`,
    defaultMessage: "Branch Name",
  },
  customerName: {
    id: `${scope}.customerName`,
    defaultMessage: "Customer Name",
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: "Start Date",
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: "End Date",
  },
  orderRemarks: {
    id: `${scope}.orderRemarks`,
    defaultMessage: "Order Remarks",
  },
  orderRemarksSuccess: {
    id: `${scope}.orderRemarksSuccess`,
    defaultMessage: "Order remark added successfully",
  },
  CBO: {
    id: `${scope}.CBO`,
    defaultMessage: "CBO",
  },
  NEW: {
    id: `${scope}.NEW`,
    defaultMessage: "NEW",
  },
  CANCELLED: {
    id: `${scope}.CANCELLED`,
    defaultMessage: "CANCELLED",
  },
  COMPLETED: {
    id: `${scope}.COMPLETED`,
    defaultMessage: "COMPLETED",
  },
  READY: {
    id: `${scope}.READY`,
    defaultMessage: "READY",
  },
  ACK: {
    id: `${scope}.ACK`,
    defaultMessage: "ACK",
  },
  REVOKED: {
    id: `${scope}.REVOKED`,
    defaultMessage: "REVOKED",
  },
});
