import { createSlice } from '@reduxjs/toolkit';
import Services from './services';

const inventorySlice = createSlice({
  name: 'inventorySlice',
  initialState: {
    allStock: [],
    allStockTotalCount: 0,
    allStockPageCount: 0,
    initalPageIndex: 0,
    initialPageSize: 10,
    allStockParams: {
      offset: 0,
      limit: 10,
    },
    pendingParts: { data: [], totalCount: 0 },
    searchedStock: [],
    searchedStockTotalCount: 0,
    queuedFile: [],
  },
  reducers: {
    addAllStock: (state, { payload }) => {
      state.allStock = payload.data;
      state.allStockTotalCount = payload.totalCount;
      state.allStockPageCount = payload.pageCount;
    },
    addSearchedStock: (state, { payload }) => {
      state.searchedStock = payload.data;
      state.searchedStockTotalCount = payload.totalCount;
    },
    addPendingParts: (state, { payload }) => {
      state.pendingParts = payload;
    },
    addQueuedFile: (state, { payload }) => {
      state.queuedFile = payload;
    },
    addInitialPageIndex: (state, { payload }) => {
      state.initalPageIndex = payload;
    },
    addInitialPageSize: (state, { payload }) => {
      state.initialPageSize = payload;
    },
    addAllStockParams: (state, { payload }) => {
      state.allStockParams = payload;
    },
  },
});

export const {
  addAllStock,
  addPendingParts,
  addSearchedStock,
  addQueuedFile,
  addInitialPageIndex,
  addInitialPageSize,
  addAllStockParams
} = inventorySlice.actions;

export const getAllStock = (payload) => (dispatcher) => {
  return Services.getAllStock(payload).then(
    (response) => {
      let data = [];
      let totalCount = 0;
      if (response.data.length > 0) {
        data = response.data[0].data;
        totalCount = response.data[0].totalCount.count;
      }

      payload.params.limit = payload.params?.limit ? payload.params?.limit : 10;
      const pageCount = Math.ceil(totalCount / payload.params?.limit);
      dispatcher(addAllStock({ data, totalCount, pageCount }));
      dispatcher(
        addAllStockParams({
          limit: payload.params?.limit,
          offset: payload.params?.offset,
        }),
      );
      return Promise.resolve({ data, totalCount });
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response?.data?.message);
      }
    },
  );
};

export const getBranchStocks = (payload) => (dispatcher) => {
  return Services.getAllStockByBranch(payload).then(
    (response) => {
      let data = [];
      let totalCount = 0;
      if (response.data.length > 0) {
        data = response.data[0].data;
        totalCount = response.data[0].totalCount.count;
      }
      dispatcher(addSearchedStock({ data, totalCount }));
      return Promise.resolve({ data, totalCount });
    },

    (error) => {
      if (error.response) {
        return Promise.reject(error.response?.data?.message);
      }
    },
  );
};

export const uploadStock = (payload) => (dispatcher) => {
  return Services.uploadStock(payload).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response?.data?.message);
      }
    },
  );
};

export const getPendingParts = (payload) => (dispatcher) => {
  return Services.getPendingParts(payload).then(
    (response) => {
      let data = [];
      let totalCount = 0;
      if (response.data.length > 0) {
        data = response.data[0].data;
        totalCount = response.data[0].totalCount.count;
      }
      dispatcher(addPendingParts({ data, totalCount }));
      return Promise.resolve({ data, totalCount });
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response?.data?.message);
      }
    },
  );
};

export const getQueuedFiles = (payload) => (dispatcher) => {
  return Services.getFileQueue(payload).then(
    (response) => {
      dispatcher(addQueuedFile(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    },
  );
};

export const selectInventory = (state) => state.inventorydetail;

export default inventorySlice.reducer;
