import React from 'react';
import serverErrorIllustrator from '../assets/img/serverError.svg';
import Error from './Error';
import { withRouter } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

  }

  render() {
    const { history } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<Error
        onRetry={() => {
          history.replace('/orders');
          this.setState({ hasError: false })
        }}
        title="Something went wrong"
        //message="Sorry! We're unable to process your request. Please try again later"
        message='We are working on this, please try again!'
        illustrator={serverErrorIllustrator}
      />
      );
    }

    return this.props.children;
  }
}


export default withRouter(ErrorBoundary);
