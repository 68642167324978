import produce from 'immer';

import { UPDATE_TABLEDATA } from './constants';

export const initialState = {
	tableData: [],
};

/* eslint-disable default-case, no-param-reassign */
const workshopReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case UPDATE_TABLEDATA:
				draft.tableData = action.payload;
				break;
		}
	});

export default workshopReducer;
