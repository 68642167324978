import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

const GaraazAlertDialog = ({
  onSubmitButtonClick,
  submitBtnText,
  isOpen,
  onClose,
  bodyText,
  headerText,
  branchName,
  showCancelButton = true,
}) => {
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        returnFocusOnClose={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth="600px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>
            <AlertDialogBody whiteSpace="pre-line">
              {bodyText} <strong>{branchName.toUpperCase()}</strong>{" "}
            </AlertDialogBody>
            <AlertDialogFooter>
            {showCancelButton && <Button onClick={onClose}>Cancel</Button>}
              <Button colorScheme="blue" onClick={onSubmitButtonClick} ml={3}>
                {submitBtnText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default GaraazAlertDialog;
