import produce from 'immer';

import { SET_EMPLOYEE_LIST } from './constants';

export const initialState = {
	employees: [],
	designations:[]
};

/* eslint-disable default-case, no-param-reassign */
const employeeListReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case SET_EMPLOYEE_LIST:
				draft.employees = action.payload;
				break;
		}
	});

export default employeeListReducer;
