import React, { useState } from "react";
import { MdAddChart } from "react-icons/md";
import {
  IconButton,
  useDisclosure,
  Textarea,
  HStack,
  Button,
} from "@chakra-ui/react";
import { RiMenuAddFill } from "react-icons/ri";
import enums from "../../utils/enums";
import { useRouteMatch, useHistory } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import UpdateInvoiceModal from "../OrderDetailsNew/updateInvoiceModal";
import SimpleModal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateOrders, updateOrdersRemarks } from "../OrderDetailsNew/actions";
import { useIntl } from "react-intl";
import garaazToast from "../../HOCs/garaazToast";
import messages from "./messages";
import { setLastApiCallTime } from "./actions";
import { selectGlobalState, updateActionTaken } from "../../globalSlice";

const ActionCell = ({
  row: { original },
  value,
  updateMyData,
  notification,
  updateIsAcknowledged,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { url } = useRouteMatch();
  const auth = useSelector((state) => state.auth);
  const { toggleActionTaken } = useSelector(selectGlobalState);
  const dispatcher = useDispatch();

  const [orderRemarks, setOrderRemarks] = useState(
    original?.allocatedTo?.[0]?.orderRemarks || ""
  );

  const {
    isOpen: isRemarksModalOpen,
    onOpen: onRemarksModalOpen,
    onClose: onRemarksModalClose,
  } = useDisclosure();

  const [invoiceEditProps, setInvoiceEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });

  const [challanEditProps, setChallanEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });

  const handleViewOrderDetails = () => {
    history.push(`${url}/${value}`);
  };

  const handleUpdateData = () => {
    updateMyData();
  };

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setOrderRemarks(inputValue);
  };

  const handleOrderRemarksHandle = async () => {
    try {
      await updateOrdersRemarks({
        auth,
        orderId: original?._id,
        data: {
          remark: orderRemarks,
        },
      });
      notification({
        status: "success",
        description: intl.formatMessage(messages.orderRemarksSuccess),
      });
    } catch (error) {
      notification({
        status: "error",
        description: intl.formatMessage({
          id: "app.containers.OrderListNew.SupplierOrderRemarksError",
          defaultMessage: `${error?.message ?? "Something went wrong"}`,
        }),
      });
    }
    updateMyData();
  };

  return original?.allocatedTo?.[0]?.orderStatus === enums.NEW ||
    original?.allocatedTo?.[0].orderStatus === enums.RETURN_REQUESTED ? (
    <Button
      size="xs"
      bg={"green.500"}
      _hover={{ bg: "green.600" }}
      color={"white"}
      onClick={async () => {
        await updateIsAcknowledged(original?._id);
        dispatcher(updateActionTaken(!toggleActionTaken));
      }}
    >
      ACK
    </Button>
  ) : (
    <>
      <HStack spacing={1} alignItems={"center"} justifyContent={"center"}>
        <IconButton
          width={7}
          height={7}
          minW={7}
          onClick={handleViewOrderDetails}
        >
          <BsFillEyeFill />
        </IconButton>
        <IconButton
          width={7}
          height={7}
          minW={7}
          onClick={() => {
            dispatcher(setLastApiCallTime(new Date().getTime()));
            setInvoiceEditProps((prevState) => ({
              ...prevState,
              isOpen: true,
            }));
          }}
        >
          <MdAddChart />
        </IconButton>
        <IconButton width={7} height={7} minW={7} onClick={onRemarksModalOpen}>
          <RiMenuAddFill />
        </IconButton>
      </HStack>

      <UpdateInvoiceModal
        orderId={value}
        invoiceEditProps={invoiceEditProps}
        setInvoiceEditProps={setInvoiceEditProps}
        orderDetails={original}
        updateData={handleUpdateData}
        challanEditProps={challanEditProps}
        setChallanEditProps={setChallanEditProps}
      />
      <SimpleModal
        okText="Save"
        title="Order remarks"
        isOpen={isRemarksModalOpen}
        onClose={onRemarksModalClose}
        onOk={handleOrderRemarksHandle}
      >
        <Textarea
          placeholder="Add order remarks"
          value={orderRemarks}
          onChange={handleInputChange}
        />
      </SimpleModal>
    </>
  );
};

export default garaazToast(ActionCell);
