import { defineMessages } from "react-intl";

export const scope = "app.containers.OrderDetails";

export default defineMessages({
  SNo: {
    id: `${scope}.SNo`,
    defaultMessage: "S. No",
  },
  hsnCode: {
    id: `${scope}.hsnCode`,
    defaultMessage: "HSN Code",
  },
  taxSlab: {
    id: `${scope}.taxSlab`,
    defaultMessage: "Tax Slab",
  },
  partNumber: {
    id: `${scope}.partNumber`,
    defaultMessage: "Part No",
  },
  partName: {
    id: `${scope}.partName`,
    defaultMessage: "Part Name",
  },
  partDescription: {
    id: `${scope}.partDescription`,
    defaultMessage: "Part Name",
  },
  quantityOrdered: {
    id: `${scope}.quantityOrdered`,
    defaultMessage: "Ordered Quantity",
  },
  availableQuantity: {
    id: `${scope}.availableQuantity`,
    defaultMessage: "Available Quantity",
  },
  quantityToGive: {
    id: `${scope}.quantityToGive`,
    defaultMessage: "Quantity To Give",
  },
  pricePerPiece: {
    id: `${scope}.pricePerPiece`,
    defaultMessage: "Price Per Piece",
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: "Total Amount",
  },
  orderDetails: {
    id: `${scope}.orderDetails`,
    defaultMessage: "Order Details",
  },
  invoice: {
    id: `${scope}.invoice`,
    defaultMessage: "Invoice",
  },
  editOrder: {
    id: `${scope}.editOrder`,
    defaultMessage: "Edit Order",
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: "Download",
  },
  orderSummary: {
    id: `${scope}.orderSummary`,
    defaultMessage: "Order Summary",
  },
  saveContinue: {
    id: `${scope}.saveContinue`,
    defaultMessage: "Save & Continue",
  },
  pendingQuantity: {
    id: `${scope}.pendingQuantity`,
    defaultMessage: "Pending Quantity",
  },
  selectedLocationQuantity: {
    id: `${scope}.selectedLocationQuantity`,
    defaultMessage: "Quantity Available At Selected Location",
  },
  partUnavailable: {
    id: `${scope}.partUnavailable`,
    defaultMessage: "Parts Unavailable",
  },
  branchToCheckInventory: {
    id: `${scope}.branchToCheckInventory`,
    defaultMessage: "Select Branch to check inventory",
  },
  arrangeParts: {
    id: `${scope}.arrangeParts`,
    defaultMessage: "Arrange Parts via Stock Transfer",
  },
  forwardOrder: {
    id: `${scope}.forwardOrder`,
    defaultMessage: "Forward  Order to Selected Branch",
  },
  completeOrderContinue: {
    id: `${scope}.completeOrderContinue`,
    defaultMessage: "Complete Order and Continue",
  },
  completeOrder: {
    id: `${scope}.completeOrder`,
    defaultMessage: "Complete Order",
  },
  outOfRange: {
    id: `${scope}.outOfRange`,
    defaultMessage: "Out of range",
  },
  outOfRangeDescription: {
    id: `${scope}.outOfRangeDescription`,
    defaultMessage: "quantity to give must not be more than ordered quantity",
  },

  errorOccurred: {
    id: `${scope}.errorOccurred`,
    defaultMessage: "Error occurred, please try again",
  },

  orderUpdated: {
    id: `${scope}.orderUpdated`,
    defaultMessage: "Order Updated",
  },

  orderUpdatedDescription: {
    id: `${scope}.orderUpdatedDescription`,
    defaultMessage: "This order has been updated successfully",
  },

  orderCreatedAndUpdatedDescription: {
    id: `${scope}.orderCreatedAndUpdatedDescription`,
    defaultMessage:
      "This order has been updated successfully and a new order is created.",
  },

  partList: {
    id: `${scope}.partList`,
    defaultMessage: "Part List",
  },

  partOrdered: {
    id: `${scope}.partOrdered`,
    defaultMessage: "Part Ordered",
  },

  partFulfilled: {
    id: `${scope}.partFulfilled`,
    defaultMessage: "Part Fulfilled",
  },

  orderCompleted: {
    id: `${scope}.orderCompleted`,
    defaultMessage: "Order Completed",
  },

  orderCompletedDescription: {
    id: `${scope}.orderCompletedDescription`,
    defaultMessage: "Order was successfully completed",
  },

  downloadPDF: {
    id: `${scope}.downloadPDF`,
    defaultMessage: "Download PDF",
  },

  downloadCSV: {
    id: `${scope}.downloadCSV`,
    defaultMessage: "Download CSV",
  },

  downloadDOC: {
    id: `${scope}.downloadDOC`,
    defaultMessage: "Download Doc",
  },

  orderForwardedDescription: {
    id: `${scope}.orderForwardedDescription`,
    defaultMessage: "Order was fowarded successfully",
  },

  orderForwarded: {
    id: `${scope}.orderForwarded`,
    defaultMessage: "Order was forwarded",
  },

  orderCancelled: {
    id: `${scope}.orderCancelled`,
    defaultMessage: "Order was cancelled",
  },

  orderCancelledDescription: {
    id: `${scope}.orderCancelledDescription`,
    defaultMessage: "Order was cancelled successfully",
  },

  cancelOrder: {
    id: `${scope}.cancelOrder`,
    defaultMessage: "Cancel Order",
  },

  takeBackOrder: {
    id: `${scope}.takeBackOrder`,
    defaultMessage: "Take Back Order",
  },

  takeBackOrderInitiated: {
    id: `${scope}.takeBackOrderInitiated`,
    defaultMessage: "Back order was initiated successfully",
  },

  addInvoice: {
    id: `${scope}.addInvoice`,
    defaultMessage: "Add Invoice",
  },
  updateInvoice: {
    id: `${scope}.updateInvoice`,
    defaultMessage: "Update Invoice",
  },
  errorOnFileLoad: {
    id: `${scope}.errorOnFileLoad`,
    defaultMessage: "Error occurred while extracting file contents",
  },
  invoiceUploadedSuccessfully: {
    id: `${scope}.invoiceUploadedSuccessfully`,
    defaultMessage: "Invoice updated successfully!",
  },
  challanUploadedSuccessfully: {
    id: `${scope}.challanUploadedSuccessfully`,
    defaultMessage: "Challan updated successfully!",
  },
  invoiceAmountCannotBeNegative: {
    id: `${scope}.invoiceAmountCannotBeNegative`,
    defaultMessage: "Invoice amount can not be negative!",
  },
  challanAmountCannotBeNegative: {
    id: `${scope}.challanAmountCannotBeNegative`,
    defaultMessage: "challan amount can not be negative!",
  },
  invalidInvoicePdf: {
    id: `${scope}.invalidInvoicePdf`,
    defaultMessage:
      "Please ensure that the Invoice number does not exceed 16 characters. It can contain alphabets, numerals, hyphens(-), and/or slash(/).",
  },
  invalidChallanPdf: {
    id: `${scope}.invalidChallanPdf`,
    defaultMessage:
      "Please ensure that the Challan number does not exceed 16 characters. It can contain alphabets, numerals, hyphens(-), and/or slash(/).",
  },
  confirmationMessageTitle: {
    id: `${scope}.confirmationMessageTitle`,
    defaultMessage: "Are you sure?",
  },
  confirmationMessageDescription: {
    id: `${scope}.confirmationMessageDescription`,
    defaultMessage: "The invoice you’re about to upload contains more items or quantities than the original order. This may cause discrepancies. Please double-check the invoice details before confirming.",
  },
  mismatchMessageTitle: {
    id: `${scope}.mismatchMessageTitle`,
    defaultMessage: "Invoice Mismatch Detected!",
  },
  mismatchMessageDescription: {
    id: `${scope}.mismatchMessageDescription`,
    defaultMessage: "Order can’t be marked as ready due to discrepancies. Please correct the details and try again.",
  },
});
