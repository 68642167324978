import { defineMessages } from "react-intl";

export const scope = "app.containers.orderListTableTop";

export default defineMessages({
  searchByOrderId: {
    id: `${scope}.searchByOrderId`,
    defaultMessage: "Search By Order No",
  },
  filterByOrderStatus: {
    id: `${scope}.filterByOrderStatus`,
    defaultMessage: "Filter by order status",
  },
  filterByBranch: {
    id: `${scope}.filterByBranch`,
    defaultMessage: "Filter by branch",
  },
  filterByWorkshop: {
    id: `${scope}.filterByWorkshop`,
    defaultMessage: "Filter by workshop",
  },
  selectBranch: {
    id: `${scope}.selectBranch`,

    defaultMessage: "Select Branch",
  },
  selectWorkshop: {
    id: `${scope}.selectWorkshop`,
    defaultMessage: "Select workshop",
  },
  orderStatusFilter: {
    id: `${scope}.orderStatusFilter`,
    defaultMessage: "Order Status Filter",
  },
  orderStatusFilterNew: {
    id: `${scope}.orderStatusFilter`,
    defaultMessage: "Order Status",
  },
  searchOrders: {
    id: `${scope}.searchOrders`,
    defaultMessage: "Search Orders",
  },
  searchOrdersNew: {
    id: `${scope}.searchOrders`,
    defaultMessage: "Order No.",
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: "Start Date",
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: "End Date",
  },
});
