import React, { forwardRef } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Box,
} from '@chakra-ui/react';

const SimpleModalNew = forwardRef(
    (
        {
            hidefooter,
            size,
            onClose,
            isOpen,
            children,
            okText,
            onOk,
            title,
            isLoading,
            isModalBody,
            isDisabled
        },
        ref,
    ) => {
        return (
            <Modal
                isCentered
                motionPreset='slideInBottom'
                size={size ?? 'sm'}
                isOpen={isOpen}
                onClose={onClose}
                scrollBehavior='inside'
                width={{ base: '100%', lg: '30%' }}
                overflow-y='auto'
                returnFocusOnClose={false}
                autoFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton onClick={onClose} />

                    {!isModalBody ? (
                        <ModalBody>{children}</ModalBody>
                    ) : (
                        <Box p={5}>{children}</Box>
                    )}

                    {!hidefooter && (
                        <ModalFooter>
                            <Button mt={0} mr={3} alignContent='right' onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                mt={0}
                                alignContent='right'
                                ref={ref}
                                isLoading={isLoading}
                                bg="blue.500"
                                onClick={onOk}
                                isDisabled={isDisabled}
                                colorScheme="blue"

                            >
                                {okText}
                            </Button>
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        );
    },
);

export default SimpleModalNew;
