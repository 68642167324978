/**
 * Create the store with dynamic reducers
 */

//import { createStore, applyMiddleware, compose } from 'redux';
//import { routerMiddleware } from 'connected-react-router';
//import hist from './utils/history';
//import createReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import {reducerProxy as allReducers } from './reducers';

// eslint-disable-line
/* function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  //istanbul ignore next 
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    //eslint-disable no-underscore-dangle 
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    //eslint-enable 
  }

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    //allReducers(),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  // istanbul ignore next 
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
      //store.replaceReducer(allReducers(store.injectedReducers))
    });
  }

  return store;
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, hist); */

const store = configureStore({
  //reducer: allReducers(), previously when allReducers was being exported
  reducer: allReducers
 });

export default store;
