import { defineMessages } from 'react-intl';

export const scope = 'app.containers.EmployeeDashboard';

export default defineMessages({

  networkErrorTitle: {
    id: `${scope}.networkErrorTitle`,
    defaultMessage: 'No internet connection!',
  },
  networkErrorMessage: {
    id: `${scope}.networkErrorMessage`,
    defaultMessage: 'No internet connection!',
  },
  serverErrorMessage: {
    id: `${scope}.serverErrorMessage`,
    defaultMessage: '500 Something went wrong!',
  },
  serverErrorTitle: {
    id: `${scope}.serverErrorTitle`,
    defaultMessage: '500 Something went wrong!',
  },
  addWorkshop: {
    id: `${scope}.addWorkshop`,
    defaultMessage: 'Add Workshop',
  },


  indiviOpt: {
    id: `${scope}.indiviOpt`,
    defaultMessage: 'Get started by adding a workshop',
  },
  indiviTitle: {
    id: `${scope}.indiviTitle`,
    defaultMessage: 'Add a workshop',
  },
  indiviMsg: {
    id: `${scope}.indiviMsg`,
    defaultMessage: 'Mannualy add details of a workshop',
  },
  bulkOpt: {
    id: `${scope}.bulkOpt`,
    defaultMessage: 'Bulk add multiple workshops',
  },
  bulkTitle: {
    id: `${scope}.bulkTitle`,
    defaultMessage: 'Upload workshops using Excel file',
  },
  bulkMsg: {
    id: `${scope}.bulkMsg`,
    defaultMessage: 'Add your workshops in bulk using Excel file',
  },
  exportToExcel: {

    id: `${scope}.exportToCSV`,
    defaultMessage: 'Export to CSV',
  
},
bulkUpload: {
  id: `${scope}.bulkUpload`,
  defaultMessage: 'Bulk Upload',
},

});
