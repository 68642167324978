import PropTypes from 'prop-types';


const InviteCard = ({ welcomeNote, handleSubmit, token }) => {
  return (
    <div className="cards" style={{ border: "2px solid #dedede", borderRadius: 10, backgroundColor: "#fff", padding: "20px", margin: "10px" }}>
      <p >{welcomeNote}</p>
      {/* <p >If you were't expecting this invitation, you can close this tab.</p> */}
      <p >This invitation will expire in 7 days.</p>
      <div style={{ marginTop: "30px" }}>
        <button style={{
          marginTop: "10px",
          width: "26%",
          backgroundColor: "#17cc17",
          color: "#ffffff",
          margin: "10px",
          borderRadius: "10px"
        }}
          onClick={() => handleSubmit(token, "ACCEPTED")} >Accept</button>
        <button style={{
          marginTop: "10px",
          width: "26%",
          backgroundColor: "red",
          color: "#ffffff",
          margin: "10px",
          borderRadius: "10px"
        }}
          onClick={() => {
            handleSubmit(token, "DECLINED")//need to redirect user to login if this is the last invite in array and delete invite token from the local storage
          }
          } >Decline</button>
      </div>
    </div>
  );
}
InviteCard.propTypes = {
  welcomeNote: PropTypes.string,
  handleSubmit: PropTypes.func,
  token: PropTypes.string,
};
export default InviteCard