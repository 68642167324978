const size = {
   /*  mobileS: '320px',
    mobileM: '375px', */
    /* mobile: '425px', */
    mobile: '320px',
    tablet: '768px',
    laptop: '1024px',
    /* laptop: '1440px', */
    desktop: '2560px'
  }


const device = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
  };

  export default device;