import { createSlice } from '@reduxjs/toolkit';
import Services from './services';

const outstandingAcctSlice = createSlice({
    name: 'outstandingAcctSlice',
    initialState: {
        outstandingAcctList: [],
        workshopAccount: [],
        workshopAccountList: []
    },
    reducers: {
        addOutstandingAcct: (state, {payload}) => {
            state.outstandingAcctList = payload;
        },
        addWorkshopOutstanding: (state, {payload}) => {
            state.workshopAccount = payload;
        },
        addWorkshopAccountList: (state, { payload }) => {
            state.workshopAccountList = payload;
        }
    },
   
   
})

export const { addOutstandingAcct, addWorkshopOutstanding, addWorkshopAccountList } = outstandingAcctSlice.actions;

export const fetchAllOutstanding = (payload) => dispatcher => {
    return Services.fetchAllOutstandingAcct(payload).then(
        response => {
            const data = Object.values(response.data)?.map((el,index )=> {
                return {...el, code: Object.keys(response.data)[index]}
            })
            dispatcher(addOutstandingAcct(data));
            return Promise.resolve(data);
        },
        error => {
            if(error.response){
                return Promise.reject(error.response.data); 
            }
        }
    )
}

export const getWorkshopAccounts = (payload) => dispatcher => {
    return Services.fetchWorkshopAccounts(payload).then(
        response => {
            dispatcher(addWorkshopOutstanding([response.data.workshopOutstandings]));
            dispatcher(addWorkshopAccountList(response.data.accounts));
            return Promise.resolve(response.data);

        }
    )
}

export const selectOutsandingAcct = state => state.outstandingAcct;

export default outstandingAcctSlice.reducer;