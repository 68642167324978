import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  Center,
  Input,
  Button,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import SimpleModalNew from "../../components/ModalNew";
import { SingleInput } from "../../components/TableTop/ModifiedTableTop";
import {
  updateOrders,
  updateSupplierChallan,
  updateSupplierInvoice,
} from "./actions";
import { useDispatch, useSelector } from "react-redux";
import messages from "./messages";
import garaazToast from "../../HOCs/garaazToast";
import _ from "lodash";
import { GrUpload } from "react-icons/gr";
import * as pdfjs from "pdfjs-dist";
import { AiFillCaretDown } from "react-icons/ai";
import { uploadMedia } from "../OrderDetails/actions";
import {
  formatDateInvoiceApi,
  formatDateReverse,
} from "../../utils/checkDates";
import { setLastApiCallTime } from "../OrderListNew/actions";

const UpdateInvoice = ({
  setInvoiceEditProps,
  notification,
  invoiceEditProps,
  challanEditProps,
  setChallanEditProps,
  orderDetails,
  updateData,
}) => {
  const auth = useSelector((state) => state.auth);
  const intl = useIntl();
  const dispatcher = useDispatch();
  const [fileType, setFileType] = useState("");
  const [invoiceEditValue, setInvoiceEditValue] = useState({
    invoiceNo: "",
    invoiceAmount: "",
    invoiceDate: "",
    noOfItems: "",
    totalQtyOfItems: "",
  });
  const [challanEditValue, setChallanEditValue] = useState({
    challanNo: "",
    challanAmount: "",
    challanDate: "",
  });
  const [mapSelectState, setMapSelectState] = useState([
    {
      title: "Invoice Number",
      type: "text",
      placeholder: "Enter invoice number",
      name: "invoiceNo",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "Invoice Amount",
      type: "number",
      placeholder: "Enter invoice amount",
      name: "invoiceAmount",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "Invoice Date",
      type: "date",
      placeholder: "Enter invoice date",
      name: "invoiceDate",
      isDisabled: true,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "No of Items",
      type: "number",
      placeholder: "Enter total items",
      name: "noOfItems",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "Total Qty of Items",
      type: "number",
      placeholder: "Enter total quantity of items",
      name: "totalQtyOfItems",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
  ]);
  const [challanMapSelectState, setChallanMapSelectState] = useState([
    {
      title: "Challan Number",
      type: "text",
      placeholder: "Enter challan number",
      name: "challanNo",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "Challan Amount",
      type: "number",
      placeholder: "Enter challan amount",
      name: "challanAmount",
      isDisabled: false,
      fullWidth: true,
      hideIcon: true,
    },
    {
      title: "Challan Date",
      type: "date",
      placeholder: "Enter challan date",
      name: "challanDate",
      isDisabled: true,
      fullWidth: true,
      hideIcon: true,
    },
  ]);

  const [pdfFile, setPdfFile] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    if (!_.isEmpty(orderDetails)) {
      setMapSelectState((prevState) => {
        return prevState.map((item) => {
          if (item?.name === "invoiceNo") {
            item.defaultValue = item.invoiceNo
              ? item?.invoiceNo
              : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceNo;
          }

          if (item?.name === "invoiceAmount") {
            item.defaultValue = item.invoiceAmount
              ? item?.invoiceAmount
              : parseFloat(
                  orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount.replace(
                    /,/g,
                    ""
                  )
                );
          }

          if (item?.name === "invoiceDate") {
            item.defaultValue = orderDetails?.allocatedTo?.[0]?.invoiceInfo
              ?.invoiceDate
              ? formatDateReverse(
                  orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceDate
                )
              : undefined;
          }

          if (item?.name === "noOfItems") {
            item.defaultValue = item.noOfItems
              ? item?.noOfItems
              : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.noOfItems;
          }

          if (item?.name === "totalQtyOfItems") {  
              item.defaultValue = item.totalQtyOfItems
              ? item?.totalQtyOfItems
              : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.totalQtyOfItems;
          }

          return item;
        });
      });
      setChallanMapSelectState((prevState) => {
        return prevState.map((item) => {
          if (item?.name === "challanNo") {
            item.defaultValue = item.challanNo
              ? item?.challanNo
              : orderDetails?.allocatedTo?.[0]?.challanInfo?.challanNo;
          }

          if (item?.name === "challanAmount") {
            item.defaultValue = item.challanAmount
              ? item?.challanAmount
              : parseFloat(
                  orderDetails?.allocatedTo?.[0]?.challanInfo?.challanAmount.replace(
                    /,/g,
                    ""
                  )
                );
          }

          if (item?.name === "challanDate") {
            item.defaultValue = orderDetails?.allocatedTo?.[0]?.challanInfo
              ?.challanDate
              ? formatDateReverse(
                  orderDetails?.allocatedTo?.[0]?.challanInfo?.challanDate
                )
              : undefined;
          }

          return item;
        });
      });
      setInvoiceEditValue({
        invoiceNo: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceNo,
        invoiceAmount:
          orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount,
        invoiceDate: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceDate,
        noOfItems: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.noOfItems,
        totalQtyOfItems: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.totalQtyOfItems,
      });
      setChallanEditValue({
        challanNo: orderDetails?.allocatedTo?.[0]?.challanInfo?.challanNo,
        challanAmount:
          orderDetails?.allocatedTo?.[0]?.challanInfo?.challanAmount,
        challanDate: orderDetails?.allocatedTo?.[0]?.challanInfo?.challanDate,
      });
    }
  }, [orderDetails]);

  const handleChange = (event, name) => {
    if (fileType === "Invoice") {
      setInvoiceEditValue((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    } else {
      setChallanEditValue((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleUpdateInvoice = async () => {
    const validInvoiceCharsRegex = /^[A-Za-z0-9\-/]+$/;

    if (fileType === "Invoice") {
      if (
        invoiceEditValue.invoiceNo.length > 16 ||
        !validInvoiceCharsRegex.test(invoiceEditValue.invoiceNo)
      ) {
        notification({
          status: "error",
          description: intl.formatMessage(messages.invalidInvoicePdf),
        });
        return;
      }

      if (Number(invoiceEditValue?.invoiceAmount) < 0) {
        notification({
          status: "error",
          description: intl.formatMessage(
            messages.invoiceAmountCannotBeNegative
          ),
        });
        return;
      }

      setInvoiceEditProps((prevState) => ({ ...prevState, isLoading: true }));

      try {
        let updatedParts = {};

        if (
          pdfFile !== null &&
          invoiceEditValue.invoiceNo &&
          invoiceEditValue.invoiceAmount &&
          invoiceEditValue.invoiceDate &&
          invoiceEditValue.noOfItems &&  
          invoiceEditValue.totalQtyOfItems

        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await uploadMedia({
            auth,
            data: formData,
          });

          updatedParts = {
            invoiceInfo: {
              ...invoiceEditValue,
              invoiceAmount: String(invoiceEditValue.invoiceAmount),
              invoiceMediaId: mediaResult.data[0]._id,
              invoiceDate: formatDateInvoiceApi(invoiceEditValue.invoiceDate),
              noOfItems: String(invoiceEditValue.noOfItems),
              totalQtyOfItems: String(invoiceEditValue.totalQtyOfItems),
            },

          };
        } else {
          updatedParts = {
            invoiceInfo: {
              ...invoiceEditValue,
              invoiceAmount: String(invoiceEditValue.invoiceAmount),
              invoiceMediaId:
                invoiceEditValue.invoiceNo ||
                invoiceEditValue.invoiceAmount ||
                invoiceEditValue.invoiceDate
                  ? orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceMediaId
                  : undefined,
              invoiceDate: formatDateInvoiceApi(invoiceEditValue.invoiceDate),
              noOfItems: String(invoiceEditValue.noOfItems),
              totalQtyOfItems: String(invoiceEditValue.totalQtyOfItems),
            },
          };
        }

        await updateSupplierInvoice({
          auth,
          orderId: orderDetails?._id,
          data: updatedParts,
        });

        await updateData();
        notification({
          status: "success",
          title: intl.formatMessage(messages.invoiceUploadedSuccessfully),
        });
        setInvoiceEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        setChallanEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
      } catch (error) {
        setInvoiceEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        setChallanEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        notification({
          status: "error",
          description: intl.formatMessage(messages.errorOccurred),
        });
      }
    } else {
      if (
        challanEditValue.challanNo.length > 16 ||
        !validInvoiceCharsRegex.test(challanEditValue.challanNo)
      ) {
        notification({
          status: "error",
          description: intl.formatMessage(messages.invalidChallanPdf),
        });
        return;
      }

      if (Number(challanEditValue?.challanAmount) < 0) {
        notification({
          status: "error",
          description: intl.formatMessage(
            messages.challanAmountCannotBeNegative
          ),
        });
        return;
      }

      setChallanEditProps((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      try {
        let updatedParts = {};

        if (
          pdfFile !== null &&
          challanEditValue.challanNo &&
          challanEditValue.challanAmount
        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await uploadMedia({
            auth,
            data: formData,
          });

          updatedParts = {
            challanInfo: {
              ...challanEditValue,
              challanAmount: String(challanEditValue.challanAmount),
              challanMediaId: mediaResult.data[0]._id,
              challanDate: formatDateInvoiceApi(challanEditValue.challanDate),
            },
          };
        } else {
          updatedParts = {
            challanInfo: {
              ...challanEditValue,
              challanAmount: String(challanEditValue.challanAmount),
              challanMediaId:
                challanEditValue.challanNo ||
                challanEditValue.challanAmount ||
                challanEditValue.challanDate
                  ? orderDetails?.allocatedTo?.[0]?.challanInfo?.challanMediaId
                  : undefined,
              challanDate: formatDateInvoiceApi(challanEditValue.challanDate),
            },
          };
        }

        await updateSupplierChallan({
          auth,
          orderId: orderDetails?._id,
          data: updatedParts,
        });

        await updateData();
        notification({
          status: "success",
          title: intl.formatMessage(messages.challanUploadedSuccessfully),
        });
        setChallanEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        setInvoiceEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
      } catch (error) {
        setChallanEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        setInvoiceEditProps((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        notification({
          status: "error",
          description: intl.formatMessage(messages.errorOccurred),
        });
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc =
    "//cdn.jsdelivr.net/npm/pdfjs-dist@3.10.111/build/pdf.worker.js";

  async function loadPdf(selectedFile) {
    if (!selectedFile) return;
    try {
      let pdfData;
      if (selectedFile) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          pdfData = new Uint8Array(fileReader.result);
          extractPdfContent(pdfData);
        };
        fileReader.readAsArrayBuffer(selectedFile);
      }
    } catch (error) {
      notification({
        status: "error",
        description: intl.formatMessage(messages.errorOnFileLoad),
      });
    } finally {
      dispatcher(setLastApiCallTime(new Date().getTime()));
    }
  }

  async function extractPdfContent(pdfData) {
    try {
      const loadingTask = pdfjs.getDocument({ data: pdfData });
      const pdfDocument = await loadingTask.promise;
      const numPages = pdfDocument.numPages;
      let pdfContent = "";

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdfDocument.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map((item) => item?.str).join(" ");
        pdfContent += pageText + "\n";
      }

      const invoiceNoRegex = /Invoice No\.: (\d+\/[A-Z]+\/\d+)/i;
      const netPayableRegex = /Net Bill Amount\s+([\d,]+(?:\.\d+)?)/i;
      const invoiceDateRegex = /Sale Type:\s+([0-9]{2}\/[0-9]{2}\/[0-9]{4})/i;
      const itemsRegex = /No\.of\s+Items\s*:\s*(\d+)/;
      const qtyRegex = /([\d,.]+)\s*Total\s*Qty\.?/i;

      const invoiceNoMatch = pdfContent.match(invoiceNoRegex);
      const netPayableMatch = pdfContent.match(netPayableRegex);
      const invoiceDateMatch = pdfContent.match(invoiceDateRegex);
      const itemsMatch = pdfContent.match(itemsRegex);
      const qtyMatch = pdfContent.match(qtyRegex);


      if (invoiceNoMatch && netPayableMatch) {
        setInvoiceEditValue((prevState) => ({
          ...prevState,
          invoiceNo: invoiceNoMatch[1],
          invoiceAmount: parseFloat(netPayableMatch[1].replace(/,/g, "")),
          invoiceDate: invoiceDateMatch[1],
          noOfItems: itemsMatch[1],
          totalQtyOfItems: parseFloat(qtyMatch[1].replace(/,/g, "")),
        }));

        setMapSelectState((prevState) => {
          return prevState.map((item) => {
            if (item?.name === "invoiceNo") {
              item.defaultValue = invoiceNoMatch[1];
            }
            if (item?.name === "invoiceAmount") {
              item.defaultValue = parseFloat(
                netPayableMatch[1].replace(/,/g, "")
              );
            }

            if (item?.name === "invoiceDate") {
              item.defaultValue = formatDateReverse(invoiceDateMatch[1]);
            }

            if (item?.name === "noOfItems") {
                item.defaultValue = itemsMatch[1];
            }

            if (item?.name === "totalQtyOfItems") {
                item.defaultValue = parseFloat(
                  qtyMatch[1].replace(/,/g, "")
                );
            }

            return item;
          });
        });
      } else {
        notification({
          status: "error",
          description: intl.formatMessage(messages.errorOnFileLoad),
        });
        setInvoiceEditValue({
          invoiceAmount: "",
          invoiceDate: "",
          invoiceNo: "",
          noOfItems: "",
          totalQtyOfItems: "",
        });

        setMapSelectState((prevState) => {
          return prevState.map((item) => {
            if (item?.name === "invoiceNo") {
              item.defaultValue = "";
            }

            if (item?.name === "invoiceAmount") {
              item.defaultValue = "";
            }

            if (item?.name === "invoiceDate") {
              item.defaultValue = "";
            }

            if (item?.name === "noOfItems") {
                item.defaultValue = "";
            }

            if(item?.name === "totalQtyOfItems") {
                item.defaultValue = "";
            }

            return item;
          });
        });
      }
    } catch (error) {
      notification({
        status: "error",
        description: intl.formatMessage(messages.errorOnFileLoad),
      });
    } finally {
      dispatcher(setLastApiCallTime(new Date().getTime()));
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    loadPdf(selectedFile);
    setPdfFile(selectedFile);
    dispatcher(setLastApiCallTime(new Date().getTime()));
  };

  return (
    <SimpleModalNew
      onClose={() => {
        setInvoiceEditProps((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
        setChallanEditProps((prevState) => ({
          ...prevState,
          isOpen: false,
        }));

        if (!_.isEmpty(orderDetails)) {
          setMapSelectState((prevState) => {
            return prevState.map((item) => {
              if (item?.name === "invoiceNo") {
                item.defaultValue = item.invoiceNo
                  ? item?.invoiceNo
                  : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceNo;
              }

              if (item?.name === "invoiceAmount") {
                item.defaultValue = item.invoiceAmount
                  ? item?.invoiceAmount
                  : parseFloat(
                      orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount.replace(
                        /,/g,
                        ""
                      )
                    );
              }

              if (item?.name === "invoiceDate") {
                item.defaultValue = orderDetails?.allocatedTo?.[0]?.invoiceInfo
                  ?.invoiceDate
                  ? formatDateReverse(
                      orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceDate
                    )
                  : undefined;
              }

              if (item?.name === "noOfItems") {
                item.defaultValue = item.noOfItems
                  ? item?.noOfItems
                  : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.noOfItems;
              }
    
              if (item?.name === "totalQtyOfItems") {  
                  item.defaultValue = item.totalQtyOfItems
                  ? item?.totalQtyOfItems
                  : orderDetails?.allocatedTo?.[0]?.invoiceInfo?.totalQtyOfItems;
              }

              return item;
            });
          });
        }
        if (!_.isEmpty(orderDetails)) {
          setChallanMapSelectState((prevState) => {
            return prevState.map((item) => {
              if (item?.name === "challanNo") {
                item.defaultValue = item.challanNo
                  ? item?.challanNo
                  : orderDetails?.allocatedTo?.[0]?.challanInfo?.challanNo;
              }

              if (item?.name === "challanAmount") {
                item.defaultValue = item.challanAmount
                  ? item?.challanAmount
                  : parseFloat(
                      orderDetails?.allocatedTo?.[0]?.challanInfo?.challanAmount.replace(
                        /,/g,
                        ""
                      )
                    );
              }

              if (item?.name === "challanDate") {
                item.defaultValue = orderDetails?.allocatedTo?.[0]?.challanInfo
                  ?.challanDate
                  ? formatDateReverse(
                      orderDetails?.allocatedTo?.[0]?.challanInfo?.challanDate
                    )
                  : undefined;
              }

              return item;
            });
          });
        }
        setPdfFile(null);
        setInvoiceEditValue({
          invoiceNo:
            orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceNo || "",
          invoiceAmount:
            orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount || "",
          invoiceDate:
            orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceDate || "",
          noOfItems: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.noOfItems || "",
          totalQtyOfItems: orderDetails?.allocatedTo?.[0]?.invoiceInfo?.totalQtyOfItems || "",
        });
        setChallanEditValue({
          challanNo:
            orderDetails?.allocatedTo?.[0]?.challanInfo?.challanNo || "",
          challanAmount:
            orderDetails?.allocatedTo?.[0]?.challanInfo?.challanAmount || "",
          challanDate:
            orderDetails?.allocatedTo?.[0]?.challanInfo?.challanDate || "",
        });
      }}
      isOpen={invoiceEditProps.isOpen || challanEditProps.isOpen}
      title="Upload Invoice/Challan"
      okText="Save"
      isDisabled={
        fileType === "" ||
        (fileType === "Invoice" &&
          (!invoiceEditValue.invoiceAmount ||
            !invoiceEditValue.invoiceNo ||
            !invoiceEditValue.invoiceDate ||
            !invoiceEditValue.noOfItems ||
            !invoiceEditValue.totalQtyOfItems ||
            (invoiceEditValue.invoiceNo ===
              orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceNo &&
              invoiceEditValue.invoiceAmount ===
                orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount &&
              invoiceEditValue.invoiceDate ===
                orderDetails?.allocatedTo?.[0]?.invoiceInfo?.invoiceDate &&
              invoiceEditValue.noOfItems ===
                orderDetails?.allocatedTo?.[0]?.invoiceInfo?.noOfItems &&
              invoiceEditValue.totalQtyOfItems ===
                orderDetails?.allocatedTo?.[0]?.invoiceInfo?.totalQtyOfItems
              ))) ||
        (fileType === "Challan" &&
          (!challanEditValue.challanAmount ||
            !challanEditValue.challanNo ||
            !challanEditValue.challanDate ||
            (challanEditValue.challanNo ===
              orderDetails?.allocatedTo?.[0]?.challanInfo?.challanNo &&
              challanEditValue.challanAmount ===
                orderDetails?.allocatedTo?.[0]?.challanInfo?.challanAmount &&
              challanEditValue.challanDate ===
                orderDetails?.allocatedTo?.[0]?.challanInfo?.challanDate)))
      }
      onOk={() => handleUpdateInvoice()}
      isLoading={invoiceEditProps.isLoading || challanEditProps.isLoading}
    >
      <>
        <Center
          cursor={"pointer"}
          w="fit-content"
          borderRadius={"full"}
          boxShadow={"0 0 5px 3px lightgray"}
          p="4"
          m="auto"
          opacity={0.5}
          onClick={() => inputRef.current?.click()}
        >
          <GrUpload />
          <Input
            ref={inputRef}
            type="file"
            cursor={"pointer"}
            id="fileInput"
            accept=".pdf"
            opacity={0}
            position="absolute"
            zIndex="-1"
            autoFocus={false}
            visibility={"hidden"}
            onChange={handleFileChange}
          />
        </Center>
        <Center my={2}>
          <Text color="gray.600">Drop your file here</Text>
        </Center>
        <Center my={3}>
          <Button
            bg="blue.500"
            _hover={{ backgroundColor: "blue.500" }}
            as="label"
            htmlFor="fileInput"
            variant="outline"
            cursor={"pointer"}
            borderColor="gray.300"
            rightIcon={<AiFillCaretDown />}
            size="sm"
            color={"white"}
          >
            Choose File
          </Button>
        </Center>
        {pdfFile && pdfFile?.name && (
          <Text textAlign={"center"} fontWeight={"bold"} my={2}>
            {pdfFile?.name}
          </Text>
        )}
        {(pdfFile ||
          mapSelectState.some((item) => item?.defaultValue) ||
          challanMapSelectState.some((item) => item?.defaultValue)) && (
          <Box mb={2}>
            <Text fontWeight={"bold"} mb={1}>
              File Type
            </Text>
            <RadioGroup value={fileType} onChange={setFileType}>
              <Radio value="Invoice">Invoice</Radio>
              <Radio value="Challan" ml={4}>
                Challan
              </Radio>
            </RadioGroup>
          </Box>
        )}

        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          {(pdfFile !== null ||
            mapSelectState.some((item) => item?.defaultValue) ||
            challanMapSelectState.some((item) => item?.defaultValue)) &&
            fileType === "Invoice" &&
            mapSelectState.map((e) => {
              return (
                <Box key={e.name} width="100%">
                  <SingleInput inputObj={e} onChange={handleChange} />
                </Box>
              );
            })}
          {(pdfFile !== null ||
            challanMapSelectState.some((item) => item?.defaultValue) ||
            mapSelectState.some((item) => item?.defaultValue)) &&
            fileType === "Challan" &&
            challanMapSelectState.map((e) => {
              return (
                <Box key={e.name} width="100%">
                  <SingleInput inputObj={e} onChange={handleChange} />
                </Box>
              );
            })}
        </Box>
      </>
    </SimpleModalNew>
  );
};

export default garaazToast(UpdateInvoice);
