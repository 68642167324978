// Needed for redux-saga es6 generator support
import React, { useEffect, useContext, createContext } from "react";
import store from "./configureStore";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Spinner } from "@chakra-ui/react";
import "./assets/tailwind.min.css";
import "./assets/index.css";
import "./assets/docs.css";
import Layout from "./containers/Layout";
import messages from "./messages";
import routes from "./routes";
import {
  getEmployees,
  getBranches,
  getDistributorInfo,
  getSupplierInfo,
  selectGlobalState,
  getWorkshops,
  setIsDistributor,
  getCitiesAndStates,
  addEmployee,
  getAllNotifications,
  getUnreadNotificationCount,
  addSingleNotification,
  getTotalNotificationCount,
} from "./globalSlice";
import _ from "lodash";

import subscribeToNotification, {
  onMessageListener,
} from "./utils/firebaseConfig";

import LoginPage from "./containers/LoginPage/Loadable";
import Profile from "./containers/Profile/Loadable";
import BranchInfo from "./containers/BranchInfo/Loadable";

import EmployeeDashboard from "./containers/EmployeeDashboard/Loadable";
import EmployeeAddition from "./containers/EmployeeAddition/Loadable";

import Parts from "./containers/Parts/Loadable";
import PartAddition from "./containers/PartAddition/Loadable";
import PartInfo from "./components/PartInfo/Loadable";
import PartBulk from "./components/PartBulk/Loadable";

import Workshop from "./containers/WorkShop/Loadable";
import WorkshopAddition from "./components/WorkShopAddition/Loadable";

import Inventory from "./containers/Inventory/Loadable";

import Invite from "./components/InviteResponse";

import NotFoundPage from "./containers/NotFoundPage/Loadable";

// Import i18n messages
import { setupAuthAxios, setupPublicAxios } from "./axios";
import { attemptLogin } from "./containers/LoginPage/actions";

import "sanitize.css/sanitize.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ROUTES } from "./routes";
import WorkShopBulk from "./components/WorkShopBulk/Loadable";
import BranchBulk from "./components/BranchBulk/Loadable";
import EmployeeBulk from "./components/EmployeeAddition/EmployeeBulk";

import OutstandingAccount from "./containers/OutstandingAccount/Loadable";
import Collections from "./containers/CollectionsAccount/Loadable";

import garaazToast from "./HOCs/garaazToast";
import OrderListNew from "./containers/OrderListNew";

export const TitleContext = createContext({});
export const OnboardContext = createContext({});
export const UseTitleContext = () => useContext(TitleContext);
export const UseOnboardContext = () => useContext(OnboardContext);

// axios setup
setupPublicAxios(process.env.REACT_APP_BASE_URL);
const authJSON = JSON.parse(localStorage.getItem("auth"));
if (authJSON) {
  store.dispatch(attemptLogin(authJSON));
  setupAuthAxios(process.env.REACT_APP_BASE_URL, authJSON.authToken);
}

const App = () => {
  const [onboard, setOnboard] = React.useState("");
  const auth = useSelector((state) => state.auth);
  const hist = useHistory();
  const showSpinner = useSelector((state) => state.app.showSpinner);
  const dispatcher = useDispatch();

  useEffect(() => {
    if (localStorage.isDistributor) {
      dispatcher(setIsDistributor(JSON.parse(localStorage.isDistributor)));
    }
  }, []);

  useEffect(() => {
    if (localStorage.onboard) {
      setOnboard(parseInt(JSON.parse(localStorage.onboard)));
    }
    if (localStorage.isDistributor) {
      setIsDistributor(JSON.parse(localStorage.isDistributor));
    }
  }, []);

  useEffect(() => {
    const { pathname } = hist.location;
    if (
      auth.authToken &&
      (pathname === "/login" ||
        pathname === "/" ||
        pathname === "/register" ||
        pathname === "/invite")
    ) {
      hist.push(ROUTES.ORDERS);
    }
    if (!auth.authToken && !pathname.startsWith("/invite")) {
      hist.push("/login");
    }
  }, [auth]);

  const updateOnboard = (onboard) => {
    setOnboard(onboard);
  };

  return (
    <OnboardContext.Provider value={{ updateOnboard, onboard }}>
      <div style={{ height: "100vh" }}>
        <Switch>
          <Route exact path="/">
            {auth.authToken ? <OrderListNew /> : <LoginPage />}
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/invite">
            <Invite />
          </Route>

          {auth.authToken && (
            <>
              <ProtectedComponents />
            </>
          )}
          {/*  <Route component={NotFoundPage} /> */}
        </Switch>
        <Toaster />
        {showSpinner && <Spinner />}
      </div>
    </OnboardContext.Provider>
  );
};

const ProtectedComponents = garaazToast((props) => {
  const intl = useIntl();
  const [currentTitle, setTitle] = React.useState();
  const [isTitleUpdated, setIsTitleUpdated] = React.useState(false);
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { distributor, isDistributor, employee } =
    useSelector(selectGlobalState);
  const dispatcher = useDispatch();

  // const getLatestNotificationInfo = () => {
  //   dispatcher(getUnreadNotificationCount({ auth }));
  //   dispatcher(getTotalNotificationCount({ auth }));
  // };

  // useEffect(() => {
  //   window.addEventListener("focus", getLatestNotificationInfo);
  //   return () => window.removeEventListener("focus", getLatestNotificationInfo);
  // }, []);

  useEffect(() => {
    if (auth && distributor._id && isDistributor) {
      subscribeToNotification(
        { auth, id: distributor._id },
        props.notification
      );
    }
  }, [auth, distributor]);

  useEffect(() => {
    (async () => {
      dispatcher(getBranches({ auth }));
      dispatcher(getCitiesAndStates({ auth }));
      dispatcher(getEmployees({ auth }));
      dispatcher(getUnreadNotificationCount({ auth }));
      dispatcher(getTotalNotificationCount({ auth }));

      if (localStorage.isDistributor) {
        dispatcher(getDistributorInfo({ auth }));
      }

      await onMessageListener((data) => {
        data.payload = JSON.parse(data?.payload);
        data.read = false;
        dispatcher(addSingleNotification(data));
      });
    })();
  }, [auth]);

  useEffect(() => {
    if (distributor._id && isDistributor) {
      dispatcher(
        getWorkshops({
          auth,
          distributorId: distributor._id,
        })
      );
      dispatcher(getSupplierInfo({ supplierId: distributor._id, auth: auth }));
    } else if (employee.distributorId && !isDistributor) {
      dispatcher(
        getSupplierInfo({
          supplierId: employee?.distributorId,
          auth: auth,
        })
      );
      dispatcher(
        getWorkshops({
          auth,
          distributorId: employee.distributorId,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor, employee]);

  useEffect(() => {
    if (
      !isDistributor &&
      localStorage.employee &&
      localStorage.employee !== "undefined"
    ) {
      dispatcher(addEmployee(JSON.parse(localStorage.employee)));
    }
  }, [isDistributor]);

  useEffect(() => {
    switch (location.pathname) {
      case ROUTES.BRANCHES:
      case ROUTES.BRANCH_ADD:
      case ROUTES.BRANCH_ADD_BULK:
      case ROUTES.BRANCH_EDIT:
        setTitle(intl.formatMessage(messages.branch));
        break;
      case ROUTES.EMPLOYEES:
      case ROUTES.EMPLOYEE_ADD:
      case ROUTES.EMPLOYEE_ADD_BULK:
        setTitle(intl.formatMessage(messages.employee));
        break;
      case ROUTES.PARTS:
        setTitle(intl.formatMessage(messages.part));
        break;
      case ROUTES.PARTS_ADD:
      case ROUTES.PART_ADD_BULK:
      case ROUTES.PART_EDIT:
        setTitle(intl.formatMessage(messages.partAddition));
        break;
      case ROUTES.PART_INFO:
        setTitle(intl.formatMessage(messages.partInfo));
        break;
      case ROUTES.WORKSHOPS:
      case ROUTES.WORKSHOP_ADD:
      case ROUTES.WORKSHOP_ADD_Bulk:
      case ROUTES.WORKSHOP_EDIT:
        setTitle(intl.formatMessage(messages.workshop));
        break;
      case routes.orderRoute:
        setTitle(intl.formatMessage(messages.customerOrders));
        break;
      case routes.getAllGaraazOrders:
        setTitle(intl.formatMessage(messages.customerOrders));
        break;

      case ROUTES.PROFILE:
        setTitle(intl.formatMessage(messages.profile));
        break;
      default:
        const routeName = location.pathname
          .split("/")
          [location.pathname.split("/").length - 1].split("-")
          .join(" ");
        setTitle(routeName);
        return;
    }

    setIsTitleUpdated(true);
  }, [location.pathname]);

  const updateTitle = (title) => {
    setTitle(title);
  };

  return (
    <TitleContext.Provider
      value={{ isTitleUpdated, currentTitle, updateTitle }}
    >
      <Layout title={currentTitle}>
        <Switch>
          {isDistributor && (
            <Route exact path={ROUTES.BRANCH_ADD} component={BranchInfo} />
          )}
          {isDistributor && (
            <Route exact path={ROUTES.BRANCH_ADD_BULK} component={BranchBulk} />
          )}
          {isDistributor && (
            <Route exact path={ROUTES.BRANCH_EDIT} component={BranchInfo} />
          )}
          {isDistributor && (
            <Route
              exact
              path={ROUTES.EMPLOYEES}
              component={EmployeeDashboard}
            />
          )}
          {isDistributor && (
            <Route
              exact
              path={ROUTES.EMPLOYEE_ADD}
              component={EmployeeAddition}
            />
          )}
          {isDistributor && (
            <Route
              exact
              path={ROUTES.EMPLOYEE_ADD_BULK}
              component={EmployeeBulk}
            />
          )}

          <Route exact path={ROUTES.PARTS} component={Parts} />
          {isDistributor && (
            <Route exact path={ROUTES.PARTS_ADD} component={PartAddition} />
          )}
          {isDistributor && (
            <Route exact path={ROUTES.PART_ADD_BULK} component={PartBulk} />
          )}
          {isDistributor && (
            <Route exact path={ROUTES.PART_INFO} component={PartInfo} />
          )}
          {isDistributor && (
            <Route exact path={ROUTES.PART_EDIT} component={PartAddition} />
          )}

          <Route exact path={ROUTES.WORKSHOPS} component={Workshop} />

          {isDistributor && (
            <Route
              exact
              path={ROUTES.WORKSHOP_ADD}
              component={WorkshopAddition}
            />
          )}
          {isDistributor && (
            <Route
              exact
              path={ROUTES.WORKSHOP_ADD_Bulk}
              component={WorkShopBulk}
            />
          )}
          {isDistributor && (
            <Route
              exact
              path={ROUTES.WORKSHOP_EDIT}
              component={WorkshopAddition}
            />
          )}

          <Route path={routes.orderRoute} component={OrderListNew} />
          <Route
            path={routes.outstandingRoute}
            component={OutstandingAccount}
          />
          <Route path={routes.collectionsRoute} component={Collections} />
          <Route path={routes.inventoryRoute} component={Inventory} />

          <Route path={routes.profile} component={Profile} />
        </Switch>
      </Layout>
    </TitleContext.Provider>
  );
});
export default App;
