import { defineMessages } from 'react-intl';

export const scope = 'app.components.EmployeeAddition';

export default defineMessages({
  employeeAddition: {
    id: `${scope}.employeeAddition`,
    defaultMessage: 'Employee Addition',
  },
  mobileNumber: {
    id: `${scope}.mobileNumber`,
    defaultMessage: 'Mobile Number',
  },
  mobileNumberRequired: {
    id: `${scope}.mobileNumberRequired`,
    defaultMessage: 'Mobile Number Required',
  },
  invalidMobileNumber: {
    id: `${scope}.invalidMobileNumber`,
    defaultMessage: 'Enter a valid Mobile Number',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  firstNameRequired: {
    id: `${scope}.firstNameRequired`,
    defaultMessage: 'First Name field is required.',
  },

  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  lastNameRequired: {
    id: `${scope}.lastNameRequired`,
    defaultMessage: 'Last Name field is required.',
  },
  designation: {
    id: `${scope}.designation`,
    defaultMessage: 'Designation',
  },
  designationRequired: {
    id: `${scope}.designationRequired`,
    defaultMessage: 'Designation Required',
  },
  deputation: {
    id: `${scope}.deputation`,
    defaultMessage: 'Deputation',
  },
  deputationRequired: {
    id: `${scope}.deputationRequired`,
    defaultMessage: 'Deputation Required',
  },

  reportsTo: {
    id: `${scope}.reportsTo`,
    defaultMessage: 'Reports To',
  },
  reportsToRequired: {
    id: `${scope}.reportsToRequired`,
    defaultMessage: 'Reports To Required',
  },
  employeeId: {
    id: `${scope}.employeeId`,
    defaultMessage: 'EmployeeId',
  },
  employeeIdRequired: {
    id: `${scope}.employeeIdRequried`,
    defaultMessage: 'Employee Id Requried',
  },
  branch: {
    id: `${scope}.branch`,
    defaultMessage: 'Branch Code',
  },
  employeeDetailDocument: {
    id: `${scope}.employeeDetailDocument`,
    defaultMessage: 'Employee Detail Document',
  },
  employeeDetailDocumentRequired: {
    id: `${scope}.employeeDetailDocumentRequired`,
    defaultMessage: 'Employee Detail Document field is required.',
  },
  saveButtonLabel: {
    id: `${scope}.saveButtonLabel`,
    defaultMessage: 'Save',
  },
  cancelButtonLabe: {
    id: `${scope}.cancelButtonLabe`,
    defaultMessage: 'Clear',
  },
  dragAndDropMessage: {
    id: `${scope}.dragAndDropMessage`,
    defaultMessage: 'Drag and drop a file here, or click to select file.',
  },
  dropFilesHere: {
    id: `${scope}.dropFilesHere`,
    defaultMessage: 'Drop your files here',
  },
  branchNameRequired: {
    id: `${scope}.branchNameRequired`,
    defaultMessage: 'Branch Name is required!',
  },
  designationRequired: {
    id: `${scope}.designationRequired`,
    defaultMessage: 'Designation is required!',
  },

  somethingWentWrong: {
    id: `${scope}.somethingWentWrong`,
    defaultMessage: 'Something went wrong!',
  },
  employeeSuccessUpload: {
    id: `${scope}.employeeSuccessUpload`,
    defaultMessage: 'Employee Details captured successfully',
  },


});
