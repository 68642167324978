import {
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Box,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalState } from "../../globalSlice";
import { attemptLogout } from "../../containers/LoginPage/actions";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { logout } from "../../reducers";
import messages from "./messages";
import { MdAccountCircle } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
import { UseOnboardContext } from "../../App";
import {
  distributorNavObj,
  employeeNavObj,
  incompleteNavObj,
  lang,
} from "./helpers";

const ProfileDropDown = ({ isOpen, SingleNav }) => {
  const { isDistributor, supplier, allAvailableBranches, employee } =
    useSelector(selectGlobalState);
  const { onboard } = UseOnboardContext();
  const dispatch = useDispatch();
  const getSupplierBranchName = useCallback(
    (branchId) => {
      const branch = allAvailableBranches?.find(
        (branch) => branch?._id === branchId
      );
      return branch ? branch?.branchName : "";
    },
    [allAvailableBranches, employee]
  );
  const getSupplierAcronym = (supplierBusinessName) => {
    const supplierName = isDistributor
      ? supplier?.businessName
      : `${supplier?.businessName}-${getSupplierBranchName(
          employee?.branchId
        )}`;
    return supplierName
      ?.split(" ")
      .map((word) => word[0])
      .join("");
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Box
          width={"30px"}
          height={"30px"}
          borderRadius={"50%"}
          overflow={"clip"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          border={"1px solid white"}
          ml={4}
        >
          <Text fontSize={"large"} color={"white"}>
            {getSupplierAcronym()}
          </Text>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        _focus={{
          outline: "none",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
        border={"1px solid grey"}
      >
        <Box display={"flex"} borderBottom={"1px solid lightgrey"} p={3}>
          <Box
            width={"50px"}
            height={"50px"}
            borderRadius={"50%"}
            overflow={"clip"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            border={"1px solid lightgrey"}
          >
            <Text fontSize={"2xl"}>{getSupplierAcronym()}</Text>
          </Box>
          <Box
            ml={4}
            alignItems={"flex-start"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Text fontWeight={"bold"}>
              {isDistributor
                ? supplier?.businessName
                : `${supplier?.businessName}-${getSupplierBranchName(
                    employee?.branchId
                  )}`}
            </Text>
            <Text fontSize={"xs"}>Garaaz Supplier</Text>
          </Box>
        </Box>
        <Box p={3}>
          {onboard && isDistributor
            ? incompleteNavObj(isOpen).map((item) => {
                return (
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <SingleNav
                      key={item.to}
                      to={item.to}
                      icon={item.icon}
                      message={item.message}
                    />
                  </div>
                );
              })
            : (isDistributor
                ? distributorNavObj(isOpen)
                : employeeNavObj(isOpen)
              ).map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                    }}
                    key={index}
                  >
                    <SingleNav
                      key={item.to}
                      to={item.to}
                      icon={item.icon}
                      message={item.message}
                    />
                  </div>
                );
              })}
          {isDistributor && (
            <Box display={"flex"} alignItems={"center"}>
              <NavLink
                exact
                to={"/profile"}
                className={classnames(`text-sm hover:text-red-700 block`)}
                activeClassName="text-red-700"
              >
                <Icon fontSize={"large"} as={MdAccountCircle} mr={1}></Icon>
                <FormattedMessage {...messages.profile} />
              </NavLink>
            </Box>
          )}

          <Box display={"flex"} mt={1} alignItems={"center"}>
            <NavLink
              onClick={() => {
                dispatch(attemptLogout());
                dispatch(logout());
              }}
              exact
              to="/login"
              className={classnames("text-sm hover:text-red-700 block")}
              activeClassName="text-red-500 hover:text-red-700"
            >
              <Icon
                fontSize={"medium"}
                as={FaSignOutAlt}
                mr={2}
                transform={"translateX(3px)"}
              ></Icon>
              <FormattedMessage {...messages.logout} />
            </NavLink>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default ProfileDropDown;
