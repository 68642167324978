import produce from 'immer';
import { SET_ORDER_DETAILS } from './constants';


const initialState = {
    orderDetails: {workshop:  {workshopcustomers:[]}, orderItems: []}
}

/* eslint-disable default-case, no-param-reassign */
const orderDetailReducer = (state = initialState, {payload, type}) => {
    return produce(state, draft => {
        switch(type){
            case SET_ORDER_DETAILS: 
               draft.orderDetails = payload;
               break; 
        }
    })
}

export default orderDetailReducer;


