import React, { forwardRef } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from "@chakra-ui/react";

const SimpleModal = forwardRef(
  (
    {
      hidefooter,
      size,
      onClose,
      isOpen,
      children,
      okText,
      onOk,
      title,
      isLoading,
      isModalBody,
      isDisabled,
    },
    ref
  ) => {
    return (
      <Modal
        isCentered
        motionPreset="slideInBottom"
        size={size ?? "sm"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        width={{ base: "100%", lg: "30%" }}
        overflow-y="auto"
        returnFocusOnClose={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton onClick={onClose} />

          {!isModalBody ? (
            <ModalBody>{children}</ModalBody>
          ) : (
            <Box p={5}>{children}</Box>
          )}

          {!hidefooter && (
            <ModalFooter display="flex" justifyContent="flex-end">
              <Button
                mt={0}
                alignContent="right"
                variant="ghost"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                mt={0}
                alignContent="right"
                ref={ref}
                isLoading={isLoading}
                variant="normal"
                onClick={onOk}
                isDisabled={isDisabled}
              >
                {okText}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    );
  }
);

export default SimpleModal;
