import { publicAxios as axios } from "./axios";
import routes, { ROUTES } from "./routes";
import getURLParams from "./utils/getURLParams";

class Services {
  static async getBranches(payload) {
    return await axios({
      method: "GET",
      url: `${ROUTES.BRANCHES}?sort=updatedAt,asc`,
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getWorkshops(payload) {
    const params = getURLParams(payload.params);
    if (!payload.distributorId) return;
    return await axios.get(
      `/distributors/${payload.distributorId}/workshops${params}`,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getCustomers(payload) {
    const params = getURLParams(payload.params);
    return await axios.get(
      `/v1/customers${params}`,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getParts(payload) {
    const params = getURLParams(payload.params);

    return await axios.get(`${routes.partRoute}/${params}`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }
  static async getPartsFromInventory(payload) {
    const params = getURLParams(payload.params);

    return await axios.get(`${routes.inventoryRoute}/${params}`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getWorkshopsMobile(payload) {
    const params = getURLParams(payload.params);

    return await axios.get(
      `/distributors/${payload.distributorId}/workshops${params}`,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getWorkshopLocation(payload) {
    return await axios.get(`/workshops/${payload.workshopId}/locations`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getDistributorInfo(payload) {
    return await axios.get("/distributors", {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getInventoryStat(payload) {
    return await axios.get(`${routes.inventoryRoute}/stats`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getSalesStat(payload) {
    return await axios.get(`${routes.salesRoute}/stats`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getCollectionsStat(payload) {
    return await axios.get(
      `${routes.accountRoute}${routes.collectionsRoute}/stats`,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getOutstandingStat(payload) {
    return await axios.get(
      `${routes.accountRoute}${routes.outstandingRoute}s/stats`,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getOrderStat(payload) {
    return await axios.get(`${routes.orderRoute}/stats`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }
  static async getCitiesAndStates(payload) {
    return await axios.get(`/utility/state-city`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getEmployees(payload) {
    return await axios.get("/employees", {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getAllNotifications(payload) {
    const params = getURLParams(payload.params);
    return await axios.get(`${routes.notificationRoute}${params}`, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async readNotification(payload) {
    return await axios.patch(routes.readNotificationRoute, payload.data, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getUnreadNotificationCount(payload) {
    return await axios.get(routes.getUnreadNotificationCount, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getTotalNotificationCount(payload) {
    return await axios.get(routes.getTotalNotificationCount, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async markNotificationAsRead(payload) {
    return await axios.patch(routes.markNotificationAsRead, payload.data, {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async getEmployeeDetails(payload) {
    return await axios.get(routes.employee(payload.userId), {
      headers: {
        Authorization: payload.auth,
      },
    });
  }

  /* static async markAllAsRead(payload) {
    return await axios({
      method: 'PATCH',
      url: routes.markAllAsRead,
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  } */

  static async markAllAsRead(payload) {
    return await axios.patch(
      routes.markAllAsRead,
      {},
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async getSupplierInfo(payload) {
    return await axios.get(routes.getSuplierinfo(payload.supplierId), {
      headers: {
        Authorization: payload.auth.authToken,
      },
    });
  }

  static async updateSupplierInfo(payload) {
    return await axios.put(
      routes.updateSuplierinfo(payload.supplierId),
      payload.data,
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }

  static async deleteSupplierBranchById(payload) {
    return await axios.delete(
      routes.deleteSupplierBranchById(payload.supplierId, payload.branchId),
      {
        headers: {
          Authorization: payload.auth.authToken,
        },
      }
    );
  }
}

export default Services;
