import { useEffect, useState } from "react";
import { IconButton, NumberInput, NumberInputField } from "@chakra-ui/react";
import { FaRegTrashAlt } from "react-icons/fa";
import getMoneyFormat from "../../utils/getMoneyFormat";
import enums from "../../utils/enums";

const TableRow = (props) => {
  const [quantityValue, setQuantity] = useState(props.data.orderedQuantity);
  const [givenQuantityValue, setGivenQuantity] = useState(
    props.data?.givenQuantity
  );

  useEffect(() => {
    setGivenQuantity(props?.data?.givenQuantity);
  }, [props?.data?.givenQuantity]);

  const isTableEditable =
    props?.orderStatus !== enums.NEW &&
    props?.orderStatus !== enums.ACK &&
    props?.orderStatus !== enums.CBO;

  const onQuantityChange = (value) => {
    if (!isNaN(value)) {
      setQuantity(value);
      const newData = { ...props.data, orderedQuantity: value };
      props.onChange(newData, "orderedQuantity", props.data?.SNo);
    }
  };

  const onGivenQuantityChange = (value) => {
    if (!isNaN(value)) {
      setGivenQuantity(value);
      const newData = { ...props.data, givenQuantity: value };
      props.onChange(newData, "givenQuantity", props.data?.SNo);
    }
  };

  return (
    <tr>
      <td style={{ width: "5%", textAlign: "center" }}>{props.data?.SNo}</td>
      <td style={{ width: "23%" }}>{props.selectInput}</td>
      <td style={{ width: "25%", textAlign: "center" }}>
        {props.data?.partName}
      </td>
      <td style={{ width: "13%", padding: "8px" }} onKeyDown={props.onKeyDown}>
        <NumberInput
          min={0}
          isReadOnly={isTableEditable}
          value={quantityValue}
          onChange={onQuantityChange}
          p={0}
        >
          <NumberInputField p={1} pl={3} />
        </NumberInput>
      </td>
      <td style={{ width: "13%", padding: "8px" }} onKeyDown={props.onKeyDown}>
        <NumberInput
          min={0}
          isReadOnly={isTableEditable}
          value={givenQuantityValue}
          onChange={onGivenQuantityChange}
          p={0}
        >
          <NumberInputField p={1} pl={3} />
        </NumberInput>
      </td>
      {props.showPrice && (
        <td style={{ width: "12%", textAlign: "center" }}>
          {getMoneyFormat(props.data?.mrp)}
        </td>
      )}
      <td style={{ width: "6%", textAlign: "center" }}>
        <IconButton
          isDisabled={isTableEditable}
          onClick={() => props.onChange(props.data, "delete", props.data?.SNo)}
        >
          <FaRegTrashAlt />
        </IconButton>
      </td>
    </tr>
  );
};

export default TableRow;
