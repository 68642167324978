/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  Flex,
  Icon,
  Box,
  Avatar,
  Text,
  Center,
  useDisclosure,
  Fade,
  useOutsideClick,
  SkeletonText,
  SkeletonCircle,
} from "@chakra-ui/react";
import { AiFillBell } from "react-icons/ai";
import device from "../../utils/mediaQueries";
import { useSelector, useDispatch } from "react-redux";
import {
  selectGlobalState,
  readNotification,
  getAllNotifications,
  markAllAsRead,
} from "../../globalSlice";
import uuid from "react-uuid";
import moment from "moment";
import { useHistory } from "react-router";
import enums from "../../utils/enums";

export const Notification = (props) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const dropdownRef = useRef();
  const auth = useSelector((state) => state.auth);
  const { allNotifications, unreadNoticationCount, totalNotificationCount } =
    useSelector(selectGlobalState);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);

  useOutsideClick({
    ref: dropdownRef,
    handler: onClose,
  });

  const dispatcher = useDispatch();

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          await dispatcher(
            getAllNotifications({ auth, params: { limit, offset: 0 } })
          );
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }
  }, [isOpen]);

  const notificationArr = [...allNotifications].map((note) => {
    return <SingleNote key={uuid()} data={note} onClose={onClose} />;
  });

  const handleScroll = (event) => {
    const contentHeight = event.target.scrollHeight;
    const displace = event.target.scrollTop;
    const height = event.target.getBoundingClientRect().height;
    if (
      contentHeight - (displace + height) < 1 &&
      limit < totalNotificationCount
    ) {
      dispatcher(
        getAllNotifications({ auth, params: { limit: limit + 10, offset: 0 } })
      );
      setLimit((prevState) => prevState + 10);
    }
  };

  const handleMarkAllAsRead = () => {
    if (Boolean(unreadNoticationCount)) {
      dispatcher(markAllAsRead({ auth }));
    }
    onToggle();
  };

  return (
    <Box position="relative" ref={dropdownRef}>
      <Box
        onClick={handleMarkAllAsRead}
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
      >
        <Icon as={AiFillBell} color="white" _hover={{ color: "red.700" }} />
        {Boolean(unreadNoticationCount) &&
          !isNaN(parseInt(unreadNoticationCount)) && (
            <StyledUnreadCount>
              {unreadNoticationCount > 9 ? "9+" : unreadNoticationCount}
            </StyledUnreadCount>
          )}
      </Box>

      {isOpen && (
        <Fade in={isOpen}>
          <StyledNotificationDropdown boxShadow="lg">
            <StyledNotTitle>
              <Box textStyle="label" layerStyle="muted" fontSize="lg">
                Notifications
              </Box>
            </StyledNotTitle>
            {isLoading && <Loading isLoaded={isLoading} />}
            {Boolean(allNotifications.length) && !isLoading && (
              <StyledBoxCont onScroll={handleScroll}>
                {notificationArr}
              </StyledBoxCont>
            )}{" "}
            {!Boolean(allNotifications.length) && !isLoading && (
              <Center height="100%" width="100%">
                <Box textStyle="label" layerStyle="muted">
                  No Data
                </Box>
              </Center>
            )}
          </StyledNotificationDropdown>
        </Fade>
      )}
    </Box>
  );
};

const SingleNote = ({ data, onClose }) => {
  const auth = useSelector((state) => state.auth);
  const dispatcher = useDispatch();
  const history = useHistory();

  const handleMarkNotificationAsRead = () => {
    dispatcher(readNotification({ auth, data: [data._id] }));
  };

  const getRoute = (payload) => {
    switch (payload.status) {
      case enums.UPDATED:
      case enums.PENDING:
        return `/orders/${payload.orderId}`;
      case enums.REQUEST_ORDER:
        return `/orders/${payload.orderId}/request-order`;
      default:
        return `/orders/${data.payload.orderId}/overview`;
    }
  };

  const handleOpenNotification = () => {
    dispatcher(readNotification({ auth, data: [data._id] }));
    onClose();
    if (
      data.notificationType === enums.ORDER_PLACED ||
      data.notificationType === enums.ORDER_UPDATED
    ) {
      history.push(getRoute(data.payload));
    }
  };
  return (
    <StyledSingleNote boxShadow="sm" onMouseDown={handleOpenNotification}>
      <Avatar
        name={
          data.notificationType === enums.ORDER_PLACED
            ? "Order Received"
            : data.notificationType.split("_").join(" ")
        }
        size="sm"
        mt={1}
      />
      <Box ml={4}>
        <Flex alignItems="flex-start" justifyContent="space-between" mb="2">
          <Text fontWeight="600" layerStyle="muted">
            {data?.title}
          </Text>
          <Box textAlign="right">
            <Text textStyle="labelLight">
              {moment(data.createdAt).format("LT")}
            </Text>
            <Text textStyle="labelLight">
              {moment(data.createdAt).format("DD MMM")}
            </Text>
          </Box>
        </Flex>
        {/* fontSize={{ base: '15px', md: '1.2vw' }} */}
        <Box textStyle="normal">{data?.body}</Box>
      </Box>
      {!data?.read && (
        <Box className="readStatus">
          <StyledReadStatus onClick={handleMarkNotificationAsRead}>
            <div />
          </StyledReadStatus>
        </Box>
      )}
    </StyledSingleNote>
  );
};

const Loading = ({ isLoaded }) => {
  return (
    <>
      <Skeleton isLoaded={isLoaded} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
};
const Skeleton = ({ isLoaded }) => {
  return (
    <Flex alignItems="flex-start" mb={5}>
      <SkeletonCircle size="10" isLoaded={isLoaded} />
      <Box flex="1" ml="4">
        <SkeletonText noOfLines={3} spacing="2" isLoaded={isLoaded} />
      </Box>
    </Flex>
  );
};

const StyledBoxCont = styled(Box)`
  height: 70vh;
  overflow: hidden;
  padding: 10px;
  margin-right: 10px;
  &:hover {
    overflow: auto;
    margin-right: 0px;
  }
`;

const StyledNotIcon = styled.button`
  position: relative;
  margin-right: 16px;
`;
const StyledUnreadCount = styled(Center)`
  position: absolute;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  top: 0;
  left: 50%;
  height: 18px;
  width: 18px;
  font-size: 14px;

  @media ${device.laptop} {
    font-size: 0.8vw;
    height: 1vw;
    width: 1vw;
  }
`;

const StyledNotificationDropdown = styled(Box)`
  padding: 16px;
  padding-right: 6px;
  height: 80vh;
  width: 80vw;
  background-color: white;
  position: absolute;
  border-radius: 5px;
  right: -5vw;
  top: 140%;

  @media ${device.tablet} {
    right: 40%;
    width: 50vw;
  }

  @media ${device.laptop} {
    right: 40%;
    width: 40vw;
  }
`;

const StyledSingleNote = styled.button`
  display: flex;
  align-items: flex-start;
  text-align: left;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 7px -7px,
    rgba(0, 0, 0, 0.1) 0px 7px 7px -7px;
  &:focus,
  &:hover {
    outline: none;
    background-color: #edf2f7;

    & .readStatus {
      & button {
        background-color: #cbd5e0;
      }
    }
  }
`;

const StyledReadStatus = styled.div`
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 0.5vw;
    height: 0.5vw;
    background-color: red;
    border-radius: 50%;
  }
`;

const StyledNotTitle = styled(Flex)`
  background-color: white;
  padding: 10px;
  padding-right: 18px;
  padding-bottom: 0px;
  width: 100%;
  justify-content: space-between;
`;

export default Notification;
