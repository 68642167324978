import { ATTEMPT_LOGIN, ATTEMPT_LOGOUT, SET_AUTH_USER } from './constants';

export function attemptLogin(authData) {
  localStorage.setItem('auth', JSON.stringify(authData));
  return {
    type: ATTEMPT_LOGIN,
    payload: authData,
  };
}

export function attemptLogout() {
  localStorage.removeItem('auth');
  return {
    type: ATTEMPT_LOGOUT,
  };
}

export function setAuthUser(user) {
  return {
    type: SET_AUTH_USER,
    payload: user,
  };
}
