import { publicAxios as axios } from "../../axios";
import { GET_ORDER_DETAILS, SET_ORDER_DETAILS } from "./constants";
import routes from "../../routes";
import getURLParams from "../../utils/getURLParams";

export const getOrderDetails = async (auth, orderNo) => {
  const response = await axios({
    method: "GET",
    url: `${GET_ORDER_DETAILS}?isPopulated=true&orderNo=${orderNo}`,
    headers: {
      Authorization: auth.authToken,
      clientId: "SA",
    },
  });

  return response.data.orders[0] ?? {};
};
export const getSingleGaraazOrderFromOrderId = async (auth, orderId) => {
  const response = await axios({
    method: "GET",
    url: `v1${routes.orderRoute}/${orderId}`,
    headers: {
      Authorization: auth.authToken,
    },
  });

  return response.data;
};

export const updateOrders = async (payload) => {
  const response = await axios({
    method: "PATCH",
    url: `/v1${routes.orderRoute}/${payload.orderId}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data,
  });

  return response.data ?? {};
};

export const transferSupplierBranch = async (payload) => {
  const response = await axios({
    method: "POST",
    url: `/v1${routes.orderRoute}/${payload.orderId}/transfer?branchId=${payload.branchId}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data,
  });

  return response.data ?? {};
};

export const updateSupplierInvoice = async (payload) => {
  const response = await axios({
    method: "PATCH",
    url: `/v1${routes.orderRoute}/${payload.orderId}/uploadSupplierInvoice`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data.invoiceInfo,
  });

  return response.data ?? {};
};
export const updateSupplierChallan = async (payload) => {
  const response = await axios({
    method: "PATCH",
    url: `/v1${routes.orderRoute}/${payload.orderId}/uploadSupplierChallan`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data.challanInfo,
  });

  return response.data ?? {};
};

export const registerSupplierAction = async (payload) => {
  const response = await axios({
    method: "POST",
    url: `/v1${routes.orderRoute}/${payload.orderId}/transition?action=${payload.action}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
  return response.data ?? {};
};

export const updateOrdersRemarks = async (payload) => {
  const response = await axios({
    method: "PATCH",
    url: `/v1${routes.orderRoute}/${payload.orderId}/supplierOrderRemarks`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data,
  });

  return response.data ?? {};
};

export const placeGaraazOrder = async (payload) => {
  const res = await axios({
    method: "POST",
    url: `/v1${routes.orderRoute}`,
    data: payload.data,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
  return res.data ?? {};
};

export const setOrderDetails = (payload) => ({
  type: SET_ORDER_DETAILS,
  payload,
});

export const completeOrder = async (payload) => {
  return await axios({
    method: "PATCH",
    url: `${routes.orderRoute}/${payload.orderId}/complete`,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
};

export const getQuantityAvailable = async (payload) => {
  const params = getURLParams(payload.params);

  return axios({
    method: "GET",
    url: `${routes.stockAvailabilityRoute}${params}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
};

export const createUnavailableOrder = async (payload) => {
  return axios({
    method: "POST",
    url: `${routes.orderRoute}/${payload.orderId}/unavailable-items/?locationId=${payload.locationId}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
    data: payload.data,
  });
};

export const getSinglePart = async (payload) => {
  const params = getURLParams(payload.params);

  return axios({
    method: "GET",
    /* url: `${routes.partRoute}${params}`, */
    url: `/part/get-product-by-code/${payload.params.partNumber}`,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
};

export const getInvoiceData = async (payload) => {
  return axios({
    method: "GET",
    url: `${routes.orderRoute}/${payload.orderId}/export?option=json`,
    headers: {
      Authorization: payload.auth.authToken,
    },
  });
};
