import routes from './routes';
import getURLParams from '../../utils/getURLParams';
import { publicAxios as axios } from '../../axios';



class Services {
    static fetchAllCollectionsAcct = (payload) => {
        const params = getURLParams(payload.params)
        return axios({
            method: 'GET',
            url: `${routes.collectionsRoute}${params}`,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }

    static fetchWorkshopCollections = (payload) => {
        const params = getURLParams(payload.params)
        return axios({
            method: 'GET',
            url: `${routes.workshopRoute}/${payload.id}/collections`,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }
}





export default Services