import { defineMessages } from 'react-intl';

export const scope = 'app.containers.BranchList';

export default defineMessages({
  branchList: {
    id: `${scope}.branchList`,
    defaultMessage: 'Branch List',
  },
  addBranch: {
    id: `${scope}.addBranch`,
    defaultMessage: '+ Add Supplier',
  },

  uploadBranches: {
    id: `${scope}.uploadBranches`,
    defaultMessage: '+ Upload Suppliers',
  },
  exportToCSV: {
    id: `${scope}.exportToCSV`,
    defaultMessage: 'Export to CSV',
  },

  indiviOpt: {
    id: `${scope}.indiviOpt`,
    defaultMessage: 'Get started by adding a branch',
  },
  indiviTitle: {
    id: `${scope}.indiviTitle`,
    defaultMessage: 'Add a branch',
  },
  indiviMsg: {
    id: `${scope}.indiviMsg`,
    defaultMessage: 'Mannualy add details of a branch',
  },
  bulkOpt: {
    id: `${scope}.bulkOpt`,
    defaultMessage: 'Bulk add multiple branches',
  },
  bulkTitle: {
    id: `${scope}.bulkTitle`,
    defaultMessage: 'Upload branches using Excel file',
  },
  bulkMsg: {
    id: `${scope}.bulkMsg`,
    defaultMessage: 'Add your branches in bulk using Excel file',
  },
});
