import { createSlice } from "@reduxjs/toolkit";
import Services from "./globalServices";

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState: {
    branches: [],
    workshops: { data: [], totalCount: { count: 0 }, pageCount: 0 },
    exportWorkshops: { data: [], totalCount: { count: 0 } },
    parts: { data: [], totalCount: { count: 0 } },
    workshopSearchResult: { data: [], totalCount: { count: 0 } },
    partSearchResult: { data: [], totalCount: { count: 0 } },
    employee: {},
    employeeList: [],
    cities: [],
    states: [],
    distributor: {
      banking: false,
      location: false,
      business: false,
      individual: false,
    },
    supplier: {},
    isDistributor: false,
    allNotifications: [],
    unreadNoticationCount: 0,
    totalNotificationCount: 0,
    toggleActionTaken: false,
  },
  reducers: {
    addBranches: (state, { payload }) => {
      state.branches = payload;
    },
    addParts: (state, { payload }) => {
      state.parts = payload;
    },

    addWorkshops: (state, { payload }) => {
      state.workshops = payload;
    },
    addExportedWorkshops: (state, { payload }) => {
      state.exportWorkshops = payload;
    },
    addWorkshopSearch: (state, { payload }) => {
      state.workshopSearchResult = payload;
    },
    addPartSearch: (state, { payload }) => {
      state.partSearchResult = payload;
    },
    addDistributor: (state, { payload }) => {
      state.distributor = payload;
    },
    addSupplier: (state, { payload }) => {
      state.supplier = payload;
    },
    setAllBranches: (state, { payload }) => {
      state.allAvailableBranches = payload;
    },
    setIsDistributor: (state, { payload }) => {
      state.isDistributor = payload;
    },
    setStateAndCities: (state, { payload }) => {
      state.cities = payload.cities;
      state.states = payload.states;
    },
    addEmployee: (state, { payload }) => {
      state.employee = payload;
    },
    addEmployeeList: (state, { payload }) => {
      state.employeeList = payload;
    },
    setAllNotifications: (state, { payload }) => {
      state.allNotifications = payload;
    },
    setUnreadNotificationCount: (state, { payload }) => {
      state.unreadNoticationCount = payload;
    },
    setTotalNotificationCount: (state, { payload }) => {
      state.totalNotificationCount = payload;
    },
    updateNotificationReadStatus: (state, { payload }) => {
      let selectedNote = {};
      state.allNotifications = state.allNotifications.map((note) => {
        // payload === [id-of-notification]
        if (note._id === payload[0]) {
          selectedNote = note;
          note = {
            ...note,
            read: true,
          };
        }
        return note;
      });

      if (state.unreadNoticationCount > 0 && !selectedNote.read) {
        state.unreadNoticationCount -= 1;
      }
    },
    addSingleNotification: (state, { payload }) => {
      state.allNotifications = [payload, ...state.allNotifications];
      state.unreadNoticationCount += 1;
    },
    markReadAllNotification: (state, action) => {
      state.allNotifications = state.allNotifications.map((item) => {
        item.read = true;
        return item;
      });
      state.unreadNoticationCount = 0;
    },
    updateActionTaken: (state, { payload }) => {
      state.toggleActionTaken = payload;
    },
  },
});

export const {
  addBranches,
  addWorkshops,
  addParts,
  addDistributor,
  addSupplier,
  setAllBranches,
  setIsDistributor,
  addWorkshopSearch,
  setStateAndCities,
  addEmployee,
  addEmployeeList,
  addPartSearch,
  addExportedWorkshops,
  setAllNotifications,
  setUnreadNotificationCount,
  updateNotificationReadStatus,
  addSingleNotification,
  setTotalNotificationCount,
  markReadAllNotification,
  updateActionTaken,
} = globalSlice.actions;

export const getBranches = (payload) => (dispatcher) => {
  return Services.getBranches(payload).then(
    (response) => {
      let data = [];
      if (Array.isArray(response?.data)) {
        data = [...response?.data].reverse();
      }
      dispatcher(addBranches(data));
      return Promise.resolve(data);
    },
    (error) => {
      if (error?.response) {
        return Promise.reject(error?.response?.data?.errMsg);
      }
    }
  );
};

export const getWorkshopSearch = (payload) => (dispatcher) => {
  return Services.getWorkshops(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data[0].data;
        responseObj.totalCount = response.data[0].totalCount;
      }

      dispatcher(addWorkshopSearch(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getCustomerSearch = (payload) => (dispatcher) => {
  return Services.getCustomers(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data;
        responseObj.totalCount = response.data.length;
      }

      dispatcher(addWorkshopSearch(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getPartSearch = (payload) => (dispatcher) => {
  return Services.getParts(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data[0].data;
        responseObj.totalCount = response.data[0].totalCount;
      }

      dispatcher(addPartSearch(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getInventoryPartSearch = (payload) => (dispatcher) => {
  return Services.getPartsFromInventory(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data[0].data;
        responseObj.totalCount = response.data[0].totalCount;
      }

      dispatcher(addPartSearch(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getWorkshopSearchByMobile = (payload) => (dispatcher) => {
  return Services.getWorkshopsMobile(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data[0].data;
        responseObj.totalCount = response.data[0].totalCount;
      }

      dispatcher(addWorkshopSearch(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getWorkshops = (payload) => (dispatcher) => {
  return Services.getWorkshops(payload)
    .then((response) => {
      let responseObj = { data: [], totalCount: { count: 0 }, pageCount: 0 };
      if (response && response.data && response.data.length > 0) {
        responseObj.data = response.data[0].data.reverse();
        responseObj.totalCount = response.data[0].totalCount;
        const limit = payload?.params?.limit ? payload.params.limit : 10;

        const pageCount = Math.ceil(response.data[0]?.totalCount.count / limit);

        responseObj.pageCount = pageCount;
      }

      dispatcher(addWorkshops(responseObj));
      return Promise.resolve(responseObj);
    })
    .catch((error) => {
      if (error && error.response) {
        return Promise.reject(error.response.data?.errorMsg);
      } else {
        return Promise.reject(error);
      }
    });
};

export const getWorkshopsForExport = (payload) => (dispatcher) => {
  return Services.getWorkshops(payload).then(
    (response) => {
      let responseObj = { data: [], totalCount: { count: 0 } };
      if (response.data.length > 0) {
        responseObj.data = response.data[0].data.reverse();
        responseObj.totalCount = response.data[0].totalCount;
      }
      dispatcher(addExportedWorkshops(responseObj));
      return Promise.resolve(responseObj);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getWorkshopLocation = (payload) => (dispatcher) => {
  return Services.getWorkshopLocation(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getEmployees = (payload) => (dispatcher) => {
  return Services.getEmployees(payload).then(
    (response) => {
      dispatcher(addEmployeeList(response.data));
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getDistributorInfo = (payload) => (dispatcher) => {
  return Services.getDistributorInfo(payload).then(
    (response) => {
      dispatcher(addDistributor(response.data[0]));
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getSupplierInfo = (payload) => (dispatcher) => {
  return Services.getSupplierInfo(payload).then(
    (response) => {
      dispatcher(addSupplier(response.data));
      dispatcher(setAllBranches(response.data?.branches));
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getCitiesAndStates = (payload) => (dispatcher) => {
  return Services.getCitiesAndStates(payload).then(
    (response) => {
      const states = Object.keys(response.data);
      const statesWithCities = Object.fromEntries(
        Object.entries(response.data).map(([key, value]) => {
          return [key.toUpperCase(), value];
        })
      );
      dispatcher(setStateAndCities({ states, cities: statesWithCities }));
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getAllNotifications = (payload) => (dispatcher) => {
  return Services.getAllNotifications(payload).then(
    (response) => {
      dispatcher(setAllNotifications(response.data));
      return Promise.resolve();
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getUnreadNotificationCount = (payload) => (dispatcher) => {
  return Services.getUnreadNotificationCount(payload).then(
    (response) => {
      dispatcher(setUnreadNotificationCount(response.data));
      return Promise.resolve();
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const readNotification = (payload) => (dispatcher) => {
  return Services.readNotification(payload).then(
    (response) => {
      dispatcher(updateNotificationReadStatus(payload.data));
      return Promise.resolve();
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const markNotificationAsRead = (payload) => (dispatcher) => {
  return Services.markNotificationAsRead(payload).then(
    (response) => {},
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const getTotalNotificationCount = (payload) => (dispatcher) => {
  return Services.getTotalNotificationCount(payload).then(
    (response) => {
      dispatcher(setTotalNotificationCount(response.data));
      return Promise.resolve();
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const markAllAsRead = (payload) => (dispatcher) => {
  return Services.markAllAsRead(payload).then(
    (response) => {
      dispatcher(markReadAllNotification());
      return Promise.resolve();
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    }
  );
};

export const selectGlobalState = (state) => state.globalStateReducer;

export default globalSlice.reducer;
