import { createSlice } from '@reduxjs/toolkit';
import Services from './services';

const collectionsAcctSlice = createSlice({
    name: 'collectionsAcctSlice',
    initialState: {
        collectionsAcctList: [],
        workshopCollection: [],
        workshopCollectionList: []
    },
    reducers: {
        addCollectionsAcct: (state, {payload}) => {
            state.collectionsAcctList = payload;
        },
        addWorkshopCollection: (state, {payload}) => {
            state.workshopCollection = payload;
        },
        addWorkshopCollectionList: (state, { payload }) => {
            state.workshopCollectionList = payload;
        }
    },
    
   
})

export const { addWorkshopCollectionList, addWorkshopCollection, addCollectionsAcct } = collectionsAcctSlice.actions;

export const fetchAllCollections = (payload) => dispatcher => {
    return Services.fetchAllCollectionsAcct(payload).then(
        response => {
            const data = Object.values(response.data)?.map((el,index )=> {
                return {...el, code: Object.keys(response.data)[index]}
            })

            dispatcher(addCollectionsAcct(data));
            return Promise.resolve(data);
        },
        error => {
            if(error.response){
                return Promise.reject(error.response.data); 
            }
        }
    )
}

export const getWorkshopCollections = (payload) => dispatcher => {
    return Services.fetchWorkshopCollections(payload).then(
        response => {
            dispatcher(addWorkshopCollection([response.data.workshopCollections]));
            dispatcher(addWorkshopCollectionList(response.data.accounts));
            return Promise.resolve(response.data);

        }
    )
}

export const selectCollectionsAcct = state => state.collectionsAcct;

export default collectionsAcctSlice.reducer;