// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { publicAxios as axios } from '../axios';
import routes from '../routes';
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDRgruOPPv737Wtq2X1XAwa0agqiW3COwY',
  authDomain: 'garaaz-ada9d.firebaseapp.com',
  projectId: 'garaaz-ada9d',
  storageBucket: 'garaaz-ada9d.appspot.com',
  messagingSenderId: '1014073380154',
  appId: '1:1014073380154:web:1dd56bbc5f6e2aaa6ad403',
  measurementId: 'G-HKT1P4G79C',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fMessaging = getMessaging(app);

function requestPermission() {
  // console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  });
}
/*  */
export const subscribeToNotification = async (params, notification) => {
  try {
    const deviceToken = await getToken(fMessaging, {
      vapidKey: 'BGoI2nQCw1lhAfnaz5qfUfka6qGUpUBqx7wr2ai2Bx0yknzGpDdMWJd8NlP7m7YZ5RXfVaJ2gmQonhLg8lVzO1Q',
    });
    requestPermission();
    if (deviceToken) {
      await axios({
        method: 'PATCH',
        url: `${routes.getDeviceToken(params.id)}`,
        data: {
          deviceToken
        },
        headers: {
          Authorization: params.auth.authToken,
        },
      });

    }
  } catch (error) {
    if (error.response) {
      notification({ status: 'error', description: `${error.response.data}` })
    }

  }
};


export const onMessageListener = (callback) =>
  new Promise((resolve) => {
    onMessage(fMessaging, (payload) => {
      callback(payload?.data);
      resolve(payload);

    });
  });
export default subscribeToNotification;
