import produce from 'immer';

import { SET__BRANCHES } from './constants';

export const initialState = {
  branches: [],
};

/* eslint-disable default-case, no-param-reassign */
const branchListReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET__BRANCHES:
        draft.branches = action.payload;
        break;
    }
  });

export default branchListReducer;
