import moment from "moment";

export const checkIsBefore = (date1, date2) => {
  return moment(date1).isBefore(date2);
};

export const checkIsSame = (date1, date2) => {
  return moment(date1).isSame(date2);
};

export const addToDate = (date, days) => {
  return moment(date, "YYYY-MM-DD").add(days, "days").format("YYYY-MM-DD");
};

export const dateFormatForApi = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export function subtractFromDate(date, days) {
  return moment(date, "YYYY-MM-DD").subtract(days, "days").format("YYYY-MM-DD");
}

export const dateFormatForExport = (date) =>
  moment(date).format("DD/MM/YYYY hh:mm A");

export const formatDateReverse = (date) => {
  return date.split("/").reverse().join("-");
};

export const formatDateInvoiceApi = (date) => {
  return date.split("-").reverse().join("/");
};
