import React from 'react'
import { Text } from '@chakra-ui/react'
import moment from 'moment'

const DateTimeOrder = ({ dateandtime }) => {
    return (
        <>
            <Text>
                {moment(dateandtime).format("DD MMM YYYY")}
            </Text>
            <Text fontSize='xs'>
                ({moment(dateandtime).format("hh:mm A")})
            </Text>
        </>
    )
}

export default DateTimeOrder