import messages from './messages'
// import individualIllustrator from '../../assets/img/employeeIllustrator.svg'
import individualIllustrator from '../../assets/img/individualIllustrator.svg'
import bulkIllustrator from '../../assets/img/bulkAddIllustrator.svg'
export const optionsProp = {
  indivi: {
    header: messages.indiviOpt,
    title: messages.indiviTitle,
    msg: messages.indiviMsg,
    illustrators: individualIllustrator,
    link: '/workshop/add'
  }, bulk: {
    header: messages.bulkOpt,
    title: messages.bulkTitle,
    msg: messages.bulkMsg,
    illustrators: bulkIllustrator,
    link: '/workshop/add/bulk'
  }
}

export const UPDATE_TABLEDATA = 'UPDATE_TABLEDATA';