import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { Stepper, Step } from 'react-form-stepper';
import XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import messages from './messages';
import { publicAxios as axios } from '../../axios';
import { sheetValidation } from './helper';
import { useHistory } from 'react-router';
import { UseOnboardContext } from '../../App';
import garaazToast from '../../HOCs/garaazToast';
import localforage from 'localforage';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const requiredFields = [
  'Mobile Number',
  'First Name',
  'Last Name',
  'Designation',
  'Branch Code',
];

const EmployeeBulk = ({ notification }) => {
  const { updateOnboard } = UseOnboardContext();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [sheetData, setSheetData] = useState([]);
  const [savedHeaders, setSavedHeaders] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [
    actualEmployeeOnboardingHeaders,
    setActualEmployeeOnboardingHeaders,
  ] = useState([]);

  const [mappedSheetDataValid, setMappedSheetDataValid] = useState([]);
  const [mappedSheetDataInvalid, setMappedSheetDataInvalid] = useState([]);

  const acceptedEmployeeOnboardingHeaders = useSelector(
    (state) => state.app.acceptedEmployeeOnboardingHeaders,
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  const intl = useIntl();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    inputRef,
  } = useDropzone({
    accept:
      'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    maxFiles: 1,
    noKeyboard: true,
  });

  useEffect(() => {
    (async () => {
      const res = await localforage.getItem('employeeFields');
      setSavedHeaders(res);
    })();
  }, []);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const jsonData = XLSX.utils.sheet_to_json(ws, { defval: '' });
        if (!jsonData.length) {
          setErrorMessage('Either sheets is empty or the sheet is corrupted.');
        } else {
          setErrorMessage('');
          setActualEmployeeOnboardingHeaders(Object.keys(jsonData[0]));
          setSheetData(jsonData);
        }
      };
      reader.readAsBinaryString(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (errorMessage) {
      toast(errorMessage);
    }
  }, [errorMessage]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );
  const onModalClose = useCallback((e) => {
    setActiveStep(1);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setSheetData([]);
  });

  const callback = async (data) => {
    if (activeStep < 3) setActiveStep(activeStep + 1);
    if (activeStep === 2) {
      if (!savedHeaders) {
        await localforage.setItem('employeeFields', data);
        const newlySavedFields = await localforage.getItem('employeeFields');
        setSavedHeaders(newlySavedFields);
      }
      const mappedSheetDataTemp = await sheetValidation(
        sheetData,
        acceptedEmployeeOnboardingHeaders,
        data,
        auth,
        requiredFields,
      );
      setMappedSheetDataValid(
        mappedSheetDataTemp.filter((row) => !row.isInvalid),
      );
      setMappedSheetDataInvalid(
        mappedSheetDataTemp.filter((row) => row.isInvalid),
      );
    } else if (activeStep === 3) {
      const validLength = mappedSheetDataValid.length;
      const totalLength = sheetData.length;

      if (mappedSheetDataInvalid.length > 0) {
        notification({
          status: 'error',
          title: 'Invalid Fields',
          description:
            'Please remove row with invalid fields or provide correct values',
        });
        return;
      }

      try {
        await axios.post(
          `/employees`,
          {
            employees: mappedSheetDataValid,
          },
          {
            headers: {
              Authorization: auth.authToken,
            },
          },
        );
        toast('Employees onboarded successfully');
        if (localStorage.onboard) {
          history.push('/workshops');
          localStorage.onboard = 4;
          updateOnboard(4);
        } else {
          history.push('/employees');
        }
      } catch (err) {
        // toast('Something went wrong!');
        // toast(err.response.data.message[0]);

        if (err.response) {
          toast(err.response.data.errorMsg);
        } else {

          toast('Something went wrong!');
        }
      }
      toast(`Attempting to onboard ${validLength}/${totalLength} Employees.`);
      reset(
        {},
        {
          keepDirty: false,
          keepErrors: false,
          keepValues: false,
        },
      );
    }
  };

  const mapOnboardingFields = useCallback(callback, [
    activeStep,
    setActiveStep,
    mappedSheetDataValid,
    mappedSheetDataInvalid,
  ]);


  const handleHeadersChange = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      if (savedHeaders) {
        const newSavedHeaders = { ...savedHeaders, [name]: value };
        await localforage.setItem('employeeFields', newSavedHeaders);
        setSavedHeaders(newSavedHeaders);
      }
    } catch (error) {

    }
  };

  return (
    <>
      <div className='w-full mb-3'>
        <Stepper
          styleConfig={{
            completedBgColor: '#1e293b',
            activeBgColor: '#da0032',
          }}
          activeStep={activeStep}
        >
          <Step label='File Upload' />
          <Step label='Field Mappings' />
          <Step label='Review' />
        </Stepper>
      </div>
      {activeStep === 1 && (
        <div className='w-full px-4 lg:w-full mb-3'>
          <Box w='100%' mb='3'>
            <Alert status='info'>
              <AlertIcon />
              <AlertDescription>
                <label>Download a sample file from here.. </label>
                <a
                  style={{ fontWeight: 'bold' }}
                  /* 1Ii55abGtxvlZeuEpJ70caygHQSO_1_mk */
                  /* 1Jt2etA2O75swOpyNKuqRTarhMUvouogP */
                  href={
                    'https://docs.google.com/uc?id=1Jt2etA2O75swOpyNKuqRTarhMUvouogP&export=download'
                  }
                  download='Branch sample File'
                >
                  Download File
                </a>
              </AlertDescription>
            </Alert>
          </Box>
          <label className='block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1'>
            {intl.formatMessage(messages.employeeDetailDocument)}
          </label>
          <div
            className='relative w-full'
            {...getRootProps({ style, className: 'dropzone' })}
          >
            <input {...getInputProps()} />
            {isDragActive && (
              <p>{intl.formatMessage(messages.dropFilesHere)}</p>
            )}
            {!acceptedFiles ||
              (acceptedFiles.length === 0 && !isDragActive && (
                <p>{intl.formatMessage(messages.dragAndDropMessage)}</p>
              ))}
            {acceptedFiles && acceptedFiles.length > 0 && !isDragActive && (
              <>
                <p>
                  <i className='far fa-file fa-2x' />
                </p>
                <p>{acceptedFiles[0].path}</p>
              </>
            )}
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <form
          id='row-mapping-form'
          onSubmit={handleSubmit(mapOnboardingFields)}
        >
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            {acceptedEmployeeOnboardingHeaders.map((header) => (
              <Box key={`${header}div`} width='48%' mb={5}>
                <div className='relative w-full mb-3'>
                  <label className='block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1'>
                    {header}
                  </label>
                  <Select
                    {...register(`${header}`, {
                      required: requiredFields.includes(header),
                    })}

                    defaultValue={
                      savedHeaders && savedHeaders[header] ? savedHeaders[header] : actualEmployeeOnboardingHeaders.find(
                        (actualHeader) =>
                          `${actualHeader}`
                            .toLowerCase()
                            .indexOf(`${header}`.toLowerCase()) !== -1,
                      ) ?? '_empty'
                    }
                    onChange={handleHeadersChange}
                  >
                    {[...actualEmployeeOnboardingHeaders, '__Empty'].map(
                      (actualHeader) => (
                        <option key={actualHeader}>{actualHeader}</option>
                      ),
                    )}
                  </Select>
                  {errors && errors[header] && (
                    <span className='text-youtube-regular text-sm'>
                      {header}
                    </span>
                  )}
                </div>
              </Box>
            ))}
          </Box>
        </form>
      )}
      {activeStep === 3 && (
        <>
          <Box w='100%' p={4}>
            <Alert status='info'>
              <AlertIcon />
              <AlertTitle mr={2}>Valid Rows</AlertTitle>
              <AlertDescription>
                There are total {mappedSheetDataValid.length}/{sheetData.length}{' '}
                rows which are valid
              </AlertDescription>
            </Alert>
          </Box>

          {mappedSheetDataInvalid.length > 0 && (
            <Box w='100%' p={4}>
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle mr={2}>Invalid Rows</AlertTitle>
                <AlertDescription>
                  There are total {mappedSheetDataInvalid.length}/
                  {sheetData.length} rows which are invalid
                </AlertDescription>
              </Alert>
            </Box>
          )}
          {mappedSheetDataInvalid.length > 0 && (
            <Box w='100%' p={4} style={{ overflowX: 'auto' }}>
              <table
                variant='striped'
                size='lg'
                className='table-auto border-collapse border border-green-800'
              >
                <thead style={{ backgroundColor: '#1e293b', color: '#ffffff' }}>
                  <tr>
                    {Object.keys(mappedSheetDataInvalid[0]).map(
                      (header, index) => {
                        if (header === 'isInvalid' || header === 'branchId') {
                        } else {
                          return (
                            <th
                              key={index}
                              className='px-2 text-center'
                              style={{ width: '150px', minWidth: '150px' }}
                            >
                              {header.toUpperCase()}
                            </th>
                          );
                        }
                      },
                    )}
                  </tr>
                </thead>
                <tbody>
                  {mappedSheetDataInvalid.map((row, index) => (
                    <tr key={`${row.mobileNumber}${row.firstName}${index}`}>
                      {Object.keys(row)
                        .filter(
                          (header) =>
                            header !== 'isInvalid' && header !== 'branchId',
                        )
                        .map((header, index) => {
                          return (
                            <td
                              key={index}
                              className='px-2 text-center'
                              style={{
                                width: '110px',
                                minWidth: '110px',
                                color: `${row[header]}`?.startsWith('_')
                                  ? 'red'
                                  : 'black',
                              }}
                            >
                              {`${row[header]}`?.startsWith('_')
                                ? row[header].slice(1)
                                : row[header]}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          )}
        </>
      )}
      <div className='bulkButtonsContainer'>
        <Button
          variant='clrBtn'
          onClick={() => {
            setMappedSheetDataValid([]);
            setMappedSheetDataInvalid([]);
            reset(
              {},
              {
                keepDirty: false,
                keepErrors: false,
                keepValues: false,
              },
            );
          }}
        >
          Clear
        </Button>
        <Button
          onClick={handleSubmit(mapOnboardingFields)}
          style={{
            backgroundColor: '#1e293b',
            color: '#ffffff',
            marginLeft: '20px',
          }}
          mr={3}
          disabled={sheetData.length < 1}
        >
          Next
        </Button>
      </div>
    </>
  );
};

EmployeeBulk.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default garaazToast(EmployeeBulk);
