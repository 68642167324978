import { mode, darken, whiten } from "@chakra-ui/theme-tools";

export const ButtonStyle = {
  size: {
    sm: {
      padding: 4,
    },
    md: {
      padding: { base: 12, lg: 6 },
    },
  },
  variants: {
    normal: {
      bg: "#1e293b",
      color: "#fff",
      _hover: {
        bg: "teal",
      },
    },

    save: {
      bg: "#1e293b",
      color: "#ffffff",
      marginRight: "20px",
    },
    cancle: {
      bg: "red.500",
      color: "#ffffff",
      _hover: {
        bg: "red.600",
      },
    },
    addition: {
      bg: "#1e293b",
      color: "#ffffff",
    },
    retry: (props) => ({
      bg: "#b3d4fc",
      marginTop: "10px",
      width: "90px",
      color: "black",
      alignSelf: "center",
      _hover: {
        bg: whiten("save", 2)(props),
        boxShadow: "md",
      },
    }),
    getOtp: {
      bg: "#1e293b",
      color: "white",
    },
    signIn: {
      bg: "#1e293b",
      color: "white",
    },
    resendOtp: {
      outLine: "none",
      color: "#3b5999",
    },
    hamburger: {},
    bulkAdd: {
      bg: "#1e293b",
      color: "#ffff",
      marginLeft: "5px",
      marginBottom: "10px",
    },
    clrBtn: {
      bg: "#edf2f7",
      margin: "10px",
      color: "#000",
    },
  },
  defaultProps: {
    size: "md",
  },
};
