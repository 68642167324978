import uuid from "react-uuid";
import { MdAccountCircle } from "react-icons/md";
import { FaBuffer, FaLanguage } from "react-icons/fa";
import { RiCaravanLine } from "react-icons/ri";
import { MdListAlt } from "react-icons/md";
import { RiEnglishInput } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { FaStore } from "react-icons/fa";
import { RiStore2Line } from "react-icons/ri";

import messages from "./messages";
import enums from "../../utils/enums";
import parts from "../../images/parts.png";

export const distributorNavObj = (isOpen) => [
  {
    to: "/employees",
    icon: IoIosPeople,
    message: messages.employee,
    name1: enums.EMPLOYEES,
  },
  {
    to: "/parts",
    icon: RiCaravanLine,
    message: messages.parts,
    name1: enums.PARTS,
  },
  {
    to: "/workshops",
    icon: RiStore2Line,
    message: messages.workshops,
    name1: enums.WORKSHOPS,
  },

  {
    to: "/inventory",
    icon: MdListAlt,
    message: messages.inventory,
    name1: enums.INVENTORY,
  },
];

export const employeeNavObj = (isOpen) => [
  {
    to: "/parts",
    icon: RiCaravanLine,
    message: messages.parts,
    name1: enums.PARTS,
  },
  {
    to: "/workshops",
    icon: RiStore2Line,
    message: messages.workshops,
    name1: enums.WORKSHOPS,
  },

  {
    to: "/inventory",
    icon: MdListAlt,
    message: messages.inventory,
    name1: enums.INVENTORY,
  },
];

export const incompleteNavObj = (isOpen) => [
  {
    to: "/employees",
    icon: IoIosPeople,
    message: messages.employee,
    name1: enums.EMPLOYEES,
  },
  {
    to: "/workshops",
    icon: RiStore2Line,
    message: messages.workshops,
    name1: enums.WORKSHOPS,
  },
];

export const lang = (isOpen, storeLocale, handleLanguageChange) => ({
  mainNav: storeLocale === enums.ENG ? messages.eng : messages.hindi,
  icon: <FaLanguage className={` ${isOpen ? "mr-2" : "mr-0"} text-sm`} />,
  subNavs: [
    {
      name: messages.eng,
      key: uuid(),
      isActive: storeLocale === enums.ENG,
      icon: (
        <RiEnglishInput className={` ${isOpen ? "mr-2" : "mr-0"} text-sm`} />
      ),
      onClick: () => handleLanguageChange(enums.ENG),
    },
    {
      name: messages.hindi,
      key: uuid(),
      isActive: storeLocale === enums.HIN,
      onClick: () => handleLanguageChange(enums.HIN),
      icon: (
        <RiEnglishInput className={` ${isOpen ? "mr-2" : "mr-0"} text-sm`} />
      ),
    },
  ],
});

export const distributorNavObj_small = [
  {
    to: "/orders",
    icon: <FaBuffer className={`mr-2 text-sm`} />,
    message: messages.orders,
    name1: enums.ORDERS,
  },
  {
    to: "/profile",
    icon: <MdAccountCircle className="mr-2 text-sm" />,
    message: messages.profile,
  },
  { to: "/employees", icon: "fas fa-users", message: messages.employee },
  {
    to: "/parts",
    icon: (
      <img
        src={parts}
        alt="parts"
        className="max-w-full rounded "
        width={20}
        height={20}
        style={{ alignSelf: "center", marginRight: "4px" }}
      />
    ),
    message: messages.parts,
  },
  {
    to: "/workshops",
    icon: "fas fa-store",
    message: messages.workshops,
  },

  {
    to: "/inventory",
    icon: <MdListAlt className={`mr-2 text-sm`} />,
    message: messages.inventory,
    name1: enums.INVENTORY,
  },
];

export const incompleteNavObj_small = [
  {
    to: "/orders",
    icon: <FaBuffer className={`mr-2 text-sm`} />,
    message: messages.orders,
    name1: enums.ORDERS,
  },
  {
    to: "/profile",
    icon: <MdAccountCircle className="mr-2 text-sm" />,
    message: messages.profile,
  },
  { to: "/employees", icon: "fas fa-users", message: messages.employee },
  {
    to: "/workshops",
    icon: "fas fa-store",
    message: messages.workshops,
  },
];

export const employeeNavObj_small = [
  {
    to: "/parts",
    icon: <RiCaravanLine className="mr-2 text-sm" />,
    message: messages.parts,
  },
  {
    to: "/workshops",
    icon: "fas fa-store",
    message: messages.workshops,
  },

  {
    to: "/inventory",
    icon: <MdListAlt className={`mr-2 text-sm`} />,
    message: messages.inventory,
    name1: enums.INVENTORY,
  },
];

export const lang_small = (storeLocale, handleLanguageChange) => ({
  mainNav: storeLocale === enums.ENG ? messages.eng : messages.hindi,
  icon: <FaLanguage className={`mr-2  text-sm`} />,
  subNavs: [
    {
      name: messages.eng,
      key: uuid(),
      isActive: storeLocale === enums.ENG,
      icon: <RiEnglishInput className={`mr-2  text-sm`} />,
      onClick: () => handleLanguageChange("en"),
    },
    {
      name: messages.hindi,
      key: uuid(),
      isActive: storeLocale === enums.HIN,
      onClick: () => handleLanguageChange("hi"),
      icon: <RiEnglishInput className={`mr-2  text-sm`} />,
    },
  ],
});
