import { createSlice } from '@reduxjs/toolkit';
import Services from './services';
import moment from 'moment';
import enums from '../../utils/enums';

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState: {
    stats: {},
    inputValues: {
      end: moment()
      .add(1, 'days')
      .format(enums.DATE_FORMAT),
      start: moment()
        .startOf('month')
        .format(enums.DATE_FORMAT),
    },
  },
  reducers: {
    addStats: (state, { payload }) => {
      state.stats = { ...state.stats, ...payload };
    },
    setInputValue: (state, { payload }) => {

      state.inputValues = { ...state.inputValues, ...payload };
    },
  },
});

export const { addStats, setInputValue } = dashboardSlice.actions;

export const getInventoryStat = (payload) => (dispatcher) => {
  return Services.getInventoryStat(payload).then(
    (response) => {
      dispatcher(addStats(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    },
  );
};

export const getSalesStat = (payload) => (dispatcher) => {
  return Services.getSalesStat(payload).then(
    (response) => {
      dispatcher(addStats(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    },
  );
};

export const getCollectionStat = (payload) => (dispatcher) => {
  return Services.getCollectionsStat(payload).then(
    (response) => {
      dispatcher(addStats(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    },
  );
};

export const getOutstandingStat = (payload) => (dispatcher) => {
  return Services.getOutstandingStat(payload).then(
    (response) => {
      dispatcher(addStats(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    },
  );
};

export const getOrderStat = (payload) => (dispatcher) => {
  return Services.getOrderStat(payload).then(
    (response) => {
      dispatcher(addStats(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data?.errMsg);
      }
    },
  );
};

export const selectDashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;
