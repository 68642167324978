import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import LanguageProvider from './containers/LanguageProvider';
import store from './configureStore';
import { VisitProvider } from './contexts/VisitContext';
import { translationMessages } from '../src/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { CustomizeTheme } from './utils/theme';
import 'react-alice-carousel/lib/alice-carousel.css';
import ErrorBoundary from './components/ErrorBoundary';
import { registerServiceWorker } from './serviceWorker'


const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <ChakraProvider theme={CustomizeTheme}>
        <LanguageProvider messages={messages}>
          <VisitProvider>
            <Router>
              <ErrorBoundary>
              <App />
              </ErrorBoundary>
            </Router>
          </VisitProvider>
        </LanguageProvider>
      </ChakraProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

//registerServiceWorker()
/* serviceWorker.register() */

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en-IN.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

/* if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
} */
