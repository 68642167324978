import { defineMessages } from "react-intl";

export const scope = "app.containers.Sidebar";

export default defineMessages({
  garaazAdmin: {
    id: `${scope}.garaazAdmin`,
    defaultMessage: "Garaaz Distributor",
  },
  changeLang: {
    id: `${scope}.changeLang`,
    defaultMessage: "Change Lang",
  },
  distributor: {
    id: `${scope}.distributor`,
    defaultMessage: "Distributor",
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: "Profile",
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  branch: {
    id: `${scope}.branch`,
    defaultMessage: "Branches",
  },

  employee: {
    id: `${scope}.employee`,
    defaultMessage: "Employees",
  },
  part: {
    id: `${scope}.part`,
    defaultMessage: "Part",
  },
  parts: {
    id: `${scope}.parts`,
    defaultMessage: "Parts",
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: "Orders",
  },
  ordersNew: {
    id: `${scope}.orderNew`,
    defaultMessage: "Orders",
  },
  workshops: {
    id: `${scope}.workshops`,
    defaultMessage: "Workshops",
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: "Logout",
  },
  hindi: {
    id: `${scope}.hindi`,
    defaultMessage: "Hindi",
  },
  eng: {
    id: `${scope}.end`,
    defaultMessage: "English",
  },
  accounting: {
    id: `${scope}.accounting`,
    defaultMessage: "Account",
  },
  outstanding: {
    id: `${scope}.outstanding`,
    defaultMessage: "Outstandings",
  },
  collections: {
    id: `${scope}.collections`,
    defaultMessage: "Collections",
  },
  inventory: {
    id: `${scope}.inventory`,
    defaultMessage: "Inventory",
  },

  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: "suppliers",
  },
});
