import { publicAxios as axios } from '../../axios';
import routes, { ROUTES } from '../../routes';
import getURLParams from '../../utils/getURLParams';


/* /part?limit=${limit}&offset=${offset}&sort=updatedAt,asc */
export const getPartList = (payload) => {
    const params = getURLParams(payload.params);
    return axios({
        method: 'GET',
        url: `${routes.partRoute}${params}&sort=updatedAt,asc`,
        headers: {
            Authorization: payload.auth.authToken,
        }
    })
}