import messages from './messages'
import individualIllustrator from '../../assets/img/individualIllustrator.svg'
import bulkIllustrator from '../../assets/img/bulkAddIllustrator.svg'
import { ROUTES } from '../../routes'
export const SET__PARTS = 'SET__PARTS';

export const optionsProp = {
  indivi: {
    header: messages.indiviOpt,
    title: messages.indiviTitle,
    msg: messages.indiviMsg,
    illustrators: individualIllustrator,
    link: ROUTES.PARTS_ADD,
  }, bulk: {
    header: messages.bulkOpt,
    title: messages.bulkTitle,
    msg: messages.bulkMsg,
    illustrators: bulkIllustrator,
    link: ROUTES.PART_ADD_BULK
  }
}

