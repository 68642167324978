import { defineMessages } from 'react-intl';

export const scope = 'app.containers.table';

export default defineMessages({
    rowPerPage: {
        id: `${scope}.rowPerPage`,
        defaultMessage: 'Rows per page',
      },
      goToPage: {
        id: `${scope}.goToPage`,
        defaultMessage: 'Go to page',
      },
      showing : {
        id: `${scope}.showing`,
        defaultMessage: 'Showing Results',
      },
      to : {
        id: `${scope}.to`,
        defaultMessage: 'to',
      },
      to : {
        id: `${scope}.to`,
        defaultMessage: 'to',
      },
      of : {
        id: `${scope}.of`,
        defaultMessage: 'of',
      },
      page : {
        id: `${scope}.page`,
        defaultMessage: 'Page',
      }
})