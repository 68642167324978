import { publicAxios as axios } from '../../axios';
import routes from './routes';
import ROUTES from '../../routes';
import getURLParams from '../../utils/getURLParams';


class Services {
    static getAllStock(payload){
        const params = getURLParams(payload.params)
        return  axios({
            method: 'GET',
            url: `${routes.inventoryRoute}${params}`,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }

    static getAllStockByBranch(payload){
        const params = getURLParams(payload.params)
        return  axios({
            method: 'GET',
            url: `${routes.inventoryByBranchRoute(payload.branchId)}${params}`,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }

    static uploadStock(payload){
        return  axios({
            method: 'POST',
            url: `${routes.inventoryRoute}`,
            data: payload.data,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }

    static getPendingParts(payload){
        const params = getURLParams(payload.params)
        return  axios({
            method: 'GET',
            url: `${ROUTES.partRoute}/pending-parts${params}`,
            headers: {
                Authorization: payload.auth.authToken,
            }
        })
    }

    static addToQueue = (payload) => {
        return axios({
            method: 'POST',
            url: `${ROUTES.uploadQueueEndpoint}`,
            headers: {
                Authorization: payload.auth.authToken,
            },
            data: payload.data,
      
        })
    }

    static uploadInventoryMedia = (payload) => {
        return axios({
            method: 'POST',
            url: `/media`,
            headers: {
                Authorization: payload.auth.authToken,
            },
            data: payload.data,
        })
    }

    static getFileQueue = (payload) => {
    
        return axios({
            method: 'GET',
            url: `${ROUTES.uploadQueueEndpoint}?module=INVENTORY`,
            headers: {
                Authorization: payload.auth.authToken,
            },
    
        })
    }

 
}



export default Services;