import React from 'react'
import { Heading, Button, Image, Flex, Text } from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
// import serverError from '../../assets/img/serverError.svg'
const Error = ({ title, message, onRetry, illustrator }) => {
	return (
		<Flex direction="column" width="100%" justifyContent="center">
			<Image alignSelf="center" m={0} src={illustrator} width={600}></Image>
			<Heading alignSelf="center" mt={10} md={10}>{title}</Heading>
			<Text alignSelf="center" textAlign="center" fontSize="lg">{message}</Text>
			{/* <Button style={{ marginTop: "10px" }} onClick={onRetry} alignSelf="center" bg="#b3d4fc" width="90px" color="black" colorScheme="navy" variant="solid"> */}
			<Button onClick={onRetry} variant="retry" >
				<RepeatIcon mr={2} />
				Retry
			</Button>
		</Flex >
	)
}
export default Error;