import _, { isUndefined, toUpper } from 'lodash';
import { publicAxios as axios } from '../../axios';
import { getEmployeeDesignations } from '../EmployeeList/actions';

export const sheetValidation = async (
  sheetData,
  acceptedEmployeeOnboardingHeaders,
  data,
  auth,
  requiredFields,
) => {
  let invalidCount = 0;
  const branches = await getBranches(auth);
  const designation = Object.values((await getEmployeeDesignations(auth)).data);


  return sheetData.map((row) => {
    const mappedRow = {};
    acceptedEmployeeOnboardingHeaders.forEach((header) => {
      if (header === 'Mobile Number') {
        if (
          `${row[data[header]]}`.length === 10 &&
          !isNaN(Number(row[data[header]]))
        ) {
          mappedRow[_.camelCase(header)] = `91${row[data[header]]}`;
        } else {
          invalidCount++;
          mappedRow.mobileNumber = `_${row[data[header]]}`;
        
        }
      } else if (header === 'Branch Code') {
        const inputBranchCode = row[data[header]];

        if (
          branches
            .map((e) => toUpper(e.branchCode).trim())
            .includes(toUpper(inputBranchCode).trim())
        ) {
          mappedRow.branchCode = toUpper(inputBranchCode);
          mappedRow.branchId = branches.find(
            (e) =>
              toUpper(e.branchCode).trim() ===
              toUpper(inputBranchCode).trim(),
          )._id;
        }

        if (isUndefined(mappedRow.branchId)) {
          invalidCount++;
          mappedRow.branchName = `_${row[data[header]]}`;
          mappedRow.branchId = 'invalid';
   
        }
      } else if (
        header === 'Designation' &&
        !designation
          .map((des) => des.toLowerCase())
          .includes(`${row[data[header]]}`.toLowerCase())
      ) {
        invalidCount++;
        mappedRow.designation = `_${row[data[header]]}`;

      } else {
        if (
          requiredFields.includes(header) &&
          (!row[data[header]] || row[data[header]] === '' || row[data[header]] === '__Empty')
        ) {
          invalidCount++;
          mappedRow[_.camelCase(header)] = `_${row[data[header]]}`;

        } else if (row[data[header]]) {
          mappedRow[_.camelCase(header)] = header === 'Designation' ? row[data[header]].toUpperCase() : row[data[header]];
        }
   
      }
    });
    mappedRow.isInvalid = invalidCount > 0 ? true : false;
    return mappedRow;
  });
};

export const getBranches = async (auth) => {
  try {
    const { data } = await axios.get('/branches', {
      headers: {
        Authorization: auth.authToken,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};
