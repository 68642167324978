import { extendTheme } from "@chakra-ui/react"
import { TabList } from './components/TabList'
import { ButtonStyle as Button } from "./components/button";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import Divider from './components/Divider';
import Modal from './components/Modal'


const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
})

export const CustomizeTheme = extendTheme({
  breakpoints,
  colors: {
    primary: "#1e293b",
    secondary: "red",
  },
  components: {
    TabList,
    Button,
    Divider,
    Modal
  },
  textStyles: {
      small: {
        fontSize: {base: '10px', lg: '1vw'},
      },
      label: {
        fontSize: {base: '11px', lg: '1vw'},
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      labelLight: {
        fontSize: {base: '11px', lg: '1vw'},
        textTransform: 'uppercase'
      },
      normal: {
        fontSize: {base: '16px', lg: '1.2vw'},
      },
      normalBold: {
        fontSize: {base: '16px', lg: '1.2vw'},
        fontWeight: 'bold',
      },
      medium: {
        fontSize: {base: '20px', lg: '1.3vw'},
        fontWeight: 'bold',
      },
      
      subtitle: {
        fontSize: {base: '24px', lg: '1.25vw'},
        fontWeight: 'bold',
        textTransform: 'capitalize'
      },

  },
  layerStyles: (props) => ({
    muted: {
      color: props.colorMode === 'dark' ? 'white' : '#64748b',
    },
    dark: {
      color: props.colorMode === 'dark'? 'white': 'black',
    },
    background: {
      backgroundColor: props.colorMode === "dark" ? "gray.700" : "white"
    },
    base: {
      bg: "gray.50",
      border: "2px solid",
      borderColor: "gray.500",
    }
  }),
 
  styles: {
    global: (props) => ({
      "html, body": {
        fontSize: ["sm", "md"],
        color: props.colorMode === "dark" ? "#64748b" : "gray.600",
        lineHeight: "tall",
        fontFamily:  `'Lato', sans-serif`
      },

    })
  }
})