import store from './configureStore';
import { toggleSpinner } from './actions';
const axios = require('axios');
export let publicAxios; // eslint-disable-line
export let authAxios; // eslint-disable-line

export const setupPublicAxios = baseUrl => {
  publicAxios = axios.create({
    baseURL: baseUrl,
    responseType: 'application/json',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    }
  });
};

export const setupAuthAxios = (baseUrl, authToken) => {
  authAxios = axios.create({
    baseURL: baseUrl,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
  });

  authAxios.interceptors.request.use(req => {
    store.dispatch(toggleSpinner);
    return req;
  });

  authAxios.interceptors.response.use(
    res => {
      store.dispatch(toggleSpinner);

      return res;
    },
    err => { }, // eslint-disable-line
  );
};

export default authAxios;
