import { createSlice } from '@reduxjs/toolkit';
import {
  createBulkSales,
  fetchAllSales,
  fetchWorkshopAccounts,
  getFileQueue,
  getPendingWorkshops,
} from './service';
import _ from 'lodash';
import moment from 'moment';

const salesSlice = createSlice({
  name: 'salesSlice',
  initialState: {
    allSales: [],
    allSalesCount:0,
    allSalesPageCount: 0,
    workshopAccount: [],
    workshopAccountList: [],
    queuedFile: [],
    pendingWorkshops: [],
    allSalesParams: {
      offset: 0,
      limit: 10,
    },
    initalPageIndex: 0,
    initialPageSize: 10,
    inputValues: {
      start: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    }
  },
  reducers: {
    addAllSales: (state, { payload }) => {
      state.allSales = payload.data;
      state.allSalesCount = payload.totalCount;
      state.allSalesPageCount = payload.pageCount;
    },
    addWorkshopAccount: (state, { payload }) => {
      state.workshopAccount = payload;
    },
    addWorkshopAccountList: (state, { payload }) => {
      state.workshopAccountList = payload;
    },
    addQueuedFile: (state, { payload }) => {
      state.queuedFile = payload;
    },
    addSingleQueue: (state, { payload }) => {
      state.queuedFile = [...state.queuedFile, payload];
    },
    addPendingWorkshops: (state,{payload})=>{
      state.pendingWorkshops=payload;
    },
    addAllSalesParams: (state, {payload}) => {
      state.allSalesParams = payload;
    },
    addInitialPageIndex: (state, { payload }) => {
      state.initalPageIndex = payload;
    },
    addInitialPageSize: (state, { payload }) => {
      state.initialPageSize = payload;
    },
    addInputValues : (state, { payload }) => {
      state.inputValues = {...state.inputValues, ...payload}
    }
  },
});

export const {
  addAllSales,
  addWorkshopAccount,
  addWorkshopAccountList,
  addQueuedFile,
  addSingleQueue,
  addPendingWorkshops,
  addAllSalesParams,
  addInitialPageIndex,
  addInitialPageSize,
  addInputValues
} = salesSlice.actions;

export const uploadBulkSales = (payload) => (dispatcher) => {
  return createBulkSales(payload).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error);
      }
    },
  );
};

export const getPendingWorkshop = (payload) => dispatcher => {
  return getPendingWorkshops(payload).then(
      response => {
          let data = [];
          // let totalCount = 0;
         
          if(response.data.length > 0){
         
            
              data = response.data;
              // totalCount = response.totalCount.count;
          }
          dispatcher(addPendingWorkshops(data));
          return Promise.resolve(data);
      },
      error => {
          if(error.response){
              return Promise.reject(error.response?.data?.message);
          }
      }
  )
}

export const getAllSales = (payload) => (dispatcher) => {
  return fetchAllSales(payload).then(
    (response) => {
      let data = [];
      let totalCount=0;

      if (typeof response.data !== 'string' && !_.isEmpty(response.data)) {
        data = Object.entries(response.data.data)?.map(([key, value]) => {
          return { ...value, code: key };
        });
        totalCount = response.data.totalCount.count;
      }
      payload.params.limit = payload.params?.limit ? payload.params?.limit : 10;
      const pageCount = Math.ceil(totalCount / payload.params?.limit)
      dispatcher(addAllSales({data,totalCount, pageCount}));
      dispatcher(addAllSalesParams({limit: payload.params?.limit, offset: payload.params?.offset}))
      return Promise.resolve({data,totalCount, pageCount});
    },
    (error) => {
      if (error.response) {

        return Promise.reject(error.response.data);
      }
    },
  );
};

export const getWorkshopAccounts = (payload) => (dispatcher) => {
  return fetchWorkshopAccounts(payload).then((response) => {
    dispatcher(addWorkshopAccount([response.data.workshopAccounts]));
    dispatcher(addWorkshopAccountList(response.data.accounts));
    return Promise.resolve(response.data);
  });
};

export const getQueuedFiles = (payload) => (dispatcher) => {
  return getFileQueue(payload).then(
    (response) => {
      dispatcher(addQueuedFile(response.data));
      return Promise.resolve(response.data);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    },
  );
};

export const selectSales = (state) => state.sales;

export default salesSlice.reducer;
