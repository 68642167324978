import React from "react";
import { Badge } from "@chakra-ui/react";

const XpressCustomer = ({ name, isXpressCustomer }) => {
  return (
    <span>
      {name}{" "}
      {isXpressCustomer && (
        <Badge colorScheme="purple" style={{ fontSize: "xx-small" }} ml={2}>
          xpress
        </Badge>
      )}
    </span>
  );
};

export default XpressCustomer;
