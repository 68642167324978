import React, { useState } from "react";
import { AsyncSelect } from "chakra-react-select";
import { getCustomerSearch } from "../../globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { isNumeric } from "../../utils/isNumericValue";

const CustomerSearch = ({
  label,
  value,
  onChange,
  isMulti = false,
  isClearable = false,
}) => {
  const dispatcher = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [timer, setTimer] = useState(null);

  const handleSearchWorkshop = (value, callBack) => {
    clearTimeout(timer); // clear previous search once a new search is initiated

    const thisTimer = setTimeout(() => {
      // trigger a new search after 3000ms
      (async () => {
        try {
          const response = await dispatcher(
            getCustomerSearch({
              auth,
              params: {
                isXpress: true,
                search: isNumeric(value) ? `91${value}` : value,
              },
            })
          );
          const workshopOptions = response.data?.map((customer) => {
            console.log(customer);
            return {
              value: `${customer._id}`,
              label: `${customer.name} (${customer?.supplierAssociations[0]?.supplierCode})`,
            };
          });
          callBack(workshopOptions);
        } catch (error) {}
      })();
    }, 2000);

    setTimer(thisTimer);
  };

  return (
    <Box>
      <AsyncSelect
        value={value}
        onChange={onChange}
        loadOptions={handleSearchWorkshop}
        isMulti={isMulti}
        isClearable={isClearable}
        placeholder={"Customer Name/Mobile"}
        size="sm"
      />
    </Box>
  );
};

export default CustomerSearch;
