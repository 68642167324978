import InviteCard from './inviteCard';
import PropTypes from 'prop-types';
import './style.css'
const InviteEnvelop = ({ invites = [], handleSubmit }) => {
  return (
    <div className="wrapper">
      <div className="lid one"></div>
      <div className="lid two"></div>
      <div className="envelope"></div>
      <div className="letter">
        {
          invites.map((invite, index) => {
            return <>
              <InviteCard key={invite.inviteToken} welcomeNote={invite.welcomeNote} handleSubmit={handleSubmit} token={invite.inviteToken} />
            </>
          })
        }
      </div>
    </div>
  );
}
InviteEnvelop.propTypes = {
  handleSubmit: PropTypes.func,
  invites: PropTypes.array
};
export default InviteEnvelop