import { defineMessages } from 'react-intl';

export const scope = 'app.containers.EmployeeDashboard';

export default defineMessages({

  networkErrorTitle: {
    id: `${scope}.networkErrorTitle`,
    defaultMessage: 'No internet connection!',
  },
  networkErrorMessage: {
    id: `${scope}.networkErrorMessage`,
    defaultMessage: 'No internet connection!',
  },
  serverErrorMessage: {
    id: `${scope}.serverErrorMessage`,
    defaultMessage: '500 Something went wrong!',
  },
  serverErrorTitle: {
    id: `${scope}.serverErrorTitle`,
    defaultMessage: '500 Something went wrong!',
  },
  addEmployee: {
    id: `${scope}.addEmployee`,
    defaultMessage: 'Add Employee',
  },


  indiviOpt: {
    id: `${scope}.indiviOpt`,
    defaultMessage: 'Get started by adding an employee',
  },
  indiviTitle: {
    id: `${scope}.indiviTitle`,
    defaultMessage: 'Add an employee',
  },
  indiviMsg: {
    id: `${scope}.indiviMsg`,
    defaultMessage: 'Mannualy add details of an employee',
  },
  bulkOpt: {
    id: `${scope}.bulkOpt`,
    defaultMessage: 'Bulk add multiple employees',
  },
  bulkTitle: {
    id: `${scope}.bulkTitle`,
    defaultMessage: 'Upload employees using Excel file',
  },
  bulkMsg: {
    id: `${scope}.bulkMsg`,
    defaultMessage: 'Add your employees in bulk using Excel file',
  },
  exportToCSV: {

      id: `${scope}.exportToCSV`,
      defaultMessage: 'Export to CSV',
    
  }

});
