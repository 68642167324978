export const ROUTES = {
  DASHBOARD: "/dashboard",
  ORDERS: "/orders",
  ONBOARDING: "/onboarding",
  DISTRIBUTOR: "/distributor",
  PROFILE: "/profile",
  BRANCHES: "/branches",
  BRANCH_ADD: "/branch/add",
  BRANCH_ADD_BULK: "/branch/add/bulk",
  BRANCH_EDIT: "/branch/:branchId/edit",
  EMPLOYEES: "/employees",
  EMPLOYEE_ADD: "/employee/add",
  EMPLOYEE_ADD_BULK: "/employee/add/bulk",
  PARTS: "/parts",
  PARTS_ADD: "/part/add",
  PART_ADD_BULK: "/part/add/bulk",
  PART_INFO: "/part/:partId/view",
  PART_EDIT: "/part/:partId/edit",
  WORKSHOPS: "/workshops",
  WORKSHOP_ADD: "/workshop/add",
  WORKSHOP_EDIT: "/workshop/:workshopId/edit",
  WORKSHOP_ADD_Bulk: "/workshop/add/bulk",
};

const routes = {
  distributor: "distributors",
  orderRoute: "/orders",
  getAllGaraazOrders: "/v2/orders",
  profile: "/profile",
  stockAvailabilityRoute: "/inventory/availability",
  partRoute: "/part",
  accountRoute: "/accounts",
  outstandingRoute: "/outstanding",
  collectionsRoute: "/collections",
  workshopRoute: "/workshops",
  inventoryRoute: "/inventory",
  accountBulkRoute: "/accounts/add-bulk-account",
  uploadQueueEndpoint: "/upload-queue",
  placeOrderRoute: "/place-order",
  getDeviceToken: (id) => `/distributors/${id}/deviceToken`,
  notificationRoute: "/notifications",
  readNotificationRoute: "/notifications/read",
  getUnreadNotificationCount: "/notifications/unread-count",
  markNotificationAsRead: "/notifications/read",
  getTotalNotificationCount: "/notifications/count",
  markAllAsRead: "/notifications/read-all",
  getSuplierinfo: (supplierId) => `/v1/suppliers/${supplierId}`,
  updateSuplierinfo: (supplierId) => `/v1/suppliers/${supplierId}`,
  deleteSupplierBranchById: (supplierId, branchId) =>
    `/v1/suppliers/${supplierId}/branch/${branchId}`,
  employee: (userId) => `/employees/${userId}`,
};

export default routes;
