import { publicAxios as axios } from '../../axios';
import { GET_ORDER_DETAILS, SET_ORDER_DETAILS } from './constants';
import routes from '../../routes';
import getURLParams from '../../utils/getURLParams';


export const getOrderDetails = async (auth, orderId) => {
    const response = await axios({
        method: 'GET',
        url: `${GET_ORDER_DETAILS}/${orderId}`,
        headers: {
            Authorization: auth.authToken
        }
    });

    return response.data ?? {};
}

export const updateOrders = async (payload) => {
    const response = await axios({
        method: 'PATCH',
        url: `${routes.orderRoute}/${payload.orderId}`,
        headers: {
            Authorization: payload.auth.authToken
        },
        data: payload.data
    });

    return response.data ?? {};
}


export const setOrderDetails = (payload) => ({
    type: SET_ORDER_DETAILS,
    payload
})


export const completeOrder = async (payload) => {
    return await axios({
        method: 'PATCH',
        url: `${routes.orderRoute}/${payload.orderId}/complete`,
        headers: {
            Authorization: payload.auth.authToken
        }
    })
}

export const getQuantityAvailable = async (payload) => {
    const params = getURLParams(payload.params);

    return axios({
        method: 'GET',
        url: `${routes.stockAvailabilityRoute}${params}`,
        headers: {
            Authorization: payload.auth.authToken
        }
    })
}


export const createUnavailableOrder = async (payload) => {
    return axios({
        method: 'POST',
        url: `${routes.orderRoute}/${payload.orderId}/unavailable-items/?locationId=${payload.locationId}`,
        headers: {
            Authorization: payload.auth.authToken
        },
        data: payload.data
    })
}

export const getSinglePart = async (payload) => {
    const params = getURLParams(payload.params);

    return axios({
        method: 'GET',
        /* url: `${routes.partRoute}${params}`, */
        url: `/part/get-product-by-code/${payload.params.partNumber}`,
        headers: {
            Authorization: payload.auth.authToken
        }
    })
}


export const getInvoiceData = async (payload) => {
    return axios({
        method: 'GET',
        url: `${routes.orderRoute}/${payload.orderId}/export?option=json`,
        headers: {
            Authorization: payload.auth.authToken
        }
    })
}

export const uploadMedia = (payload) => {
    return axios({
        method: 'POST',
        url: `/media`,
        headers: {
            Authorization: payload.auth.authToken,
        },
        data: payload.data,
    });
};

