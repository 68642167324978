export const TabList = {
  sizes: {

  },
  variants: {
    dropDown: {
      display: "flex",
      flexDirection: "column"
    }
  }
}