import produce from 'immer';

import { ATTEMPT_LOGIN, ATTEMPT_LOGOUT, SET_AUTH_USER } from './constants';

export const initialState = {
  authToken: '',
  validProfile: false,
  user: null,
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_LOGIN:
        draft.authToken = action.payload.auth;
        draft.validProfile = action.payload.isValidProfile;

        if (action.payload.user) {
          draft.user = action.payload.user;
        }
        break;
      case SET_AUTH_USER:
        draft.user = {
          ...draft.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          role: action.payload.role,
        };
        draft.validProfile = true;
        break;
      case ATTEMPT_LOGOUT:
        draft.authToken = '';
        draft.validProfile = false;
        draft.user = null;
        break;
    }
  });

export default authReducer;
