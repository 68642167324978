import { SET_EMPLOYEE_LIST } from './constants';
import { publicAxios as axios } from '../../axios';
export const setEmployeeList = (data) => {
	return {
		type: SET_EMPLOYEE_LIST,
		payload: data,
	};
}

export const getBranches = async (auth) => {
	try {
		const { data } = await axios.get('/branches', {
			headers: {
				Authorization: auth.authToken,
			},
		})
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}



export const getEmployees = async (auth) => {
	const { data } = await axios.get('/employees', {
		headers: {
			Authorization: auth.authToken,
		}
	})
	return { data: data }
}
export const getEmployee = async (auth, employeeId) => {
	const { data } = await axios.get(`/employee/${employeeId}`, {
		headers: {
			Authorization: auth.authToken,
		}
	})
	return { data: data }
}

export const getEmployeeDesignations = async (auth) => {
	const { data } = await axios.get(`/employees/designations`, {
		headers: {
			Authorization: auth.authToken,
		}
	})
	return { data }
}
