import PropTypes from "prop-types";
import { Box, useColorMode } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar/NewSideBar";
import NavbarAdmin from "../../components/NavbarAdmin";
import { useRouteMatch, useParams, useLocation } from "react-router-dom";

const Layout = ({ title = "", children }) => {
  const { colorMode } = useColorMode();
  const { pathname } = useLocation();

  return (
    <Box height={"100%"}>
      <Box
        backgroundColor={colorMode === "dark" ? "gray.900" : "white"}
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        overflow={"scroll"}
        p={0}
        className={"ui"}
      >
        <Box position={"sticky"} top={0} height={"56px"} zIndex={4}>
          <Sidebar></Sidebar>
        </Box>

        <div
          className="px-4 md:px-5 mx-auto w-full mt-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Layout;
