import { createSlice } from '@reduxjs/toolkit';
import { getPartList } from './service';

const partSlice = createSlice({
  name: 'partSlice',
  initialState: {
    parts: [],
    bulkOrderItem: [],
    partsTotalCount: 0,
    partsPageCount: 0,
    initalPageIndex: 0,
    initialPageSize: 10,
    partsParams: {
      offset: 0,
      limit: 10,
    },
  },
  reducers: {
    addParts: (state, { payload }) => {
      state.parts = payload.data;
      state.partsTotalCount = payload.totalCount;
      state.partsPageCount = payload.pageCount;
    },
    addInitialPageIndex: (state, { payload }) => {
      state.initalPageIndex = payload;
    },
    addInitialPageSize: (state, { payload }) => {
      state.initialPageSize = payload;
    },
    addPartsParams: (state, { payload }) => {
      state.partsParams = payload;
    },
  },
});

export const {
  addParts,
  addPartsParams,
  addInitialPageIndex,
  addInitialPageSize,
} = partSlice.actions;

export const getParts = (payload) => (dispatcher) => {
  return getPartList(payload).then(
    (response) => {
      let data = [];
      let totalCount = 0;
      if (response.data.length > 0) {
        data = response.data[0].data;
        totalCount = response.data[0].totalCount.count;
      }
      payload.params.limit = payload.params?.limit ? payload.params?.limit : 10;
      const pageCount = Math.ceil(totalCount / payload.params?.limit);
      dispatcher(addParts({ data, totalCount, pageCount }));
      dispatcher(
        addPartsParams({
          limit: payload.params?.limit,
          offset: payload.params?.offset,
        }),
      );
      return Promise.resolve({ data, totalCount });

      /* return Promise.resolve(
        response.data.length > 0
          ? response.data[0]
          : { data: [], totalCount: { count: 0 } },
      ); */
    },

    (error) => {
      return Promise.reject(error);
    },
  );
};

export const selectPart = (state) => state.partListReducer;

export default partSlice.reducer;
