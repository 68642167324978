import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { publicAxios as axios } from '../../axios';
import { useDispatch } from 'react-redux';
import { attemptLogin } from '../../containers/LoginPage/actions';
import logo from '../../images/logo.png';
import toast from 'react-hot-toast';
import get from 'lodash/get';
import InviteEnvelop from './InviteAnimate';
import garaazToast from '../../HOCs/garaazToast';

const Invite = ({ notification }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [invites, setInvite] = useState([]);

  const handleSubmit = async (inviteToken, response) => {
    try {
      const res = await axios.patch('/employees/invites', {
        // action: "ACCEPTED",
        inviteToken: inviteToken,
        action: response,
      });

      if (response === 'ACCEPTED') {
        dispatch(attemptLogin(get(res, 'data', {})));
        history.push('/orders');
        localStorage.removeItem('invite');
      } else {
        setInvite(
          invites.filter((invite) => {
            if (invites.length === 1) {
              toast('You rejected all invites');
              history.push('/login');
            } else {
              return invite.inviteToken !== inviteToken;
            }
          }),
        ); //insted of this will call get invites again
      }
    } catch (error) {
      if (error.response) {
        notification({
          status: 'error',
          title: 'Expired invitation link',
          description: error.response.data?.errorMsg,
        });
      } else {
        toast(error.response.data.message);
        setInvite(
          invites.filter((invite) => invite.inviteToken !== inviteToken),
        );
      }
    }
  };

  useEffect(() => {
    const token = history.location.search.split('token=')[1];
    try {
      const decodedToken = atob(decodeURIComponent(token));
      setInvite([JSON.parse(decodedToken)]);
    } catch (error) {
      alert('Invite expired or invalid link');
      history.push('/');
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h3 style={{ fontWeight: 'bold', fontSize: '23px', bottom: '100vh' }}>
        Welcome to Garaaz
      </h3>
      <InviteEnvelop handleSubmit={handleSubmit} invites={invites} />
      <div style={{}}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} width='40' height='40'></img>
            <h4
              style={{
                fontWeight: 'bolder',
                fontSize: '24px',
                paddingTop: '5px',
              }}
            >
              Garaaz
            </h4>
          </div>
        </div>
        <p>Copyright © 2021 all rights reserved</p>
      </div>
    </div>
  );
};
export default garaazToast(Invite);
