import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PartList';

export default defineMessages({
  partList: {
    id: `${scope}.partList`,
    defaultMessage: 'Part List',
  },
  addPart: {
    id: `${scope}.addPart`,
    defaultMessage: 'Add Part',
  },
  indiviOpt: {
    id: `${scope}.indiviOpt`,
    defaultMessage: 'Get started by adding a part',
  },
  indiviTitle: {
    id: `${scope}.indiviTitle`,
    defaultMessage: 'Add a part',
  },
  indiviMsg: {
    id: `${scope}.indiviMsg`,
    defaultMessage: 'Mannualy add details of a part',
  },
  bulkOpt: {
    id: `${scope}.bulkOpt`,
    defaultMessage: 'Bulk add multiple parts',
  },
  bulkTitle: {
    id: `${scope}.bulkTitle`,
    defaultMessage: 'Upload parts using Excel file',
  },
  bulkMsg: {
    id: `${scope}.bulkMsg`,
    defaultMessage: 'Add your parts in bulk using Excel file',
  },


});
