import messages from './messages'
import individualIllustrator from '../../assets/img/employeeIllustrator.svg'
import bulkIllustrator from '../../assets/img/bulkAddIllustrator.svg'
export const SET_EMPLOYEE_LIST = 'SET_EMPLOYEE_LIST';
export const optionsProp = {
  indivi: {
    header: messages.indiviOpt,
    title: messages.indiviTitle,
    msg: messages.indiviMsg,
    illustrators: individualIllustrator,
    link: '/employee/add'
  }, bulk: {
    header: messages.bulkOpt,
    title: messages.bulkTitle,
    msg: messages.bulkMsg,
    illustrators: bulkIllustrator,
    link: '/employee/add/bulk'
  }
}