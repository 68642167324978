import React, { useMemo, useCallback } from "react";
import {
  Box,
  useColorMode,
  Image,
  useDisclosure,
  Collapse,
  Flex,
  Fade,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";

import classnames from "classnames";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { FaBuffer, FaLanguage } from "react-icons/fa";

import { changeLocale } from "../../containers/LanguageProvider/actions";

import { selectGlobalState } from "../../globalSlice";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Notification from "../NavbarAdmin/Notification";
import GarrazMonthlyIncentive from "../../containers/OrderListNew/GaraazMonthlyIncentive";

import enums from "../../utils/enums";
import {
  distributorNavObj,
  employeeNavObj,
  incompleteNavObj,
  lang,
} from "./helpers";
import ProfileDropDown from "./ProfileDropDown";

const NewSideBar = ({ isOpen }) => {
  const { isDistributor, supplier, allAvailableBranches, employee } =
    useSelector(selectGlobalState);

  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const storeLocale = useSelector((state) => get(state, "language.locale", ""));

  const handleLanguageChange = useCallback((event) => {
    if (typeof event === "string") {
      dispatch(changeLocale(event));
    } else {
      dispatch(changeLocale(event.target.value));
    }
  }, []);
  const getSupplierBranchName = useCallback(
    (branchId) => {
      const branch = allAvailableBranches?.find(
        (branch) => branch?._id === branchId
      );
      return branch ? branch?.branchName : "";
    },
    [allAvailableBranches, employee]
  );

  return (
    <Box
      as="nav"
      backgroundColor={"#08AEEA"}
      display={"flex"}
      height={"56px !important"}
      alignItems={"center"}
      px={3}
      justifyContent={"space-between"}
      position={"sticky"}
      top={0}
      zIndex={4}
      backgroundImage={"linear-gradient(90deg, #2AF598 0%,  #08AEEA 100%)"}
    >
      <Box display={"flex"}>
        <NavLink
          to={
            (isDistributor && !localStorage.onboard) || !isDistributor
              ? "/orders"
              : "/profile"
          }
          style={{ display: "flex", alignItems: "center" }}
        >
          <Image src={logo} alt="Logo" width={"40px"} height={"40px"} />
          <Text
            fontWeight={"extrabold"}
            fontSize={"large"}
            color={"black"}
            ml={2}
          >
            Garaaz
          </Text>
        </NavLink>
        <div
          style={{
            margin: "0.5em",
            width: "fit-content",
            display: "flex",
            position: "relative",
          }}
        >
          <GarrazMonthlyIncentive />
        </div>
      </Box>

      <Box display={"flex"} alignItems={"center"}>
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          <NavLink
            exact
            to={"/orders"}
            className={classnames(
              `text-white text-sm hover:text-red-700 block`
            )}
            activeClassName="text-red-700"
          >
            <Icon fontSize={"large"} as={FaBuffer} mr={1}></Icon>
            <FormattedMessage {...messages.orders} />
          </NavLink>
        </div>
        <Box
          width={"2px"}
          height={"30px"}
          backgroundColor={"white"}
          ml={5}
        ></Box>
        <Box>
          <Text
            fontWeight={"bold"}
            color={"white"}
            fontSize={"sm"}
            ml={3}
            verticalAlign={"center"}
          >
            {isDistributor
              ? supplier?.businessName
              : `${supplier?.businessName}-${getSupplierBranchName(
                  employee?.branchId
                )}`}
          </Text>
        </Box>

        <ProfileDropDown
          isOpen={isOpen}
          SingleNav={SingleNav}
        ></ProfileDropDown>
      </Box>
    </Box>
  );
};

const LangChange = (isOpen) => {
  const dispatch = useDispatch();
  const storeLocale = useSelector((state) => get(state, "language.locale", ""));
  const handleLanguageChange = useCallback((event) => {
    if (typeof event === "string") {
      dispatch(changeLocale(event));
    } else {
      dispatch(changeLocale(event.target.value));
    }
  }, []);
  return (
    <GenericGroupNav2 {...lang(isOpen, storeLocale, handleLanguageChange)} />
  );
};

export const GenericGroupNav = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode } = useColorMode();

  const subNavList = [...props.subNavs].map((nav) => {
    return (
      <SingleNav
        key={nav.to}
        to={nav.to}
        icon={
          <Box className={`${isOpen ? "mr-2" : "mr-0"} text-sm`}>
            {nav.icon}
          </Box>
        }
        message={nav.name}
        onClick={props.onClick}
      />
    );
  });

  return (
    <React.Fragment>
      <NavLink
        exact
        to={props.to}
        onClick={onToggle}
        className={classnames(
          "text-xs pl-4 uppercase py-3 font-bold block flex"
        )}
        activeClassName="text-red-500 hover:text-red-700"
      >
        <Flex alignItems="center">
          <Box width="fit-content" mr={2} fontSize={{ base: 16, lg: "lg" }}>
            {props.icon}
          </Box>
          <Box mr={2}>{props.mainNav}</Box>

          {Boolean(props.subNavs.length) && (
            <>
              <Fade in={!isOpen}>
                <Box>
                  <IoIosArrowDown
                    style={{
                      fontSize: 16,
                      display: !isOpen ? "inline-block" : "none",
                    }}
                  />
                </Box>
              </Fade>
              <Fade in={isOpen}>
                <Box>
                  <IoIosArrowUp
                    style={{
                      fontSize: 16,
                      display: isOpen ? "inline-block" : "none",
                    }}
                  />
                </Box>
              </Fade>
            </>
          )}
        </Flex>
      </NavLink>
      <Collapse in={isOpen} animateOpacity>
        <Box backgroundColor={colorMode === "dark" ? "black" : "gray.100"}>
          {subNavList}
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

export const GenericGroupNav2 = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode } = useColorMode();

  const subNavList = [...props.subNavs].map((nav) => {
    return (
      <SingleNav1
        key={nav.key}
        isActive={nav.isActive}
        icon={
          <Box className={`${isOpen ? "mr-2" : "mr-0"} text-sm`}>
            {nav.icon}
          </Box>
        }
        message={nav.name}
        onClick={nav.onClick}
      />
    );
  });

  return (
    <React.Fragment>
      <Button
        size="sm"
        sx={{
          textTransform: "capitalize",
          backgroundColor: "transparent",
          _hover: {
            cursor: "pointer",
          },
          _focus: {
            outline: "none",
          },
        }}
        onClick={onToggle}
        className={classnames(
          "text-xs pl-4 uppercase py-3 font-bold block flex"
        )}
      >
        <Flex alignItems="center">
          <Box width="fit-content" fontSize={{ base: 16, lg: "lg" }}>
            {props.icon}
          </Box>
          <Box mr={2}>{<FormattedMessage {...props.mainNav} />}</Box>

          {Boolean(props.subNavs.length) && (
            <>
              <Fade in={!isOpen}>
                <Box>
                  <IoIosArrowDown
                    style={{
                      fontSize: 16,
                      display: !isOpen ? "inline-block" : "none",
                    }}
                  />
                </Box>
              </Fade>
              <Fade in={isOpen}>
                <Box>
                  <IoIosArrowUp
                    style={{
                      fontSize: 16,
                      display: isOpen ? "inline-block" : "none",
                    }}
                  />
                </Box>
              </Fade>
            </>
          )}
        </Flex>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box backgroundColor={colorMode === "dark" ? "black" : "gray.100"}>
          {subNavList}
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

const SingleNav1 = ({ message, onClick, isActive }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        textTransform: "capitalize",
        color: isActive && "red",
        _hover: {
          cursor: "pointer",
        },
      }}
      className={classnames("text-xs pl-4 uppercase py-3 font-bold block flex")}
    >
      <FormattedMessage {...message} />
    </Box>
  );
};

const SingleNav = ({ to, icon, message, onClick }) => {
  return (
    <Box
      display={"flex"}
      mr={5}
      alignItems={"center"}
      cursor={"pointer"}
      className="ui"
    >
      <NavLink
        exact
        onClick={onClick}
        to={to}
        className={classnames(`block flex hover:text-red-700`)}
        activeClassName="text-red-700"
      >
        <Box display={"flex"} alignItems={"center"} fontSize={"sm"}>
          <Icon fontSize={"large"} as={icon} mr={1}></Icon>
          <FormattedMessage {...message} />
        </Box>
      </NavLink>
    </Box>
  );
};

export default NewSideBar;
