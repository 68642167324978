import enums from "./enums";

class SupplierOrderStatus {
  static isActionTaken(supplierOrderStatus) {
    return supplierOrderStatus === enums.READY ||
      supplierOrderStatus === enums.CBO ||
      supplierOrderStatus === enums.CANCELLED ||
      supplierOrderStatus === enums.ACCEPTED ||
      supplierOrderStatus === enums.REJECTED
      ? true
      : false;
  }
}

export default SupplierOrderStatus;
