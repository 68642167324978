/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { OrderDetailInfo } from "../../components/OrderComponentsNew/OrderDetailInfo";
import messages from "./messages";
import { useIntl } from "react-intl";
import enums, { getSupplierStateColor } from "../../utils/enums";
import { BiSave } from "react-icons/bi";

import {
  Text,
  Box,
  IconButton,
  Center,
  Button,
  Divider,
  Tag,
  HStack,
  Select,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";

import {
  FaPlay,
  FaStop,
  FaForward,
  FaBackward,
  FaPause,
  FaLongArrowAltRight,
  FaLongArrowAltLeft,
} from "react-icons/fa";
import garaazToast from "../../HOCs/garaazToast";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetails,
  setOrderDetails,
  updateOrders,
  getSinglePart,
  placeGaraazOrder,
  registerSupplierAction,
  getSingleGaraazOrderFromOrderId,
  transferSupplierBranch,
} from "./actions";

import { FiZoomIn, FiZoomOut, FiRotateCcw, FiRotateCw } from "react-icons/fi";
import { AsyncSelect } from "chakra-react-select";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import TableRow from "./TableRow";
import _ from "lodash";
import SimpleModalNew from "../../components/ModalNew";
import { selectGlobalState, updateActionTaken } from "../../globalSlice";
import { FaPencilAlt } from "react-icons/fa";
import { EXPORT_DOC_V2 } from "./constants";
import { GrDownload } from "react-icons/gr";
import GaraazAlertDialog from "../../utils/GaraazAlertDialog";

const RequestOrder = (props) => {
  const intl = useIntl();
  const { orderNo } = useParams();
  const auth = useSelector((state) => state.auth);
  const dispatcher = useDispatch();
  const { orderDetails } = useSelector((state) => state.orderDetails);
  const [orderItems, setOrderItems] = useState([{}]);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isCBOLoading, setCBOLoading] = useState(false);
  const [isCancelLoading, setCancelLoading] = useState(false);
  const [parentOrderDetails, setParentOrderDetails] = useState({});
  const [isTransferOrderLoading, setIsTransferOrderLoading] = useState(false);
  const [isSaveaveAsDraftLoading, setSaveAsDraftLoading] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const { allAvailableBranches, toggleActionTaken } =
    useSelector(selectGlobalState);

  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();
  const {
    isOpen: isMismatchOpen,
    onOpen: onMismatchOpen,
    onClose: onMismatchClose,
  } = useDisclosure();
  const [alertDialogTitleText, setAlertDialogTitleText] = useState("");
  const [alertDialogBodyText, setAlertDialogBodyText] = useState("");

  const {
    isOpen: isTransferModalOpen,
    onOpen: onTransferOrderModalOpen,
    onClose: onTransferOrderModalClose,
  } = useDisclosure();

  const fetchOrderDetails = async () => {
    try {
      const data = await getOrderDetails(auth, orderNo);

      if (data?.orderItems) {
        const transformedItems = data.orderItems.map((item, index) => {
          return {
            ...item,
            SNo: index,
            givenQuantity: item.givenQuantity ?? item.orderedQuantity,
          };
        });
        setOrderItems(transformedItems);
      }

      dispatcher(setOrderDetails(data));
    } catch (error) {
      props.notification({
        status: "error",
        description: error.response
          ? error.response.data.message
          : intl.formatMessage(messages.errorOccurred),
      });
    }
  };

  const fetchOrderDetailsByOrderId = async () => {
    try {
      const data = await getSingleGaraazOrderFromOrderId(
        auth,
        orderDetails?.parentOrderId
      );
      setParentOrderDetails(data);
    } catch (error) {
      props.notification({
        status: "error",
        description: error.response
          ? error.response.data.message
          : intl.formatMessage(messages.errorOccurred),
      });
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    if (orderDetails.orderType === "RETURN") {
      fetchOrderDetailsByOrderId();
    }
  }, [orderDetails]);

  const branchOptions = useMemo(() => {
    return allAvailableBranches?.flatMap((branch) =>
      branch._id !== orderDetails?.allocatedTo?.[0]?.branchId
        ? [{ label: branch.branchName, value: branch._id }]
        : []
    );
  }, [allAvailableBranches, orderDetails?.allocatedTo]);

  const handleAddRow = () => {
    const prevOrderItems = [...orderItems].filter((item) => !_.isEmpty(item));
    setOrderItems([...prevOrderItems, {}]);
  };

  const isButtonsDisabled = useMemo(() => {
    return [...orderItems].some((item) => {
      const orderedQuantity = parseInt(item.orderedQuantity) || 0;
      const givenQuantity = parseInt(item.givenQuantity) || 0;
      return (
        givenQuantity > orderedQuantity ||
        !item.partId ||
        !item.partNumber ||
        isNaN(parseInt(item.givenQuantity)) ||
        parseInt(item.givenQuantity) < 0 ||
        parseInt(item.orderedQuantity) <= 0
      );
    });
  }, [orderItems]);

  const isAllGivenQuantityZero = useMemo(() => {
    return [...orderItems].every((item) => parseInt(item.givenQuantity) === 0);
  }, [orderItems]);

  const handleSaveAsDraftOrder = async () => {
    try {
      setSaveAsDraftLoading(true);
      const updatedItems = orderItems?.map((item) => {
        return {
          partId: item.partId,
          orderedQuantity: parseInt(item.orderedQuantity),
          givenQuantity: parseInt(item.givenQuantity),
        };
      });
      const orderResponse = await updateOrders({
        auth,
        orderId: orderDetails._id,
        data: { orderItems: updatedItems },
      });

      const updatedOrderItems = orderItems.map((item) => {
        const responseItem = orderResponse.orderItems.find(
          (response) => response.partId === item.partId
        );
        if (responseItem && responseItem.orderedQuantity !== undefined) {
          return {
            ...item,
            orderedQuantity: responseItem.orderedQuantity || 0,
            givenQuantity: responseItem.givenQuantity || 0,
          };
        }
        return item;
      });
      setOrderItems(updatedOrderItems);
      history.push("/orders");
    } catch (error) {
      alert("Something went wrong!");
    } finally {
      setSaveAsDraftLoading(false);
    }
  };

  const handleOrderStatusChange = async (status, action) => {
    try {
      status === enums.CANCELLED ? setCancelLoading(true) : setCBOLoading(true);
      const updatedItems = [];
      const newOrderItems = [];
      for (const item of orderItems) {
        const partId = item.partId;
        const orderedQuantity = parseInt(item.orderedQuantity) || 0;
        const givenQuantity = parseInt(item.givenQuantity) || 0;
        if (givenQuantity > orderedQuantity) {
          props.notification({
            description: `Given quantity for part ${item?.partNumber} is greater than ordered quantity.`,
            status: "error",
          });
          return;
        }
        if (givenQuantity > 0) {
          updatedItems.push({
            partId: partId,
            orderedQuantity: givenQuantity,
            givenQuantity: givenQuantity,
          });
        }
        if (givenQuantity < orderedQuantity) {
          newOrderItems.push({
            partId: partId,
            orderedQuantity: orderedQuantity - givenQuantity,
            givenQuantity: 0,
          });
        }
      }

      const currentOrderUpdatedData = {
        orderItems: updatedItems,
      };

      await registerSupplierAction({
        auth,
        orderId: orderDetails._id,
        action,
      });

      if (newOrderItems.length > 0) {
        const newOrderPayload = {
          orderItems: newOrderItems,
          shipmentAddress: orderDetails?.shipmentAddress,
          customerRemarks: orderDetails?.customerRemarks,
          customerId: orderDetails?.customerId,
          initiatorId: orderDetails?.initiatorId,
          orderMedia: orderDetails?.orderMedia,
          transactionType: orderDetails?.transactionType,
          allocatedTo: [
            {
              ...orderDetails?.allocatedTo[0],
              orderStatus: enums.NEW,
              invoiceInfo: undefined,
              challanInfo: undefined,
              allocationTime: undefined,
            },
          ],
        };

        await placeGaraazOrder({
          auth,
          data: newOrderPayload,
        });
      }

      await updateOrders({
        auth,
        orderId: orderDetails._id,
        data: currentOrderUpdatedData,
      });

      history.push("/orders");
    } catch (error) {
      props.notification({
        description: "Something went wrong!",
        status: "error",
      });
    } finally {
      setCancelLoading(false);
      setCBOLoading(false);
    }
  };

  const handleAcceptRejectOrder = async (action) => {
    try {
      if (
        action === "ACCEPT" &&
        !(
          orderDetails?.allocatedTo[0]?.invoiceInfo ||
          orderDetails?.allocatedTo[0]?.challanInfo
        )
      ) {
        props.notification({
          description:
            "Something went wrong!, Please ensure that invoice has been uploaded",
          status: "error",
        });
        return false;
      }
      await registerSupplierAction({
        auth,
        orderId: orderDetails._id,
        action,
      });
      history.push("/orders");
    } catch (error) {
      props.notification({
        description: "Something went wrong!, Please try again",
        status: "error",
      });
    }
  };

  const handleCompleteOrder = async () => {
    try {
      setLoading(true);

      if (
        orderItems.length === 0 ||
        orderItems.some(
          (item) =>
            !item.partId ||
            !item.orderedQuantity ||
            !item.givenQuantity ||
            !item.partNumber
        )
      ) {
        props.notification({
          description:
            "Please make sure all order items have part and quantity.",
          status: "error",
        });
        return;
      }

      const newItems = [];
      const updatedItems = [];

      for (const item of orderItems) {
        const partId = item.partId;
        const orderedQuantity = parseInt(item.orderedQuantity) || 0;
        const givenQuantity = parseInt(item.givenQuantity) || 0;

        if (givenQuantity > orderedQuantity) {
          props.notification({
            description: `Given quantity for part ${item?.partNumber} is greater than ordered quantity.`,
            status: "error",
          });
          return;
        }
        if (givenQuantity > 0) {
          updatedItems.push({
            partId: partId,
            orderedQuantity: givenQuantity,
            givenQuantity: givenQuantity,
          });
        }
        if (givenQuantity < orderedQuantity) {
          newItems.push({
            partId: partId,
            orderedQuantity: orderedQuantity - givenQuantity,
            givenQuantity: orderedQuantity - givenQuantity,
          });
        }
      }

      const updatedParts = {
        orderItems: updatedItems,
      };
      await registerSupplierAction({
        auth,
        orderId: orderDetails._id,
        action: enums.READY,
      });

      if (newItems.length > 0) {
        const newOrderPayload = {
          orderItems: newItems,
          shipmentAddress: orderDetails?.shipmentAddress,
          customerRemarks: orderDetails?.customerRemarks,
          customerId: orderDetails?.customerId,
          initiatorId: orderDetails?.initiatorId,
          orderMedia: orderDetails?.orderMedia,
          transactionType: orderDetails?.transactionType,
          allocatedTo: [
            {
              ...orderDetails?.allocatedTo[0],
              orderStatus: enums.NEW,
              invoiceInfo: undefined,
              challanInfo: undefined,
              allocationTime: undefined,
            },
          ],
        };

        const newOrder = await placeGaraazOrder({
          auth,
          data: newOrderPayload,
        });
        await registerSupplierAction({
          auth,
          orderId: newOrder._id,
          action: enums.ACK,
        });
        await registerSupplierAction({
          auth,
          orderId: newOrder._id,
          action: enums.CBO,
        });
      }

      await updateOrders({
        auth,
        orderId: orderDetails._id,
        data: updatedParts,
      });

      history.push("/orders");
    } catch (error) {
      props.notification({
        description:
          "Something went wrong!, Please ensure that invoice has been uploaded",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTransferOrderToBranch = async () => {
    try {
      setIsTransferOrderLoading(true);
      const updatedItems = [];
      const newOrderItems = [];

      for (const item of orderItems) {
        const partId = item.partId;
        const orderedQuantity = parseInt(item.orderedQuantity) || 0;
        const givenQuantity = parseInt(item.givenQuantity) || 0;

        if (givenQuantity > orderedQuantity) {
          props.notification({
            description: `Given quantity for part ${item?.partNumber} is greater than ordered quantity.`,
            status: "error",
          });
          return;
        }
        if (givenQuantity > 0) {
          updatedItems.push({
            partId: partId,
            orderedQuantity: givenQuantity,
            givenQuantity: givenQuantity,
          });
        }
        if (givenQuantity < orderedQuantity) {
          newOrderItems.push({
            partId: partId,
            orderedQuantity: orderedQuantity - givenQuantity,
            givenQuantity: 0,
          });
        }
      }

      const updatedParts = {
        orderItems: updatedItems,
      };

      if (newOrderItems.length > 0) {
        const newOrderPayload = {
          orderItems: newOrderItems,
          shipmentAddress: orderDetails?.shipmentAddress,
          customerRemarks: orderDetails?.customerRemarks,
          customerId: orderDetails?.customerId,
          initiatorId: orderDetails?.initiatorId,
          orderMedia: orderDetails?.orderMedia,
          transactionType: orderDetails?.transactionType,
          allocatedTo: [
            {
              ...orderDetails?.allocatedTo[0],
              orderStatus: enums.NEW,
              invoiceInfo: undefined,
              challanInfo: undefined,
              allocationTime: undefined,
            },
          ],
        };

        await placeGaraazOrder({
          auth,
          data: newOrderPayload,
        });
      }

      await transferSupplierBranch({
        auth,
        orderId: orderDetails._id,
        data: updatedParts,
        branchId: selectedBranchId,
      });
      props.notification({
        description: `Order transferred to ${getSupplierBranchName(
          selectedBranchId
        )}`,
        status: "success",
      });
      history.push("/orders");
    } catch (error) {
      props.notification({
        description: "Something went wrong!",
        status: "error",
      });
    } finally {
      setIsTransferOrderLoading(false);
      setSelectedBranchId("");
    }
  };

  const handleCloseTransferOrderModal = () => {
    setSelectedBranchId("");
    onTransferOrderModalClose();
  };

  const handleRowChange = (value, columnId, SNo) => {
    if (columnId === "delete") {
      let currentOrderItems = [...orderItems].filter(
        (item) => item.partId !== value.partId
      );

      setOrderItems(currentOrderItems);
    } else {
      let selectedItem = [...orderItems].find(
        (item) => item.partId === value.partId
      );

      if (columnId === "partNumber") {
        selectedItem = {
          ...value,
          partId: value.partId,
          SNo,
        };
      } else if (columnId === "orderedQuantity" && !_.isEmpty(selectedItem)) {
        selectedItem = {
          ...selectedItem,
          orderedQuantity: value.orderedQuantity,
        };
      } else if (columnId === "givenQuantity" && !_.isEmpty(selectedItem)) {
        selectedItem = {
          ...selectedItem,
          givenQuantity: value.givenQuantity,
        };
      }

      const indexOfSelectedItem = [...orderItems].findIndex(
        (item) => item.partId === selectedItem.partId
      );

      if (indexOfSelectedItem === -1) {
        const prevOrderItems = [...orderItems].filter(
          (item) => !_.isEmpty(item) && orderItems[0].partNumber
        );

        setOrderItems([...prevOrderItems, selectedItem]);
      } else {
        setOrderItems((prevState) => {
          return prevState.map((item) => {
            if (item.partNumber === selectedItem.partNumber) {
              if (columnId === "partNumber") {
                props.notification({
                  title: "Duplicate Item",
                  description: `item with part number ${item.partNumber} already exist`,
                  status: "error",
                });

                return item;
              }

              return selectedItem;
            }
            return item;
          });
        });
      }
    }
  };

  const handlePartNumberChange = (value, SNo) => {
    handleRowChange(value, "partNumber", SNo);
  };

  const handlePartInputChange = async (value, callBack) => {
    const response = await getSinglePart({
      auth,
      params: { partNumber: value },
    });

    const options = response.data.map((item) => {
      return {
        ...item,
        value: item?._id,
        label: item?.partNumber,
        partId: item?._id,
        partNumber: item?.partNumber,
      };
    });
    callBack(options);
  };

  const handleFillActionQuantity = () => {
    setOrderItems((prevOrderItems) => {
      const newOrderItems = prevOrderItems.map((item) => {
        return {
          ...item,
          givenQuantity: item.orderedQuantity,
        };
      });
      return newOrderItems;
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddRow();
    }
  };

  const tableRowsArr = [...orderItems].map((item, index) => {
    const data = {
      ...item,
      SNo: index + 1,
    };

    return (
      <TableRow
        onKeyDown={handleKeyDown}
        selectInput={
          <AsyncSelect
            autoFocus={
              orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.READY &&
              !item?.partNumber
                ? true
                : false
            }
            value={{ value: item?.partId, label: item?.partNumber }}
            onChange={(value) => handlePartNumberChange(value, index + 1)}
            loadOptions={handlePartInputChange}
            isReadOnly={
              item?.partNumber ||
              (orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.NEW &&
                orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.ACK &&
                orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.CBO)
            }
          />
        }
        key={item?.partNumber ? item?.partNumber : null}
        showPrice={true}
        data={data}
        onChange={handleRowChange}
        orderStatus={orderDetails?.allocatedTo?.[0]?.orderStatus}
      />
    );
  });

  const getSupplierBranchName = useCallback(
    (branchId) => {
      const branch = allAvailableBranches?.find(
        (branch) => branch?._id === branchId
      );
      return branch ? branch?.branchName : "";
    },
    [orderDetails, allAvailableBranches]
  );

  const handleReadyOrder = async () => {
    const invoiceInfo = orderDetails?.allocatedTo?.[0]?.invoiceInfo;

    const validOrderItems = orderItems.filter(
      (item) => parseInt(item.givenQuantity) > 0
    );
    const totalOrderItems = validOrderItems.length;
    const totalGivenQuantity = validOrderItems.reduce((sum, item) => {
      return sum + parseInt(item.givenQuantity);
    }, 0);

    if (invoiceInfo) {
      const { noOfItems, totalQtyOfItems } = invoiceInfo;

      if (
        noOfItems === totalOrderItems &&
        totalQtyOfItems === totalGivenQuantity
      ) {
        await handleCompleteOrder();
        dispatcher(updateActionTaken(!toggleActionTaken));
      } else if (
        noOfItems >= totalOrderItems &&
        totalQtyOfItems >= totalGivenQuantity
      ) {
        setAlertDialogTitleText(messages.confirmationMessageTitle.defaultMessage);
        setAlertDialogBodyText(
          `${messages.confirmationMessageDescription.defaultMessage}\n\n` +
            `• INVOICE : Items - ${noOfItems}       , Quantity - ${totalQtyOfItems}\n` +
            `• ORDER   : Items - ${totalOrderItems} , Quantity - ${totalGivenQuantity}`
        );

        onConfirmationOpen();
      } else {
        setAlertDialogTitleText(messages.mismatchMessageTitle.defaultMessage);
        setAlertDialogBodyText(
          `${messages.mismatchMessageDescription.defaultMessage}\n\n` +
            `• INVOICE : Items - ${noOfItems}       , Quantity - ${totalQtyOfItems}\n` +
            `• ORDER   : Items - ${totalOrderItems} , Quantity - ${totalGivenQuantity}`
        );
        onMismatchOpen();
      }
    } else {
      await handleCompleteOrder();
      dispatcher(updateActionTaken(!toggleActionTaken));
    }
  };

  return (
    <>
      <Box position={"absolute"} top={14} right={4} zIndex={9999}>
        <Icon
          as={GrDownload}
          cursor={"pointer"}
          color={"#4A5567"}
          fontSize={"sm"}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL}/${EXPORT_DOC_V2(
                orderDetails._id
              )}csv`,
              "_blank"
            );
          }}
          mr={3}
        ></Icon>
        {orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.READY &&
          orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.CANCELLED && (
            <IconButton
              aria-label="saveBtn"
              cursor={"pointer"}
              _hover={{ bg: "none", border: "1px solid lightgrey" }}
              size="sm"
              bg="none"
              isDisabled={
                orderItems?.length === 0 ||
                isButtonsDisabled ||
                orderDetails?.allocatedTo?.[0]?.orderStatus === enums.CBO ||
                orderDetails?.orderType === "RETURN"
              }
              color={"black"}
              icon={<BiSave size={"1.5rem"} />}
              onClick={async () => {
                await handleSaveAsDraftOrder();
                dispatcher(updateActionTaken(!toggleActionTaken));
              }}
            />
          )}
      </Box>

      <OrderDetailInfo
        orderDetails={orderDetails}
        fetchOrderDetails={fetchOrderDetails}
        parentOrderDetails={parentOrderDetails}
      />
      <Divider size="md" />

      <Box
        layerStyle="background"
        display="flex"
        flexDirection={"column"}
        alignItems="start"
        pt={5}
      >
        {!!orderDetails?.orderMedia?.length && (
          <Box layerStyle="dark" width="100%" boxShadow="base">
            <MediaViewer />
          </Box>
        )}

        <Box width={"100%"} mt={5}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
            mb={3}
          >
            <Tag
              size="md"
              py={2}
              backgroundColor={
                getSupplierStateColor(
                  orderDetails?.allocatedTo?.[0]?.orderStatus.replace(/_/g, " ")
                )?.color
              }
              color={
                getSupplierStateColor(
                  orderDetails?.allocatedTo?.[0]?.orderStatus.replace(/_/g, " ")
                )?.textColor
              }
            >
              {orderDetails?.allocatedTo?.[0]?.orderStatus?.replace(/_/g, " ")}
            </Tag>
          </Box>
          <Box border="1px solid" borderColor="gray.100" pb={4}>
            <StyledTable isLarge={window.innerWidth > 768}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Part Number</th>
                  <th>Part Name</th>
                  <th>Ordered Quantity</th>
                  <th
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Action Quantity
                    <Button
                      bgColor={"white"}
                      size="xs"
                      ml={2}
                      p={1}
                      isDisabled={
                        orderItems.length === 0 ||
                        orderItems.some(
                          (item) => !item.partNumber || !item.orderedQuantity
                        ) ||
                        (orderDetails?.allocatedTo?.[0]?.orderStatus !==
                          enums.ACK &&
                          orderDetails?.allocatedTo?.[0]?.orderStatus !==
                            enums.NEW &&
                          orderDetails?.allocatedTo?.[0]?.orderStatus !==
                            enums.CBO)
                      }
                      onClick={handleFillActionQuantity}
                      height={"fit-content"}
                    >
                      <Icon as={FaPencilAlt} color={"black"}></Icon>{" "}
                    </Button>
                  </th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>{tableRowsArr}</tbody>
            </StyledTable>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              mt={4}
              pr={2}
            >
              <Button
                isDisabled={
                  orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.NEW &&
                  orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.ACK
                }
                onClick={handleAddRow}
              >
                Add Row
              </Button>
            </Box>
          </Box>
          {orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.READY &&
            orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.CANCELLED &&
            orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.REVOKED &&
            orderDetails?.orderType !== "RETURN" && (
              <HStack justifyContent="flex-end" width="100%" mt={4} mb={5}>
                <Button
                  onClick={async () => {
                    await handleSaveAsDraftOrder();
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  isLoading={isSaveaveAsDraftLoading}
                  variant={"normal"}
                  _hover={{ bg: "yellow.500" }}
                  bg={"yellow.400"}
                  w={"10rem"}
                  isDisabled={
                    orderItems?.length === 0 ||
                    isButtonsDisabled ||
                    orderDetails?.allocatedTo?.[0]?.orderStatus === enums.CBO
                  }
                >
                  Save as Draft
                </Button>
                <Button
                  onClick={async () => {
                    await handleOrderStatusChange(enums.CANCELLED, "CANCEL");
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  isLoading={isCancelLoading}
                  variant={"normal"}
                  _hover={{ bg: "red.500" }}
                  bg={"red.400"}
                  w={"10rem"}
                  isDisabled={
                    orderDetails?.allocatedTo?.[0]?.orderStatus ===
                      enums.CANCELLED ||
                    orderDetails?.allocatedTo?.[0]?.orderStatus === enums.NEW ||
                    orderDetails?.allocatedTo?.[0]?.orderStatus ===
                      enums.READY ||
                    isButtonsDisabled ||
                    isAllGivenQuantityZero
                  }
                >
                  Cancel Order
                </Button>
                <Button
                  onClick={onTransferOrderModalOpen}
                  variant={"normal"}
                  _hover={{ bg: "purple.500" }}
                  bg={"purple.400"}
                  w={"10rem"}
                  isDisabled={
                    orderItems?.length === 0 ||
                    isButtonsDisabled ||
                    isAllGivenQuantityZero
                  }
                >
                  Transfer Order
                </Button>
                <SimpleModalNew
                  isOpen={isTransferModalOpen}
                  onClose={handleCloseTransferOrderModal}
                  onOk={async () => {
                    await handleTransferOrderToBranch();
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  okText="Transfer"
                  title=" Transfer Order to other Branch"
                  isDisabled={!selectedBranchId}
                  isLoading={isTransferOrderLoading}
                >
                  <Select
                    placeholder="Select branch"
                    onChange={(e) => {
                      setSelectedBranchId(e.target.value);
                    }}
                  >
                    {branchOptions?.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </SimpleModalNew>
                <Button
                  onClick={async () => {
                    await handleOrderStatusChange(enums.CBO, "CBO");
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  isLoading={isCBOLoading}
                  bg="cyan.500"
                  variant={"normal"}
                  _hover={{ bg: "cyan.600" }}
                  w={"10rem"}
                  isDisabled={
                    orderDetails?.allocatedTo?.[0]?.orderStatus === enums.CBO ||
                    orderDetails?.allocatedTo?.[0]?.orderStatus === enums.NEW ||
                    orderDetails?.allocatedTo?.[0]?.orderStatus ===
                      enums.READY ||
                    isButtonsDisabled ||
                    isAllGivenQuantityZero
                  }
                >
                  Take Back Order
                </Button>
                <Button
                  onClick={handleReadyOrder}
                  isLoading={isLoading}
                  variant="normal"
                  _hover={{ bg: "teal.700" }}
                  bg="teal.600"
                  isDisabled={
                    orderItems?.length === 0 ||
                    isButtonsDisabled ||
                    isAllGivenQuantityZero
                  }
                  w="10rem"
                >
                  Ready Order
                </Button>
                <GaraazAlertDialog
                  isOpen={isConfirmationOpen}
                  onClose={onConfirmationClose}
                  submitBtnText="Proceed"
                  headerText={alertDialogTitleText}
                  branchName={""}
                  bodyText={alertDialogBodyText}
                  onSubmitButtonClick={async () => {
                    await handleCompleteOrder();
                    dispatcher(updateActionTaken(!toggleActionTaken));
                    onConfirmationClose();
                  }}
                />
                <GaraazAlertDialog
                  isOpen={isMismatchOpen}
                  submitBtnText="OK"
                  headerText={alertDialogTitleText}
                  branchName={""}
                  bodyText={alertDialogBodyText}
                  showCancelButton={false}
                  onSubmitButtonClick={onMismatchClose}
                />
              </HStack>
            )}
          {orderDetails?.orderType === "RETURN" &&
            orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.ACCEPTED &&
            orderDetails?.allocatedTo?.[0]?.orderStatus !== enums.REJECTED && (
              <HStack justifyContent="flex-end" width="100%" mt={4} mb={5}>
                <Button
                  onClick={async () => {
                    await handleAcceptRejectOrder("REJECT");
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  variant={"normal"}
                  _hover={{ bg: "red.500" }}
                  bg="red.400"
                  isDisabled={orderItems?.length === 0 || isButtonsDisabled}
                  w={"10rem"}
                >
                  Reject
                </Button>
                <Button
                  onClick={async () => {
                    await handleAcceptRejectOrder("ACCEPT");
                    dispatcher(updateActionTaken(!toggleActionTaken));
                  }}
                  variant={"normal"}
                  _hover={{ bg: "teal.700" }}
                  bg="teal.600"
                  isDisabled={orderItems?.length === 0 || isButtonsDisabled}
                  w={"10rem"}
                >
                  Accept
                </Button>
              </HStack>
            )}
        </Box>
      </Box>
    </>
  );
};

const LeftSliderButton = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      isRound={true}
      position="absolute"
      right="90%"
      top="40%"
      bg="rgba(237,242,247, 0.4)"
    >
      <FaLongArrowAltLeft style={{ color: "black" }} />
    </IconButton>
  );
};

const RightSliderButton = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      isRound={true}
      position="absolute"
      left="90%"
      top="40%"
      bg="rgba(237,242,247, 0.4)"
    >
      <FaLongArrowAltRight />
    </IconButton>
  );
};

export const MediaViewer = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef();
  const { orderDetails } = useSelector((state) => state.orderDetails);

  useEffect(() => {
    if (orderDetails?.orderMedia?.length) {
      setActiveIndex(1);
    }
  }, [orderDetails]);

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handlePrev = () => {
    carouselRef.current.slidePrev();
    if (activeIndex > 1) {
      setActiveIndex((prevState) => prevState - 1);
    }
  };

  const handleNext = () => {
    carouselRef.current.slideNext();
    if (activeIndex > (orderDetails?.orderMedia?.length ?? 0))
      setActiveIndex((prevState) => prevState + 1);
  };

  //new code added
  const items = (
    orderDetails?.orderMedia?.length > 0 ? orderDetails?.orderMedia : []
  ).map((item) => {
    return (
      <>
        <Center
          onDragStart={handleDragStart}
          key={item.link}
          width="100%"
          minHeight="40vh"
          position="relative"
          layerStyle="dark"
        >
          {item?.mimeType.startsWith("image") ? (
            <ImageCanvas
              imageLink={item?.path}
              stageHeight={props.stageHeight}
              stageWidth={props.stageWidth}
            />
          ) : item?.mimeType.startsWith("audio") ? (
            <MediaComponent mimeType={item?.mimeType} mediaLink={item.path} />
          ) : (
            <Box>
              <Button
                onClick={() => {
                  window.open(item.path, "_blank");
                }}
              >
                Download Text
              </Button>
            </Box>
          )}
        </Center>
      </>
    );
  });

  if (!orderDetails?.orderMedia || orderDetails.orderMedia.length === 0) {
    return (
      <Box>
        <Text>No media is associated with this order.</Text>
      </Box>
    );
  }

  return (
    <Box layerStyle="dark" minHeight="20vh" position="relative">
      <Box>
        {activeIndex}/{orderDetails?.orderMedia?.length ?? 0}
      </Box>

      <AliceCarousel
        ref={carouselRef}
        disableButtonsControls={true}
        disableDotsControls={true}
        items={items}
      />
      <Center py={4} bg="gray.50" mt={3} px={3}>
        <Box layerStyle="dark" textStyle="subtitle">
          {orderDetails?.remarks}
        </Box>
      </Center>
      <LeftSliderButton onClick={handlePrev} />

      <RightSliderButton onClick={handleNext} />
    </Box>
  );
};

const MediaComponent = (props) => {
  const videoRef = useRef();
  const [intervalRwd, setIntervalRwd] = useState(null);
  const [intervalFwd, setIntervalFwd] = useState(null);
  const [showControls, setShowControls] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [isBackward, setIsBackward] = useState(false);

  useEffect(() => {
    setShowControls(false);
  }, []);

  const handlePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPaused(true);
    } else {
      video.pause();
      setIsPaused(false);
    }

    setIsBackward(false);
    setIsForward(false);
    clearInterval(intervalRwd);
    clearInterval(intervalFwd);
  };

  const handleStop = () => {
    const video = videoRef.current;
    video.pause();
    video.currentTime = 0;
    setIsPaused(false);
    setIsBackward(false);
    setIsForward(false);
    clearInterval(intervalRwd);
    clearInterval(intervalFwd);
  };

  function windBackward() {
    const video = videoRef.current;
    if (video.currentTime <= 3) {
      setIsBackward(false);
      clearInterval(intervalRwd);
      handleStop();
    } else {
      video.currentTime -= 3;
    }
  }

  function windForward() {
    const video = videoRef.current;
    if (video.currentTime >= video.duration - 3) {
      setIsForward(false);
      clearInterval(intervalFwd);
      handleStop();
    } else {
      video.currentTime += 3;
    }
  }

  const handleBackward = () => {
    clearInterval(intervalFwd);
    setIsForward(false);
    const video = videoRef.current;

    if (isBackward) {
      setIsBackward(false);
      clearInterval(intervalRwd);
      video.play();
    } else {
      setIsBackward(true);
      video.pause();

      const timeId = setInterval(windBackward, 200);
      setIntervalRwd(timeId);
    }
  };

  const handleForward = () => {
    const video = videoRef.current;
    clearInterval(intervalRwd);
    setIsBackward(false);

    if (isForward) {
      setIsForward(false);
      clearInterval(intervalFwd);
      video.play();
    } else {
      setIsForward(true);
      video.pause();
      const timeId = setInterval(windForward, 200);
      setIntervalFwd(timeId);
    }
  };
  return (
    <Fragment>
      {`${props.mimeType}`.startsWith("video") && (
        <video
          ref={videoRef}
          controls={showControls}
          style={{ minWidth: "100%", minHeight: "100%" }}
        >
          <source src={props.mediaLink} type="video/mp4" />
          <source src={props.mediaLink} type="video/webm" />
        </video>
      )}
      {props.mimeType.startsWith("audio") && (
        <audio controls style={{ minWidth: "100%", minHeight: "100%" }}>
          <source src={props.mediaLink} type="audio/ogg" />
          <source src={props.mediaLink} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {props.mimeType.startsWith("video") && (
        <Center top="85%" position="absolute" width="100%">
          <StyledControlButton onClick={handlePlay} opacity="0.5">
            {isPaused ? <FaPause /> : <FaPlay />}
          </StyledControlButton>
          <StyledControlButton onClick={handleStop}>
            <FaStop />
          </StyledControlButton>
          <StyledForwarAndBackward
            isActive={isBackward}
            onClick={handleBackward}
          >
            <FaBackward />
          </StyledForwarAndBackward>
          <StyledForwarAndBackward isActive={isForward} onClick={handleForward}>
            <FaForward />
          </StyledForwarAndBackward>
        </Center>
      )}
    </Fragment>
  );
};

const initialState = {
  x: 100,
  y: 100,
  id: "img1",
  // scaleX:0.3,
  // scaleY:0.3,
};

const LionImage = React.forwardRef((props, ref) => {
  const [image] = useImage(props.imageLink);
  return (
    <Fragment>
      <KonvaImage
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        draggable={props.draggable}
        ref={ref}
        scaleX={0.3}
        scaleY={0.3}
        {...props.shapeProps}
        onDragEnd={(e) => {
          props.onChange({
            ...props.shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        image={image}
      />
    </Fragment>
  );
});

const ImageCanvas = ({ imageLink, stageHeight, stageWidth }) => {
  const shapeRef = useRef();
  const [initialProps, setProps] = useState(initialState);
  const [selectedId, selectShape] = useState(null);
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const stageRef = useRef();

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const onMouseEnter = () => {
    stageRef.current.container().style.cursor = "grab";
  };

  const onMouseLeave = () => {
    stageRef.current.container().style.cursor = "default";
  };

  const handleZoom = (type) => {
    const node = shapeRef.current;
    let currentHeight;
    let currentWidth;

    if (type === "zoomIn") {
      node.scaleX(node.scaleX() + 0.1);
      node.scaleY(node.scaleY() + 0.1);

      currentHeight = initialHeight ? initialHeight : node.height();
      currentWidth = initialWidth ? initialWidth : node.width();
    } else if (type === "zoomOut") {
      node.scaleX(node.scaleX() - 0.1);
      node.scaleY(node.scaleY() - 0.1);
      currentHeight = initialHeight ? initialHeight : node.height();
      currentWidth = initialWidth ? initialWidth : node.width();
    }

    if (!initialHeight) {
      setInitialHeight(node.height());
    }

    if (!initialWidth) {
      setInitialWidth(node.width());
    }

    setProps({
      ...initialProps,
      x: node.x(),
      y: node.y(),
      width: currentWidth * node.scaleX(),
      height: currentHeight * node.scaleY(),
    });
  };

  const handleRotate = (type) => {
    const node = shapeRef.current;

    if (type === "antiClock") {
      node.rotation(node.rotation() + 20);
      node.offsetY(node.height() / 2);
      node.offsetX(node.width() / 2);

      setProps({
        ...initialProps,
        offset: {
          x: node.offsetX(),
          y: node.offsetY(),
        },
      });
    } else {
      node.rotation(node.rotation() - 20);
      node.offsetY(node.height() / 2);
      node.offsetX(node.width() / 2);

      setProps({
        ...initialProps,
        offset: {
          x: node.offsetX(),
          y: node.offsetY(),
        },
      });
    }
  };

  return (
    <Box>
      <Stage
        ref={stageRef}
        width={stageWidth ?? window.innerWidth * (43 / 100)}
        height={stageHeight ?? window.innerWidth * (40 / 100)}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        <Layer>
          <LionImage
            ref={shapeRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            imageLink={imageLink}
            draggable
            shapeProps={initialProps}
            isSelected={initialProps.id === selectedId}
            onSelect={() => {
              selectShape(initialProps.id);
            }}
            onChange={(newAttrs) => {
              setProps(newAttrs);
            }}
          />
        </Layer>
      </Stage>
      <Center>
        <StyledControlButton onClick={() => handleZoom("zoomIn")}>
          <FiZoomIn />
        </StyledControlButton>
        <StyledControlButton onClick={() => handleZoom("zoomOut")}>
          <FiZoomOut />
        </StyledControlButton>
        <StyledControlButton onClick={() => handleRotate("antiClock")}>
          <FiRotateCcw />
        </StyledControlButton>
        <StyledControlButton onClick={() => handleRotate("clock")}>
          <FiRotateCw />
        </StyledControlButton>
      </Center>
    </Box>
  );
};

export const StyledTable = styled.table`
  min-width: 100%;
  & thead {
    background-color: #1e293b;
    font-size: ${(props) => (props.isLarge ? "0.75rem" : "0.6rem")};
    & th {
      color: #fff;
      padding: 10px 5px;
      text-align: center;
      text-transform: uppercase;
      border-left: 1px solid #a0aec0;
      letter-spacing: 0.5px;
      font-weight: 700;
      &:first-child {
        border-left: none;
      }
    }
  }

  & tbody {
    & tr {
      border-bottom: 1px solid #a0aec0;
      & td {
        padding: 3px;
        border-left: 1px solid #a0aec0;
      }
    }
  }
`;

const StyledControlButton = styled(IconButton)`
  opacity: 0.5;
  border-radius: 0px;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    opacity: 1;
  }
`;

const StyledForwarAndBackward = styled(StyledControlButton)`
  color: ${(props) => (props.isActive ? "red" : "gray")};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
`;

export default garaazToast(RequestOrder);
