import { useToast } from "@chakra-ui/react";

const garaazToast = (WrappedComponent) => {
  return (props) => {
    const toast = useToast();
    const notification = ({
      status,
      title = "",
      description,
      duration = 10000,
    }) => {
      return toast({
        title: title,
        description: description,
        status: status,
        duration: duration,
        isClosable: true,
        position: "top",
      });
    };

    return <WrappedComponent {...props} notification={notification} />;
  };
};

export default garaazToast;
