const enTranslationMessages = require('./translations/en.json');
const hiTranslationMessages = require('./translations/hi.json');
const DEFAULT_LOCALE = 'en';
const appLocales = ['en', 'hi'];

exports.appLocales = appLocales;
exports.translationMessages = {
  en: enTranslationMessages,
  hi: hiTranslationMessages,
};
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
