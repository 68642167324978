import { publicAxios as axios } from "../../axios";
import {
  GET_ALL_ORDERS,
  SET_ALL_ORDERS_NEW,
  SET_TOTAL_COUNT_NEW,
  UPDATE_TABLE_TOP_VALUE_NEW,
  GET_ALL_SUPPLIERS,
  SET_LAST_API_CALL_TIME,
} from "./constants";
import getURLParams from "../../utils/getURLParams";

export const setAllOrders = (payload) => {
  return {
    type: SET_ALL_ORDERS_NEW,
    payload,
  };
};

export const setAllSuppliers = (payload) => {
  return {
    type: GET_ALL_SUPPLIERS,
    payload,
  };
};

export const setTotalCount = (payload) => ({
  type: SET_TOTAL_COUNT_NEW,
  payload,
});

export const updateTableTopValue = (payload) => ({
  type: UPDATE_TABLE_TOP_VALUE_NEW,
  payload,
});

export const getAllOrders = async (auth, paramsObj = {}) => {
  const params = getURLParams(paramsObj);

  const response = await axios.get(
    `${GET_ALL_ORDERS}${params}&sort=supplierAllocationTime`,
    {
      headers: {
        Authorization: auth.authToken,
        clientId: "SA",
      },
    }
  );

  return response?.data ?? [];
};

export const getAllSuppliers = async () => {
  const response = await axios.get("/v2/suppliers");
  return response.data;
};

export const setLastApiCallTime = (payload) => {
  return {
    type: SET_LAST_API_CALL_TIME,
    payload,
  };
};

export const getGarrazMonthlyIncentive = async (
  auth,
  startDate,
  endDate,
  supplierID
) => {
  const response = await axios.get(`/v1/suppliers/${supplierID}/incentives`, {
    params: {
      allocationStartDate: startDate,
      allocationEndDate: endDate,
    },
    headers: {
      Authorization: auth.authToken,
    },
  });
  return response.data;
};
