import produce from 'immer';

import { SET__PARTS } from './constants';

export const initialState = {
  parts: [],
};

/* eslint-disable default-case, no-param-reassign */
const partListReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET__PARTS:
        draft.parts = action.payload;
        break;
    }
  });

export default partListReducer;
