import React from "react";
import {
  InputGroup,
  Input,
  InputRightElement,
  Box,
  Tooltip,
  IconButton,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { Select as MultiSelect } from "chakra-react-select";
import { FiSearch } from "react-icons/fi";
import { useIntl } from "react-intl";
import messages from "./messages";
import enums from "../../utils/enums";
import CustomerSearch from "../CustomerSearch";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { RepeatIcon, SpinnerIcon } from "@chakra-ui/icons";
import { FaRegShareSquare } from "react-icons/fa";
import { useEffect, useState } from "react";

const TableTopNew = (props) => {
  const intl = useIntl();

  const {
    tableTopValues: { dates, isPending}
  } = useSelector((state) => state.orderListNew);

  return (
    <>
      <Box display={"flex"} mt={3} mb={8}>
        <Box width={"14%"} mr={2}>
          <InputGroup>
            <Input
              h="2rem"
              fontSize={"sm"}
              type="text"
              placeholder={intl.formatMessage(messages.searchOrdersNew)}
              onChange={props.onSearchOrderChange}
              defaultValue={props.orderNo}
              borderRadius={"2.5px"}
            />
            <InputRightElement
              h="2rem"
              pointerEvents="none"
              children={<FiSearch color="gray.300" />}
            />
          </InputGroup>
        </Box>
        <Box width={"14%"} mr={2}>
          <MultiSelect
            placeholder={intl.formatMessage(messages.orderStatusFilterNew)}
            value={props.orderStatus}
            onChange={(value) => props.onSelectChange(value, enums.STATUS)}
            isMulti
            options={props.statusOption}
            size="sm"
            styles={{ input: (base) => ({ ...base, borderRadius: "30px" }) }}
            isDisabled={isPending}
          />
        </Box>
        <Box width={"14%"} mr={2}>
          <MultiSelect
            placeholder={intl.formatMessage(messages.selectBranch)}
            value={props.branchValue}
            onChange={(value) => props.onSelectChange(value, enums.BRANCH)}
            isMulti={false}
            options={props.branchOptions}
            isClearable={true}
            size="sm"
          />
        </Box>
        <Box width={"17%"} mr={2} h="2rem">
          <CustomerSearch
            label="Filter Customer"
            value={props.selectedCustomer}
            onChange={props.handleCustomerChange}
            isMulti={false}
            isClearable={true}
          />
        </Box>
        <Box width={"9%"} mr={2}>
          <Input
            h="2rem"
            fontSize={"xs"}
            name={enums.STARTDATE}
            type="date"
            onChange={props.onDateChange}
            placeholder={intl.formatMessage(messages.startDate)}
            defaultValue={dates.startDate}
            p={1}
            borderRadius={"2.5px"}
          />
        </Box>
        <Box width={"9%"} h="2rem" mr={3}>
          <Input
            h="2rem"
            fontSize={"xs"}
            name={enums.ENDDATE}
            type="date"
            onChange={props.onDateChange}
            placeholder="DD/MM/YYYY"
            defaultValue={dates.endDate}
            p={1}
            borderRadius={"2.5px"}
          />
        </Box>
        <Box
          width={"9%"}
          h="2rem"
          mr={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} mr={2}>
            <Checkbox
              mr={2}
              isChecked={isPending}
              onChange={props.handleSupplierOrderStatusChange}
            />
            <Text fontSize={"xs"}>Pending</Text>
          </Box>
        </Box>
        <Box ml={"auto"}>
          <Tooltip
            label="Export Orders to CSV"
            fontSize={"small"}
            placement="bottom-start"
          >
            <IconButton
              h="2rem"
              onClick={props.fetchExportData}
              variant="normal"
              mr={2}
              bgColor={"transparent"}
              color={"black"}
              border={"1px solid #E2E8F0"}
              _hover={{ bgColor: "#EDF2F6" }}
              borderRadius={"2.5px"}
            >
              {props.exportLoading ? <SpinnerIcon /> : <FaRegShareSquare />}
            </IconButton>
          </Tooltip>

          <Box display="none">
            <CSVLink
              data={props.exportData}
              filename={"orders.csv"}
              ref={props.csvInstance}
            ></CSVLink>
          </Box>
          <Tooltip
            label="Refresh Page"
            fontSize={"small"}
            placement="bottom-start"
          >
            <IconButton
              h="2rem"
              onClick={props.reset}
              variant="normal"
              bgColor={"transparent"}
              color={"black"}
              border={"1px solid #E2E8F0"}
              _hover={{ bgColor: "#EDF2F6" }}
              borderRadius={"2.5px"}
            >
              <RepeatIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {props.children}
    </>
  );
};

export default TableTopNew;
