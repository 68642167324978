/**
 * Combine all reducers in this file and export the combined reducers.
 */

//import { combineReducers } from 'redux';
import { connectRouter } from "connected-react-router";
import { createAsyncThunk, combineReducers } from "@reduxjs/toolkit";

import history from "./utils/history";
import languageProviderReducer from "./containers/LanguageProvider/reducer";
import loginPageReducer from "./containers/LoginPage/reducer";
// import distributerInfoReducer from "./containers/DistributerInfo/reducer";
import employeeListReducer from "./components/EmployeeList/reducer";
import branchListReducer from "./containers/BranchList/reducer";
import partListReducer from "./components/PartsList/reducer";
import orderListReducer from "./containers/OrderList/reducer";
import orderListReducerNew from "./containers/OrderListNew/reducer";
import orderDetailReducer from "./containers/OrderDetails/reducer";
import accountReducer from "./containers/Accounting/slice";
import outstandingAccountReducer from "./containers/OutstandingAccount/slice";
import collectionsAcctReducer from "./containers/CollectionsAccount/slice";
import inventoryRed from "./containers/Inventory/slice";

import globalSlice from "./globalSlice";
import salesReducer from "./containers/Sales/slice";
import dashboardReducer from "./containers/Dashboard/slice";
import partReducer from "./containers/Parts/slice";
import workshopReducer from "./components/WorkShopList/reducer";

import { TOGGLE_SPINNER } from "./types";

const appReducer = (
  state = {
    showSpinner: false,
    acceptedBranchOnboardingHeaders: [
      "Branch Name",
      "Branch Code",
      // 'Incharge Code',
      "Start Time",
      "End Time",
      "Working Days",
      "Address Line1",
      "Address Line2",
      "City",
      "State",
      "Country",
      "Pin Code",
      "Mobile Numbers",
      "Alternate Numbers",
      "Landline Numbers",
      "WhatsApp Numbers",
      "Emails",
      "Website",
    ],
    acceptedEmployeeOnboardingHeaders: [
      "Mobile Number",
      "First Name",
      "Last Name",
      "Designation",
      /*       'Deputation', */
      "Employee Id",
      "Reports To",
      "Branch Code",
    ],
    acceptedPartsOnboardingHeaders: [
      "Brand",
      "Part Type",
      /* 'Type Code', */
      "Category",
      /* 'Part Sub-Category', */
      "Part Number",
      "Root Part Number",
      "Part Name",
      "Part Description",
      "MRP",
      "Selling Price",
      "HSN Code",
      "Tax Slab",
      "Pack Quantity",
      "Pack Size",
      "Origin",
      "Notes",
      /* 'Media', */
      "Sale_Status",
      "Status",
    ],
    acceptedWorkshopsOnboardingHeaders: [
      "Code",
      "Name",
      "Type",
      "Address Line 1",
      "Address Line 2",
      "City",
      "State",
      "Country",
      "Pin Code",
      "Pan",
      "GSTIN",
      "Owner Full Name",
      "Mobile Number",
      "Alternate Number",
      "Email Address",
      "Payment Terms",
      "Discount",
      "Credit Days",
      "Branch Code",
      "Sale Executive Code",
    ],
    acceptedAccountOnboardHeaders: [
      "Branch Code",
      "Party Name",
      "Code",
      "Opening Balance",
      "Closing Balance",
      "Date",
      "Particulars",
      "Vch Type",
      "Vch No",
      "Debit",
      "Credit",
      "Running Balance",
      "Due Days",
    ],
    acceptedInventoryOnboardHeaders: [
      "Branch Code",
      "Quantity",
      "Price",
      "Part Description",
      "Part Number",
      "Bin Location",
    ],
    acceptedSalesOnboardHeaders: [
      "Branch Code",
      "Branch Name",
      "Part Category",
      "Part Type",
      "Part Number",
      "Part Name",
      "Root Part Number",
      "Days",
      "Month",
      "Workshop Code",
      "Workshop Name",
      "Workshop Type",
      "Document Number",
      "Remarks",
      "Retail Qty",
      "Return Qty",
      "Retail Sell",
      "Return Sell",
    ],
  },
  action
) => {
  switch (action.type) {
    case TOGGLE_SPINNER:
      return { ...state, showSpinner: !state.showSpinner };
    default:
      return state;
  }
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
/* export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    app: appReducer,
    language: languageProviderReducer,
    auth: loginPageReducer,
    router: connectRouter(history),
    distributorInfo: distributerInfoReducer,
    employeeList: employeeListReducer,
    branchList: branchListReducer,
    partList: partListReducer,
    orderList: orderListReducer,
    orderDetails: orderDetailReducer,
    accounts: accountReducer,
    outstandingAcct: outstandingAccountReducer,
    collectionsAcct: collectionsAcctReducer,
    inventoryReducer: inventoryReducer,
    ...injectedReducers,
  });

  return rootReducer;
} */

/* export const allReducers = () => {
  return {
    inventorydetail: inventoryRed,
    globalStateReducer: globalSlice,
    app: appReducer,
    language: languageProviderReducer,
    auth: loginPageReducer,
    router: connectRouter(history),
    distributorInfo: distributerInfoReducer,
    employeeList: employeeListReducer,
    branchList: branchListReducer,
    partList: partListReducer,
    orderList: orderListReducer,
    orderDetails: orderDetailReducer,
    accounts: accountReducer,
    outstandingAcct: outstandingAccountReducer,
    collectionsAcct: collectionsAcctReducer,
    sales: salesReducer,
    dashboard: dashboardReducer,
    placeOrder: placeOrderReducer,
    partListReducer: partReducer,
    workshopReducer
  };
}; */

const allReducers = combineReducers({
  inventorydetail: inventoryRed,
  globalStateReducer: globalSlice,
  app: appReducer,
  language: languageProviderReducer,
  auth: loginPageReducer,
  router: connectRouter(history),
  // distributorInfo: distributerInfoReducer,
  employeeList: employeeListReducer,
  branchList: branchListReducer,
  partList: partListReducer,
  orderList: orderListReducer,
  orderListNew: orderListReducerNew,
  orderDetails: orderDetailReducer,
  accounts: accountReducer,
  outstandingAcct: outstandingAccountReducer,
  collectionsAcct: collectionsAcctReducer,

  sales: salesReducer,
  dashboard: dashboardReducer,
  partListReducer: partReducer,
  workshopReducer,
});

export const reducerProxy = (state, action) => {
  if (action.type === "logout/LOGOUT") {
    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export const logout = createAsyncThunk(
  "auth/logout",
  async function (_payload, thunkAPI) {
    thunkAPI.dispatch({ type: "logout/LOGOUT" });
  }
);
